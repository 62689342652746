import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'

const View_batch_details = () => {
    const[batch_details,setbatch_details]=useState([])
    const[original_data,setoriginal_data]=useState({})
    const[employees,setemployees]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/countofstudents/`)
        .then((r)=>{
            console.log("Count with batches",r.data);
            setoriginal_data(r.data)
            setbatch_details(r.data.results)
            setemployees(r.data.results[0].employee_student_counts)
        })
    },[])

    const[api,setapi]=useState('')

    useEffect(()=>{
        if(api!==''){
            axios.get(`${api}`)
            .then((r)=>{
                console.log("Next page Count with batches",r.data);
                setoriginal_data(r.data)
                setbatch_details(r.data.results)
                setemployees(r.data.results[0].employee_student_counts)
            })
        }
    },[api])


return (
    <div className='d-flex'>
        <Sidebar activevalue={"batch_details"}/>
        <div className="w-100">
            <Navbar/>
            <div className="main-container p-3 bg-light">
                <div className='table-responsive'>
                <table className='table caption-top rounded'>
                    <thead className='table-secondary'>
                      <tr className='bg-white'>
                        <th scope='col' className='py-3' 
                        >
                          <p className='fw-bold text-center m-0' 
                          style={{ color: '#000', fontSize: '14px'}}>S.No</p>
                        </th>
                        <th scope='col' className='py-3' 
                        >
                          <p className='fw-bold text-center m-0' 
                          style={{ color: '#000', fontSize: '14px'}}>Batch Name</p>
                        </th>
                        
                        {employees && employees.map(val => (
                          <th scope='col' className='py-3'>
                            <p className='fw-medium m-0 text-center' style={{ color: '#000', fontSize: '13px' }}>{val.employee_name}</p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className=''>
                      {batch_details.map((x, index) => (
                        <tr  className={`${index%2!=0 ? 'table-primary':'table-white'}`}>
                          <td className='py-3' scope='row' 
                          // style={{position:'sticky',left:'0px'}}
                          >
                            <p className='d-flex align-items-center justify-content-center'>{index + 1}</p>
                          </td>
                        
                          <td className='py-3' 
                          // style={{position:'sticky',left:'170px'}}
                          ><p className='d-flex align-items-center justify-content-center fw-medium'>{x.batch_id}</p></td>

                          {x.employee_student_counts && x.employee_student_counts.map((y) => {
                            return(
                              <>
                               <td className='py-3 px-4'>
                              <div className='d-flex justify-content-center'>
                              <p>{y.student_count}</p>
                              </div>
                            </td>
                              </>
                            )
                            
                           
    })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className='d-flex align-items-center justify-content-between'>
                <button className={`btn btn-sm px-3 btn-success ${original_data.previous===null ? '':'d-none'}`} disabled='true'>Previous</button>

                <button className={`btn btn-sm px-3 btn-success ${original_data.previous!==null ? '':'d-none'}`} onClick={()=>{
                        setapi(original_data && original_data.previous)
                    }}>Previous</button>
                     <button className={`btn btn-sm px-3 btn-success ${original_data.next!==null ? '':'d-none'}`} onClick={()=>{
                        setapi(original_data && original_data.next)
                    }}>Next</button>
                     <button className={`btn btn-sm px-3 btn-success ${original_data.next===null ? '':'d-none'}`} disabled='true'>Next</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default View_batch_details