import React, { useState } from 'react'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
// import { pdfjs } from 'react-pdf';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Dashboard from './Components/Dashboard'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Leads from './Components/Leads'
import './main.css'
// import './birthday.css'
import Employess from './Components/Employess'
import Activities from './Components/Activities'
import Customerleads from './Components/Customerleads'
import Clientregistrationform from './Components/Clientregistrationform'
import Customerregistrationform from './Components/Customerregistrationform'
import Protect from './Components/Protect'
import Invoice from './invoide_and_receipt/Invoice'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import Mark_as_register_form from './Components/Mark_as_register_form'
import Invoice_page from './invoide_and_receipt/Invoice_page'
import Receipt_page from './invoide_and_receipt/Receipt_page'
import Assigned_leads from './Components/Assigned_leads'
import Customerwalkinregistrationform from './Shared_forms/Walkin_customer_form'
import Edit_client_lead from './Components/Edit_client_lead'
import Edit_customer_lead from './Components/Edit_customer_lead'
import Trainer_dashboard from './Trainer_pages/Trainer_dashboard'
import Batches from './Trainer_pages/Batches'
import Students from './Trainer_pages/Students'
import Trainer_login from './Trainer_pages/Trainer_login'
import Protect2 from './Trainer_pages/Protect2'
import Log_sheet from './Trainer_pages/Log_sheet'
import Course_completion from './Certificates/Course_completion'
import Logsheet from './Trainer_pages/Logsheet'
import Student_login from './Student_panel/Student_login'
import Student_dashboard from './Student_panel/Student_dashboard'
import Protect3 from './Student_panel/Protect3'
import Student_batches from './Student_panel/Batches'
import Student_Logsheet from './Student_panel/Logsheet'
import Ask_doubt from './Student_panel/Ask_doubt'
import Reply_Queries from './Trainer_pages/Reply_Queries'
import Test from './Trainer_pages/Test'
import Your_queries from './Student_panel/Your_queries'
import Installment_form from './Components/Installment_form'
import Questions from './Trainer_pages/Questions'
import View_question from './Trainer_pages/View_question'
import View_coding_question from './Trainer_pages/View_coding_question'
import Student_courses from './Student_panel/Courses'
import All_Students from './Trainer_pages/All_students'
import Groups from './Student_panel/Groups'
import View_groups from './Student_panel/View_groups'
import Installments from './Student_panel/Installments'
import Trainer_Groups from './Trainer_pages/Trainer_groups'
import Student_marks from './Student_panel/Marks'
import View_marks from './Student_panel/View_marks'
import Assign_marks from './Trainer_pages/Assign_marks'
import Assign_students_marks from './Trainer_pages/Assign_students_marks'
import Leave_request from './Student_panel/Leave_request'
import Leave_approval from './Trainer_pages/Leave_approval'
import Payment from './invoide_and_receipt/Payment'
import Open_group from './Student_panel/Open_group'
import View_answer_sheet from './Trainer_pages/View_answer_sheet'
import ViewAnswerSheet from './Trainer_pages/View_answer_sheet'
import Complaint from './Student_panel/Complaint';
import Take_attendance from './Trainer_pages/Take_attendance';
import Project_dashboard from './Projects/Project_dashboard';
import Project_navbar from './Projects/Project_navbar';
import View_category from './Projects/View_category';
import Admin_login from './Admin_panel/Admin_login';
import Protect4 from './Admin_panel/Protect4';
import Admin_dashboard from './Admin_panel/Admin_dashboard';
import Batch_details from './Admin_panel/Batch_details';
import Students_under_batch from './Admin_panel/Students_under_batch';
import Students_Logsheet from './Admin_panel/Students_logsheet';
import Add_details from './Admin_panel/Add_details';
import View_Employees from './Admin_panel/View_employees';
import View_all_Employees from './Components/View_all_employees';
import Sidebar from './Student_panel/Sidebar';
import Approvals from './Admin_panel/Approvals';
import Performa_Invoice_page from './invoide_and_receipt/Performa_invoice';
import Trainers from './Admin_panel/Trainers';
import Footer from './Projects/Footer';
import Explore_project from './Projects/Explore_project';
import Feedback from 'react-bootstrap/esm/Feedback';
import Feedback_page from './Student_panel/Feedback';
import Birthday from './Student_panel/Birthday';
import View_document from './Projects/View_document';
import View_events from './Student_panel/View_events';
import ScrollToTop from './Scroll_to_top';
import Refer from './Student_panel/Refer';
import Interview_details from './Student_panel/Interview_details';
import axios from 'axios';
import Emp_profile from './Components/Emp_profile';
import Trainer_profile from './Trainer_pages/Trainer_profile';
import Student_profile from './Student_panel/Student_profile';
import Referral_form from './Student_panel/Referral_form';
import Add_projects from './Components/Add_projects';
import Add_events from './Components/Add_events';
import View_courses from './Student_panel/View_courses';
import Student_performa_Invoice_page from './Student_panel/Student_performa_invoice';
import Campaign_from from './Shared_forms/Campaign_form';
import Assign_students from './Components/Assign_students';
import Applied_list from './Admin_panel/Applied_list';
import All_students from './Components/All_students';
import All_students_list from './Components/All_students';
import Leave_request_list from './Components/Leave_request_list';
import Trainer_details from './Admin_panel/Trainer_details';
import Leave_Performa_Invoice_page from './invoide_and_receipt/Leave_performa_invoice';
import Payment_page from './Student_panel/Payment_page';
import View_employee_leads from './Components/View_employee_leads';
import View_Activities from './Components/View_activity';
import View_lead_details from './Components/View_lead_details';
import Add_activity_module from './Components/Add_activity_module';
import Registered_students from './Components/Registered_students';
import Allleads from './Admin_panel/All_leads';
import View_all_leads from './Admin_panel/View_all_leads';
import View_batch_details from './Admin_panel/View_batch_details';
import Loginpage from './Components/Loginppage'
import Employee_profile from './Components/Employe_profile'
import Cretificate2 from './Certificates/Certificate2'
import Student_leave_requests from './Student_panel/Student_leave_requests'
import Registration_form from './Shared_forms/Registration_form'
import Event_Performa_Invoice_page from './invoide_and_receipt/Event_performa_invoice'
import Add_Employees from './Components/Add_Employees'
import Add_batch from './Components/Add_batch'
import Client_quotation_register_form from './Components/Client_quotation_register_form'
import All_upcoming_batches from './Components/All_upcoming_batces'
import { pdfjs } from 'react-pdf'
// import 'bootstrap/dist/css/bootstrap.min.css';


export const ipaddress="https://crmclient.skilllearningacademy.com"
export const ipaddress2="https://crmclient.skilllearningacademy.com"
// export const ipaddress="http://192.168.18.11:9000"
// export const ipaddress2="http://192.168.18.11:9000"

export const role="center manager"
const App = () => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/build/pdf.worker.min.mjs',
  //   import.meta.url,
  // ).toString();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

//   pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const[designation,setDesignation]=useState("")
  return (
    <div>
        <BrowserRouter>
        <ToastContainer/>
        <ScrollToTop/>
        <Routes>
        <Route path='/' element={<Loginpage/>}/>
            <Route path='/dashboard/*' element={<Protect Child={Dashboard} Child2={Admin_dashboard}/>}/>
            <Route path='/leads' element={<Leads designation={designation}/>}/>
            <Route path='/customerleads' element={<Customerleads designation={designation}/>}/>
            <Route path='/employees' element={<Employess designation={designation}/>}/>
            <Route path='/activities' element={<Activities designation={designation}/>}/>
            <Route path='/clientregistrationform' element={<Clientregistrationform designation={designation}/>}/>
            <Route path='/edit_client_lead/:id' element={<Edit_client_lead/>}/>
            <Route path='/edit_customer_lead/:id' element={<Edit_customer_lead/>}/>
            <Route path='/customerregistrationform' element={<Customerregistrationform designation={designation}/>}/>
            <Route path='/register_form' element={<Campaign_from/>}/>
            <Route path='/mark_as_register_form/:id' element={<Mark_as_register_form designation={designation}/>}/>
            <Route path='/client_register_form/:id' element={<Client_quotation_register_form/>}/>
            <Route path='/invoice/:id' element={<Invoice_page/>}/>
            <Route path='/view_invoice/:id' element={<Invoice/>}/>
            <Route path='/performa_invoice/:id/' element={<Performa_Invoice_page/>}/>
            <Route path='/receipt/:token/' element={<Receipt_page/>}/>
            <Route path='/employee_profile/:id' element={<Employee_profile/>}/>
            <Route path='/add_employees' element={<Add_Employees/>}/>
            <Route path='/assigned_leads' element={<Assigned_leads/>}/>
            <Route path='/lead_registration_form' element={<Customerwalkinregistrationform/>}/>
            <Route path='/installment_form/:id' element={<Installment_form/>}/>
            <Route path='/view_all_employees' element={<View_all_Employees/>}/>
            <Route path='/profile' element={<Emp_profile/>}/>
            <Route path='/add_projects' element={<Add_projects/>}/>
            <Route path='/add_events' element={<Add_events/>}/>
            <Route path='/add_activity_module' element={<Add_activity_module/>}/>
            <Route path='/assign_students' element={<Assign_students/>}/>
            <Route path='/students_list' element={<All_students_list/>}/>
            <Route path='/payments' element={<Leave_request_list/>}/>
            <Route path='/leave_performa_invoice/:id/' element={<Leave_Performa_Invoice_page/>}/>
            <Route path='/event_performa_invoice/:id/' element={<Event_Performa_Invoice_page/>}/>
            <Route path='/view_employee_leads/:id/' element={<View_employee_leads/>}/>
            <Route path='/view_employee_activity/:id/' element={<View_Activities/>}/>
            <Route path='/lead_details/:email/' element={<View_lead_details/>}/>
            <Route path='/:register_value/registered_students/:id/:course_id' element={<Registered_students/>}/>
            <Route path='/all_leads' element={<View_all_leads/>}/>
            <Route path='/batch_details' element={<View_batch_details/>}/>
            <Route path='/add_batch' element={<Add_batch/>}/>
            <Route path='/upcoming_batches' element={<All_upcoming_batches/>}/>

            {/* Trainer */}
            <Route path='/trainer_login' element={<Trainer_login/>}/>
            <Route path='/trainer_dashboard' element={<Protect2 Child={Trainer_dashboard}/>}/>
            {/* <Route path='/trainer_dashboard' element={<Trainer_dashboard/>}/> */}
            <Route path='/batches' element={<Batches/>}/>
            <Route path='/assign_marks' element={<Assign_marks/>}/>
            <Route path='/students/:id' element={<Students/>}/>
            <Route path='/assign_students_marks/:id' element={<Assign_students_marks/>}/>
            <Route path='/all_students' element={<All_Students/>}/>
            <Route path='/reply_for_queries/:id' element={<Reply_Queries/>}/>
            <Route path='/log_sheet/:id/:batch_id' element={<Log_sheet/>}/>
            <Route path='/logsheet/:id/:batch_id' element={<Logsheet/>}/>
            <Route path='/attendance/:id/:batch_id' element={<Take_attendance/>}/>
            <Route path='/assign_test' element={<Test/>}/>
            <Route path='/your_groups' element={<Trainer_Groups/>}/>
            <Route path='/leave_approval' element={<Leave_approval/>}/>
            <Route path='/question_papers' element={<Questions/>}/>
            <Route path='/mcq_question/:id' element={<View_question/>}/>
            <Route path='/coding_question/:id' element={<View_coding_question/>}/>
            <Route path='/view_answer_sheet/:student_id/:batch_id' element={<ViewAnswerSheet/>}/>
            <Route path='/trainer_profile' element={<Trainer_profile/>}/>
            <Route path='/students_list' element={<All_students_list/>}/>


            {/* Student */}
            <Route path='/student_login' element={<Student_login/>}/>
            <Route path='/student_dashboard' element={<Protect3 Child={Student_dashboard}/>}/>
            {/* <Route path='/student_dashboard' element={<Student_dashboard/>}/> */}
            <Route path='/student_batches' element={<Student_batches/>}/>
            <Route path='/student_marks' element={<Student_marks/>}/>
            <Route path='/view_marks/:id' element={<View_marks/>}/>
            <Route path='/student_courses' element={<Student_courses/>}/>
            <Route path='/student_logsheet/:id' element={<Student_Logsheet/>}/>
            <Route path='/your_queries/:id' element={<Your_queries/>}/>
            <Route path='/ask_doubt/:id' element={<Ask_doubt/>}/>
            <Route path='/groups/:id' element={<Groups/>}/>
            <Route path='/view_group/:id/:batch_id' element={<Open_group/>}/>
            <Route path='/view_group' element={<View_groups/>}/>
            <Route path='/installments' element={<Installments/>}/>
            <Route path='/leave_request' element={<Leave_request/>}/>
            <Route path='/complaint' element={<Complaint/>}/>
            <Route path='/your_leaves' element={<Student_leave_requests/>}/>
            <Route path='/feedback' element={<Feedback_page/>}/>
            <Route path='/birthday' element={<Birthday/>}/>
            <Route path='/view_events' element={<View_events/>}/>
            <Route path='/view_courses' element={<View_courses/>}/>
            <Route path='/refer' element={<Refer/>}/>
            <Route path='/interview_details' element={<Interview_details/>}/>
            <Route path='/student_profile' element={<Student_profile/>}/>
            <Route path='/referral_form' element={<Referral_form/>}/>
            <Route path='/installments_list/:id' element={<Payment_page/>}/>
            <Route path='/student_performa_invoice' element={<Student_performa_Invoice_page/>}/>


            <Route path='/project_dashboard' element={<Project_dashboard/>}/>
            <Route path='/view_category/:category_id' element={<View_category/>}/>
            <Route path='/explore_project/:category_id/:id' element={<Explore_project/>}/>



            {/* Certificates */}
            <Route path='/course_completion_certificate/:id' element={<Course_completion/>}/>
            <Route path='/achievement_certificate' element={<Cretificate2/>}/>
            <Route path='/payment' element={<Payment/>}/>
            <Route path='/registration_form/:id/' element={<Registration_form/>}/>

            {/* Admin Panel */}
            <Route path='/admin_panel' element={<Admin_login/>}/>
            <Route path='/admin_dashboard' element={<Protect4 Child={Admin_dashboard}/>}/>
            <Route path='/all_batches' element={<Batch_details/>}/>
            <Route path='/trainers' element={<Trainers/>}/>
            <Route path='/add_details' element={<Add_details/>}/>
            <Route path='/view_employees' element={<View_Employees/>}/>
            <Route path='/trainer_details/:id' element={<Trainer_details/>}/>
            <Route path='/approvals' element={<Approvals/>}/>
            <Route path='/applied_list' element={<Applied_list/>}/>
            <Route path='/students_under_batch/:id' element={<Students_under_batch/>}/>
            <Route path='/students_logsheet/:id' element={<Students_Logsheet/>}/>


            <Route path='/footer' element={<Footer/>}/>

        </Routes>
        </BrowserRouter>

        {/* <Activity/> */}

    </div>
  )
}

export default App