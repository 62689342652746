import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ipaddress } from '../App'

const Edit_client_lead = () => {

  const navigate=useNavigate()
  const {id}=useParams()

  const[availablecourses,setAvailablecourses]=useState([])
  const[lead_levels,setlead_levels]=useState([])

  const user=JSON.parse(sessionStorage.getItem('user'))

  useEffect(()=>{
    axios(`${ipaddress}/AllcoursesnameView/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setAvailablecourses(r.data.coursename)
    })
    .catch((err)=>{
      console.log("Courses fetching Error",err)
    })

    axios(`${ipaddress}/Allleadlevels/`)
    .then((r)=>{
      console.log("Lead levels successfully fetched",r.data)
      setlead_levels(r.data.leadlevels)
    })
    .catch((err)=>{
      console.log("Lead levels fetching Error",err)
    })

    axios(`${ipaddress}/LeadDetailsView/${id}/`)
    .then((r)=>{
      console.log("Lead details successfully fetched",r.data)
    setFormData(r.data)
    })
    .catch((err)=>{
      console.log("Lead details fetching Error",err)
    })
  },[id])


  const sourcedata=(e)=>{
    if(e.target.value==="walkin"){
      document.getElementById('contactedpersondiv').style.display="block"
    }
    if(e.target.value==="other"){
      document.getElementById('othersource').style.display="block"
    }
  }

  const [formData, setFormData] = useState({
    emp_id:'',
    name: '',
    email: '',
    contact_number: '',
    company_name:'',
    company_website:'',
    company_field:'',
    company_contact_number:'',
    gst_number:'',
    door_number:'',
    street_name:'',
    area:'',
    state:'',
    city:'',
    pincode:'',
    purpose:'',
    num_candidates_trained:'',
    place_of_training:'',
    preferred_duration_per_day:'',
    syllabus:'',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name:'',
    contacted_person_number:'',
    priority: '',
    level_lead: '',
    preferred_course:'',
    preferred_batch_type:'',
    preferred_batch_start_time:'',
    preferred_class_type:'',
    expected_registration_date:'',
    tentative_batch_start_date:'',
    assigned_to:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevState) => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };


  const senddetails=(e)=>{
    e.preventDefault()
    formData.emp_id=user.emp_id

    axios.put(`${ipaddress}/ClientLeadsView/${id}/`,formData)
    .then((r)=>{
      console.log("Client successfully updated",r.data)
      toast.success('Successfully updated',{
        autoClose: 2000,
        theme:'colored',
      position:'top-center'
      })

      setTimeout(()=>{
        navigate('/leads')
      },3000)
    })
    .catch((err)=>{
      console.log("Client updating error",err)
      console.log(formData)
    })
  }
  
  return (
    <div className='animate__animated animate__fadeIn bg-light'>
        <div className='container-fluid row m-0 pb-4 pt-3'>
          <div className='mb-2 d-flex'>
         <Link className='text-dark' to='/leads'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></Link> 
            <h5 className='text-success mx-auto'>EDIT CLIENT LEAD</h5>
          </div>
            <div className="col-12 bg-white py-3 shadow">
            <form className="" onSubmit={senddetails}>
{/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
                <div className="row m-0 border-bottom pb-2">
                <h6 className='text-primary'>Personal Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" name='name' value={formData.name} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Email <span className='required-field'>*</span></label>
    <input required type="email" className="form-control shadow-none bg-light py-2" id="inputPassword4" name='email' value={formData.email} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" maxLength={10} className="form-control shadow-none bg-light py-2" id="inputPassword4" name='contact_number' value={formData.contact_number} onChange={handleInputChange}/>
  </div>
                </div>

{/* -----------------------------------COMPANY DETAILS------------------------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Company Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Company Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" value={formData.company_name} name='company_name' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Company Website <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.company_website} name='company_website' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Company Field <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" value={formData.company_field} name='company_field' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Company Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" className="form-control shadow-none bg-light py-2" maxLength={10} id="inputPassword4" value={formData.company_contact_number} name='company_contact_number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputPassword4" className="form-label">GST Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.gst_number} name='gst_number' onChange={handleInputChange}/>
  </div>
                </div>

{/* --------------------------------------COMPANY ADDRESS----------------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Company Address</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Door Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" value={formData.door_number} name='door_number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Street Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" maxLength={10} value={formData.street_name} name='street_name' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Area</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.area} name='area' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">State</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" value={formData.state} name='state' onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>Tamilnadu</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">City</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" value={formData.city} name='city' onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>Madurai</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputPassword4" className="form-label">Pincode</label>
    <input type="tel" className="form-control shadow-none bg-light py-2" maxLength={6} id="inputPassword4" value={formData.pincode} name='pincode' onChange={handleInputChange}/>
  </div>
                </div>


{/* ----------COURSE DETAILS----------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Course Details</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Course Enquired <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.course_enquiredd} disabled='true' name='course_enquiredd'/>

  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Enquiry Location <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.enquiry_location} name='enquiry_location' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Source <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.source} name='source'/>
 
  </div>
  <div>
  
  <div className='' style={{display:'none'}} id='contactedpersondiv'>
  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_name} name='contacted_person_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_number} name='contacted_person_number'/>
  </div>
  </div>

{/* Other source */}
<div className="col-md-6 col-lg-4 mb-3" id='other-source' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter the source</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.source} name='source' onChange={handleInputChange}/>
  </div>
  </div>
                </div>

{/* --------------------------------LEAD DETAILS-------------------- */}
<div className="row m-0 border-bottom py-3">
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Priority <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.priority} name='priority'/>
    
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Level of Lead <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.level_lead} name='level_lead' disabled='true'/>

  </div>
</div>

<div className="row m-0 border-bottom py-3">
<h6 className='text-primary'>Purpose</h6>

<div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Purpose <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.purpose} name='purpose' onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Number of candidates trained <span className='required-field'>*</span></label>
    <input required type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.num_candidates_trained} name='num_candidates_trained' onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Place of training <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.place_of_training} name='place_of_training' onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Preffered duration per day <span className='required-field'>*</span></label>
    <input required type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.preferred_duration_per_day} name='preferred_duration_per_day' onChange={handleInputChange}/>
  </div>
  
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Syllabus <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.syllabus} name='syllabus'/>

  </div>
</div>
{/* --------------------------------Additional Details------------------------------ */}
{/* <div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Batch Details</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Preffered Course</label>
    <select id="inputState" className="form-select shadow-none bg-light" name="preferred_course" value={formData.preferred_course} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option></option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Preffered Batch Type</label>
    <select id="inputState" className="form-select shadow-none bg-light" name="preferred_batch_type" value={formData.preferred_batch_type} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option value='weekend'>Weekend</option>
      <option value='weekdays'>Weekdays</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Preferred Class Type</label>
    <select id="source" className="form-select shadow-none bg-light" name="preferred_class_type" value={formData.preferred_class_type} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option value="online">Online</option>
      <option value="offline">Offline</option>
      <option value="hybrid">Hybrid</option>
    </select>
  </div>

  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Preferred Batch Start Time</label>
    <input type="time" className="form-control shadow-none bg-light" id="inputPassword4" name="preferred_batch_start_time" value={formData.preferred_batch_start_time} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Expected Registration date</label>
    <input type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="expected_registration_date" value={formData.expected_registration_date} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Tentative Batch start date</label>
    <input type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="tentative_batch_start_date" value={formData.tentative_batch_start_date} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Created at</label>
    <input type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="created_at" value={formData.created_at} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
  <label for="source" className="form-label shadow-none">Assignto</label>
    <select id="source" className="form-select shadow-none bg-light" name="assigned_to" value={formData.assigned_to} onChange={handleInputChange}>
      <option selected>Choose...</option>
    </select>
  </div>
  
                </div> */}

  <div className="col-12 text-end mt-3">
    <button type="submit" className="btn btn-primary text-white fw-medium px-2 px-lg-5">Submit</button>
  </div>
</form>
            </div>
        </div>
    </div>
  )
}

export default Edit_client_lead