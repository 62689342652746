import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import Upcoming_events from './Upcoming_events'

const   Complaint = () => {

    const[data,setdata]=useState("")
    const[subject,setsubject]=useState("")

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[state,setstate]=useState(false)

    const send_data=()=>{
       setload(true)

       setTimeout(() => {
        send()
       }, 1000);
    }

    const send=()=>{
        axios.post(`${ipaddress2}/StudentSuggestionView/${user.email}/`,{
            suggestion:data
        })

        .then((r)=>{
            console.log("Sent successfully",r.data)
            setsubject("")
            setdata("")
            setstate(true)
            setload(false)
        })
    }
    const[load,setload]=useState()

return (
    <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"complaint"}/>
        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3 py-4" style={{minHeight:'100vh',backgroundColor:'#F5F5F5'}}>
               <div className="row m-0">
                <div className="col-lg-8 pt-4">
                <div className='d-flex flex-column align-items-center' style={{position:'relative',height:'120px'}}>
                    <img src={require('../images/Group 1171275952.png')} style={{width:'80%'}} alt="" />
                    <p className='fw-bold text-center' style={{fontSize:'46px',position:'absolute',top:'44px'}}>How can we help?</p>
                </div>
                <div className={`row m-0 complaint-div mx-auto w-75 ${state ? 'd-none':''}`}>

                    <div className="col-12 p-0 mt-4 pt-2">
                        <input className='form-control shadow-none py-3 bg-transparent' value={subject} placeholder='Subject*' onChange={(e)=>{
                            setsubject(e.target.value)
                        }} style={{border:'1px solid #57309C'}} name="" id=""/>
                    </div>
                    <div className="col-12 p-0 mt-4 pt-2">
                        <textarea onChange={(e)=>{
                            setdata(e.target.value)
                        }} rows={5} className='form-control shadow-none py-2 bg-transparent' value={data} placeholder='Your Suggestion*' style={{border:'1px solid #57309C'}} name="" id=""/>
                    </div>
                    <div className="col-12 px-0 mt-4 pt-2">
                        <button disabled={data.length>0 ? false:true} className='btn text-white w-100' onClick={()=>{
                            send_data()
                        }} style={{backgroundColor:'#57309C',fontSize:'13px',height:'40px'}}>
                             <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                            <span role="status" className={`ms-2 ${load ? '':'d-none'}`}>Sending...</span>
                            <span className={`${load ? 'd-none':''}`}>SEND NOW</span>
                        </button>
                    </div>
                </div>

                <div className={`${state ? '':'d-none'} bg-white complaint-div2 animate__animated animate__bounceIn d-flex flex-column align-items-center justify-content-center py-5 shadow-sm mt-4 mx-auto`} style={{borderRadius:'15px'}}>
                    <img src={require('../images/Screenshot 2024-06-18 at 2.23.08 PM 1.png')} width={120} alt="" />
                    <p className='fw-bold text-center mt-2'>Your suggestion has been received, and we will take appropriate action.</p>
                    {/* <p style={{color:'#8A8A8A',fontSize:'13px'}}>Your ticket is generated with- MSA90991</p> */}

                    <p className='text-decoration-underline mt-4' onClick={()=>{
                        navigate('/student_dashboard')
                    }} style={{color:'#8A8A8A',fontSize:'13px',cursor:'pointer'}}>Go to Dashboard</p>

                </div>
                </div>

                <div className="col-lg-4 px-4">
                    <Upcoming_events/>
                </div>
               </div>
            </div>
        </div>
    </div>
  )
}

export default Complaint