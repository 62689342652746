import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'
import Upcoming_events from './Upcoming_events'

const Student_courses = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Get Your Certificate
    </Tooltip>
  );

    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[courses,setcourses]=useState([])
    const[count,setcount]=useState(0)

    useEffect(()=>{
        axios.get(`${ipaddress2}/CoursesChoosenByStudent/${user.email}/`)
        .then((r)=>{
            console.log("Student courses",r.data)
            setcourses(r.data.courses)
        })
        .catch((err)=>{
          console.log("Students courses fetching error",err);
        })
    },[count])

const[course,setcourse]=useState('')
const[courseid,setcourseid]=useState()

const[start_date,setstart_date]=useState('')
const[end_date,setend_data]=useState('')

const apply_for_certificate=()=>{
  axios.post(`${ipaddress2}/CertificateRequest/${user.email}/`,{
    course_id:courseid,
    starting_date:start_date,
    ending_date:end_date
  })
  .then((r)=>{
    console.log("Successfully applied",r.data)
    toast.warn('Request Sent to Admin for Approval',{
      autoClose:2000,
      theme:'colored',
      position:'top-center'
    })
    setstart_date('')
    setend_data('')

    setcount(count+1)
  })
}

const apply_for_hard_copy=(id)=>{
  axios.post(`${ipaddress2}/CertificateRequest/${user.email}/`,{
    certificate_status:id
  })
  .then((r)=>{
    console.log("Successfully applied",r.data)
    toast.warn('Request Sent to Admin for Approval',{
      autoClose:2000,
      theme:'colored',
      position:'top-center'
    })
    setcount(count+1)
  })
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"courses"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light p-3 main-container px-4' style={{minHeight:'100vh'}}>

        <div className="row m-0">
          <div className="col-lg-8">
          <h5 className='pb-3 pt-2'>Apply and Get Your Certificates</h5>
          <h6 className={`text-center py-4 text-secondary ${courses.length>0 ? 'd-none':''}`}>Completed Courses Not Available...</h6>
            <div className={`table-responsive ${courses.length>0 ? '':'d-none'}`}>
            <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">SI No</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Course Name</p></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
  {courses.map((x,index)=>{
      return(
        <tr>
      <td className='py-3' scope="row"><p className="text-center m-0">{index+1}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.name}</p></td>
      <td className='py-3'>
        <div className="text-center">
        <button className={`btn btn-sm btn-primary px-3 ${x.respond_status!==null ? 'd-none':''}`} onClick={()=>{
          setcourse(x.name)
          setcourseid(x.id)
        }} data-bs-toggle="modal" data-bs-target="#apply_certificate">Apply for Certificate</button> 
        

    <button className={`btn btn-sm btn-primary px-3 ${x.respond_status==='rejected' ? 'text-danger':'d-none'}`}>Rejected</button> 
    <button className={`btn btn-sm fw-medium px-3 ${x.respond_status==='pending' ? 'text-warning':'d-none'}`}>Pending</button> 

        <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip}
    >
    <button className={`btn btn-sm btn-success px-3 ${x.respond_status==='approved' ? '':'d-none'}`} onClick={()=>{
      navigate(`/course_completion_certificate/${x.id}`)
    }}>Get Certificate</button> 
    </OverlayTrigger>

   

          </div></td>
          <td className='py-3'>
          <button className={`btn btn-sm btn-success px-3 ms-3 ${x.respond_status==='approved' && x.hrd_copy_status==='not applied' ? '':'d-none'}`} onClick={()=>{
      apply_for_hard_copy(x.certificate_id)
    }}>Request for Hard copy</button> 

    <p className={`text-success ms-3 ${x.respond_status==='approved' && x.hrd_copy_status==='approved' ? '':'d-none'}`}>Hard copy approved</p>
    <p className={`text-warning ms-3 ${x.respond_status==='approved' && x.hrd_copy_status==='applied' ? '':'d-none'}`}>Hard copy applied</p>
          </td>
    </tr>
      )
    })}
  </tbody>
</table>
            </div>
          </div>

          <div className="col-lg-4 px-4">
            <Upcoming_events/>
          </div>
        </div>
        </div>
        </div>

{/* Apply for certificate */}
<div class="modal fade" id="apply_certificate" tabindex="-1" aria-labelledby="apply_certificateLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h1 class="modal-title fs-6" id="exampleModalLabel">Fill the Form</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div className='p-2'>
          <div className="row m-0">
            <div className="col-12 mb-3 mb-md-4">
              <label htmlFor="" className='fw-medium'>Course Name</label>
              <input type="text" value={course} disabled='true' className='form-control mt-2 shadow-none border-secondary-subtle py-2 bg-light' />
            </div>

            <div className="col-md-6 mb-3 mb-md-4">
              <label htmlFor="" className='fw-medium'>Start Date</label>
              <input type="date" value={start_date} onChange={(e)=>{
                setstart_date(e.target.value)
              }} className='form-control mt-2 shadow-none border-secondary-subtle py-2 bg-light' />
            </div>

            <div className="col-md-6 mb-3 mb-md-4">
              <label htmlFor="" className='fw-medium'>End Date</label>
              <input type="date" value={end_date} onChange={(e)=>{
                setend_data(e.target.value)
              }} className='form-control mt-2 shadow-none border-secondary-subtle py-2 bg-light' />
            </div>

            <div className='text-end mt-2'>
              <button data-bs-dismiss="modal" onClick={()=>{
                apply_for_certificate()
              }} className='btn btn-sm btn-primary px-4 py-2'>Submit</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
    </div>
  )
}

export default Student_courses