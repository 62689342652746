import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import Slider from 'react-slick'
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';

const Applied_list = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
        // initialSlide: 0,
        // autoplay: true,
        speed: 500,
        // autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const[type,settype]=useState('events')
    const[interview_details,setinterview_details]=useState([])
    const[event_details,setevent_details]=useState([])

    useEffect(()=>{
        fetch_events()
    },[])

    const fetch_events=()=>{
      axios.get(`${ipaddress}/registered-events/`)
      .then((r)=>{
          console.log("Events list",r.data)
          setevent_details(r.data)
      })
      .catch((err)=>{
          console.log("Events error",err);
      })
  }

    const fetch_interview=()=>{
        axios.get(`${ipaddress}/applications-list/`)
        .then((r)=>{
            console.log("Interview list",r.data)
            setinterview_details(r.data)
        })
        .catch((err)=>{
            console.log("Interview error",err);
        })
    }


return (
    <div className='d-flex'>
        <Sidebar activevalue={"list"}/>
        <div className="w-100">
            <Navbar/>

            <div className="p-3 bg-light main-container" style={{minHeight:'100vh'}}>
                <div className="container">
                <div className="mt-3 container">
      <Slider {...settings} className='px-2'>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('events')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='events' ? '#57309C':'',cursor:'pointer',borderBottom:type==='events' ? '2px solid #57309C':'none'}}>Events</p>
        </div>
        {/* <div className='p-2 text-center'>
        <p onClick={()=>{
                        settype('course')
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='course' ? '#57309C':'',cursor:'pointer',borderBottom:type==='course' ? '2px solid #57309C':'none'}}>Courses</p>
        </div> */}
        <div className='p-2'>
        <p onClick={()=>{
                        settype('interview')
                        fetch_interview()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='interview' ? '#57309C':'',cursor:'pointer',borderBottom:type==='interview' ? '2px solid #57309C':'none'}}>Interview</p>
        </div>
      </Slider>
    </div>
                </div>

{/* Events */}

<div className={`${type==='events' ? '':'d-none'}`}>
<h6 className={`text-center py-4 text-secondary ${event_details.length>0 ? 'd-none':''}`}>No data available...🧐</h6>

    <div className={`table-responsive ${event_details.length>0 ? '':'d-none'}`}>
    <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      {/* <th scope="col"><p className='text-center m-0'>Image</p></th> */}
      <th scope="col"><p className='text-center m-0'>Event Name</p></th>
      <th scope="col"><p className='text-center m-0'>Registered on</p></th>
    </tr>
  </thead>
  <tbody>
    {event_details.map((x,index)=>{
      return(
        <tr>
      <th scope="row"><p className='text-center m-0'>{index+1}</p></th>
      {/* <td><div>
        <img src={x.events && x.events.image && x.events.image} alt="" />
        </div></td> */}
      <td><p className='text-center m-0'>{x.events && x.events.description}</p></td>
      <td><p className='text-center m-0'>{x.events && x.events.date_time && x.events.date_time.slice(0,10)}</p></td>
    </tr>
      )
    })}
  </tbody>
</table>
    </div>

</div>



{/* Courses */}

<div className={`${type==='course' ? '':'d-none'}`}>
<div className='table-responsive'>
    <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      <th scope="col"><p className='text-center m-0'>First</p></th>
      <th scope="col"><p className='text-center m-0'>Last</p></th>
      <th scope="col"><p className='text-center m-0'>Handle</p></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row"><p className='text-center m-0'>1</p></th>
      <td><p className='text-center m-0'>Mark</p></td>
      <td><p className='text-center m-0'>Otto</p></td>
      <td><p className='text-center m-0'>@mdo</p></td>
    </tr>
  </tbody>
</table>
    </div>

</div>

{/* Interview */}

<div className={`${type==='interview' ? '':'d-none'}`}>
<h6 className={`text-center py-4 text-secondary ${interview_details.length>0 ? 'd-none':''}`}>No data available...🧐</h6>

<div className={`table-responsive ${interview_details.length>0 ? '':'d-none'}`}>
    <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      <th scope="col"><p className='text-center m-0'>Student Name</p></th>
      <th scope="col"><p className='text-center m-0'>Interview Date</p></th>
      <th scope="col"><p className='text-center m-0'>Position</p></th>
    </tr>
  </thead>
  <tbody>
    {interview_details.map((x,index)=>{
        return(
            <tr>
      <td scope="row"><p className='text-center m-0'>{index+1}</p></td>
      <td><p className='text-center m-0'>{x.student && x.student.customer_name}</p></td>
      <td><p className='text-center m-0'>{x.interview && x.interview.interview_date && x.interview.interview_date.slice(0,10)}</p></td>
      <td><p className='text-center m-0'>{x.interview && x.interview.position}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
    </div>

</div>


            </div>
        </div>

    </div>
  )
}

export default Applied_list