import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import axios from 'axios';
import { ipaddress } from '../App';
import Slider from 'react-slick';
import Preloader from './Preloader';
import { useNavigate, useParams } from 'react-router-dom';

const View_Activities = ({ designation }) => {

  const[loading,setLoading]=useState()
  const {id}=useParams()

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const user = JSON.parse(sessionStorage.getItem('user'));

  const [filterdropdownstate, setfilterdropdownstate] = useState(false);
  const [selected_stage, setselected_stage] = useState('all');
  const [selected_sub_stage, setselected_sub_stage] = useState('');

  const [selected_new_stage, setselected_new_stage] = useState('all');
  const [selected_new_sub_stage, setselected_new_sub_stage] = useState('');


  const [value, setvalue] = useState('Leads Coursewise');

  const handleradiochange = (e) => {
    if (e.target.value === 'all') {
      setselected_sub_stage('');
    }
    if(e.target.value==='registered'){
      setselected_sub_stage('');
    }
    setselected_stage(e.target.value);
  };

  const setdata=()=>{
    setselected_new_stage(selected_stage)
    setselected_new_sub_stage(selected_sub_stage)
    setfilterdropdownstate(false)
  }



  const currentDate = new Date();

  // Get the current day of the month
  const currentDay = currentDate.getDate();

  // Get the number of days in the current month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  // Generate an array of days in the month
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Format the date for display in the table headers
// Format the date for display in the table headers
const formatDate = (day) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Month is zero-based
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};


  const [activities, setactivities] = useState([]);
  const [modules, setmodules] = useState([]);
  const [counts, setcounts] = useState({});
  const [activities_achieved, setactivities_achieved] = useState([]);

  useEffect(() => {
    // fetch_all_activities();
    // fetch_counts()
    setLoading(true)
    fetch_modules()
  }, []);

  const fetch_modules=()=>{
    axios.get(`${ipaddress}/AllModules/?emp_id=${id}`)
    .then((r)=>{
      console.log("Modules",r.data)
      setmodules(r.data)
      if(r.data.length>0){
        setselected_new_stage(r.data[0].module_name)
      fetch_all_activities(r.data[0].module_name)
      }
      setLoading(false)
    })
    .catch((err)=>{
      console.log("error",err);
      console.log("URL",`${ipaddress}/AllModules/?emp_id=${id}`);
    })
  }

  const fetch_all_activities = (name) => {
    axios.get(`${ipaddress}/ActivityView/${id}/${name}/`)
      .then((r) => {
        console.log('All Activities', r.data);
        setactivities(r.data);
        const data=r.data.map((x)=>x.daily_achieved)
        console.log("Datttttaaa",data)
        setactivities_achieved(data)
        // setLoading(false)
      })
      
  };

  // const fetch_activity_counts
  const fetch_counts = () => {
    axios.get(`${ipaddress}/ActivityCounts/${id}/`)
      .then((r) => {
        console.log('All Counts', r.data);
        setcounts(r.data);
      })
      
  };

  // ---------------------------------------------Send the today activity count--------------------------------------------

  const send_activity_count=(value,date,activity_name)=>{
    // if(value>0){
      axios.post(`${ipaddress}/AddAchieved/`,{
        module_name:selected_new_stage,
        activity_name:activity_name,
        achieved:value,
        date:date
      })
      .then((r)=>{
        console.log("Activity count sent",r.data)
        fetch_all_activities(selected_new_stage)
      })
      .catch((err)=>{
        console.log("Activity count sending error",err)
        console.log("Value",value)
      })
    // }
  }

  const[addstatus,setaddstatus]=useState(false)
  const navigate=useNavigate()

  const edit_target=(activity_id,count)=>{
    axios.put(`${ipaddress}/activity/edit-target/${id}/${activity_id}/`,{
      new_target:count
    })
    .then((r)=>{
      console.log("Target updated",r.data)
      fetch_all_activities(selected_new_stage)
      
    })
  }

  const delete_activity=(activity_id)=>{
    axios.delete(`${ipaddress}/activity/delete/${id}/${activity_id}/`)
    .then((r)=>{
      console.log("Target deleted",r.data)
      fetch_all_activities(selected_new_stage)
    })
  }

  return (
    <div>
      {loading ? (<Preloader/>):(
          <div className='d-flex'>
          <Sidebar designation={designation} activevalue={'employees'} />
          <div className='w-100 main-div'>
            <Navbar designation={designation} pagename={'Activities'} />
            <div className='bg-light pt-3 main-container' style={{ minHeight: '100vh' }}>
            <p className='px-3'><svg style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/employee_profile/${id}`)
            }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></p>
    
              <div style={{height:'70vh'}} className={`d-flex flex-column align-items-center justify-content-center ${activities.length>0 ? 'd-none':'d-none'}`}>
                {/* <img src={require('../images/grpemtboi.png')} style={{width:'25%'}} alt="" /> */}
                <p className='fw-medium fs-5 mt-3' style={{color:'#D1A870'}}>No Activities Assigned...🧐</p>
              </div>
    
              <div className={`container ${activities.length>0 ? '':''}`}>
              
              <div className='pb-3' style={{ position: 'relative' }}>
                  <button onClick={() => {
                    setfilterdropdownstate(!filterdropdownstate);
                  }} className='btn btn-sm text-white sidebar-color rounded-pill px-3 d-flex align-items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>
                    <span className='ms-1'>Activities</span>
                  </button>
    
                  <div className={`bg-white border rounded w-25 py-3 mt-2 px-3 animate__animated animate__fadeIn ${filterdropdownstate ? '' : 'd-none'}`} style={{ position: 'absolute',zIndex:10}}>
                    <h6 className='d-flex align-items-center pb-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
</svg>
                      <span className='ms-1'>Activities tables</span>
                    </h6>
                    <div className='d-flex'>
                      <ul className={`${selected_stage === 'all' || selected_stage === 'registered' || selected_stage === 'billing' || selected_stage === 'collection' ? '' : 'filter border-end'} ps-0`}>
                       {modules.map((x,index)=>{
                        return(
                          <>
                          <li className='d-flex align-items-center mb-3'><span style={{cursor:'pointer'}} className='ms-2' onClick={()=>{
                            setselected_new_stage(x.module_name)
                            fetch_all_activities(x.module_name)
                            setfilterdropdownstate(false)
                          }}>{x.module_name}</span></li>
                          </>
                        )
                       })}
                         </ul>
                      <div className={`px-3 ${selected_stage === 'all' || selected_stage === 'registered' || selected_stage === 'billing' || selected_stage === 'collection' ? 'd-none' : ''}`} style={{ width: '60%' }}>
                        <p className='m-0 mb-2 text-primary'>{selected_stage}</p>
                        <select value={selected_sub_stage} name='' className='form-select shadow-none border-secondary-subtle' id='' onChange={(e) => {
                          setselected_sub_stage(e.target.value);
                        }}>
                          <option value=''>Select</option>
                          <option value='coursewise'>Course Wise</option>
                          <option value='sourcewise'>Source Wise</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
    
    
             
              
    
                {/* ---------------------------------------------------Activities table------------------------------------------------- */}
    
    <h6 className={`text-secondary text-center py-3 ${activities.length>0 ? 'd-none':''}`}>No Assigned Activities...🧐</h6>
                <div onClick={() => {
                  setfilterdropdownstate(false);
                }} className={`table-responsive rounded bg-white border-0 py-3 mt-2 ${activities.length>0 ? '':'d-none'}`}>
                  <h5 className='mb-3'>Activities Table</h5>
                  <table className='table caption-top rounded'>
                    <thead className='table-secondary'>
                      <tr className='bg-white'>
                        <th></th>
                        <th scope='col' className='py-3 px-3' 
                        // style={{position:'sticky',left:'0px'}}
                        >
                          <span className='fw-bold' 
                          style={{ color: '#000', fontSize: '14px'}}>S.No</span>
                        </th>
                        <th scope='col' className='py-3 px-3' 
                        // style={{position:'sticky',left:'32px'}}
                        >
                          <span className='fw-bold' 
                          style={{ color: '#000', fontSize: '14px'}}>Activities</span>
                        </th>
                        <th scope='col' className='py-3 px-3' 
                        // style={{position:'sticky',left:'104px'}}
                        >
                          <span className='fw-bold' 
                          style={{ color: '#000', fontSize: '14px'}}>Target</span>
                        </th>
                        <th scope='col' className='py-3 px-3' 
                        // style={{position:'sticky',left:'160px'}}
                        >
                          <span className='fw-bold' 
                          style={{ color: '#000', fontSize: '14px'}}>Achieved</span>
                        </th>
                        {daysArray.map(day => (
                          <th key={day} scope='col' className='py-3 px-3' style={{ width: '100px'}}>
                            <p className='fw-medium m-0 text-center' style={{ color: '#000', fontSize: '13px' }}>{formatDate(day)}</p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className=''>
                      {activities.map((activity, index) => (
                        <tr key={activity.id} className={`${index%2!=0 ? 'table-primary':'table-white'}`}>
                          <td className='py-3 px-2'>
                            <div className='text-center'>
                            <svg style={{cursor:'pointer'}} onClick={()=>{
                              delete_activity(activity.id)
                            }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#FD4646" class="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
</svg>
                            </div>
                          </td>
                          <td className='py-3 px-3' scope='row' 
                          // style={{position:'sticky',left:'0px'}}
                          >
                            {index + 1}
                          </td>
                          {activity.activity_name !=undefined && (<td className='py-3 fw-medium' 
                          // style={{position:'sticky',left:'32px'}}
                          >{activity.activity_name}</td>)}
                          <td className='py-3 px-3' 
                          // style={{position:'sticky',left:'170px'}}
                          ><input type="text" value={activity.targets} 
                          className='form-control shadow-none border-secondary-subtle'
                          onChange={(e)=>{
                            edit_target(activity.id,Number(e.target.value))
                          }}/></td>
                          <td className='py-3 px-3' 
                          // style={{position:'sticky',left:'228px'}}
                          ><p className='rounded text-center text-white fw-medium py-2' style={{height:'33px',backgroundColor:activity.status==='green' ? '#3FE258':activity.status==='red' ? '#fa6461':activity.status==='yellow' ? '#fa8c59':''}}>{activity.Total_Achived}</p></td>
                          {daysArray.map((day) => {
                            // console.log("Date",formatDate(currentDay))
                            let obj = activities_achieved[index].find((obj) => obj.date == formatDate(day))
                            return(
                              <>
                               <td key={`${activity.id}-${day}`} className='py-3 px-4' style={{ width: '100px' }}>
                              <div className='d-flex justify-content-center'>
                              <input
                                type='number'
                                className={`form-control shadow-none ${day === currentDay ? 'border-primary':'border-secondary-subtle'}`}
                                placeholder='0'
                                value={obj && obj.achieved}
                                style={{ width: '80px',backgroundColor:obj && obj.status==='0' ? '#FFFF' : obj && obj.status==='1' ? '#fa6461': obj && obj.status==='2' ? '#fa8c59': obj && obj.status==='3' ? '#3FE258':'#FFF'}} // Adjust the width as needed
                                disabled={day !== currentDay}
                                onChange={(e) => {
                                  send_activity_count(Number(e.target.value),formatDate(day),activity.activity_name)
                                  // console.log(formatDate(day))
                                }}
                              />
                              </div>
                            </td>
                              </>
                            )
                           
    })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
    
    {/* ---------------------------------------------------Leads Acitivity table based on Source and Course wise------------------- */}
    
    {/* <Activity_table lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_leads_sourcewise lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_table_walkin_course lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_table_walkin_source lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_table_prospect_course lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_prospect_sourcewise lead_stage={selected_new_stage} type={selected_new_sub_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    
    <Activity_registered lead_stage={selected_new_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_billing lead_stage={selected_new_stage} setfilterdropdownstate={setfilterdropdownstate}/>
    <Activity_collection lead_stage={selected_new_stage} setfilterdropdownstate={setfilterdropdownstate}/> */}
    
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default View_Activities;
