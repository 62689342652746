import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress } from '../App'

const Trainer_details = () => {
    const {id}=useParams()
    const[trainer,settrainer]=useState({})
    const[batches,setbatches]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress}/trainer-history/${id}/`)
        .then((r)=>{
            console.log("Trainer details",r.data);
            settrainer(r.data.trainer_name)
            setbatches(r.data.batches)
        })
        .catch((err)=>{
            console.log("Trainer details error",err);
        })
    },[id])

    const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split('T')[1].split(':');
        return `${hours}:${minutes}`;
      };

      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };

  return (
    <div className='d-flex'>
        <Sidebar/>
        <div className="w-100">
            <Navbar/>
            <div className="p-3 bg-light main-container">
                <h5 className='text-success mb-4'>{trainer.first_name}'s Profile</h5>
                <div className='row m-0 bg-white rounded shadow-sm pt-3 px-4'>
                    <div className="col-lg-6 mb-4">
                        <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> First Name : </span>{trainer.first_name}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Last Name : </span>{trainer.last_name}</p>
                    </div>
                    <div className="col-lg-6">
                        <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Email : </span>{trainer.email}</p>
                    </div>
                    <div className="col-lg-6">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Employee Id : </span>{trainer.emp_id}</p>
                    </div>
                </div>

                <div className={`row m-0 mt-4 pt-3 ${batches.length>0 ? '':'d-none'}`}>
                    <h5 className='pb-2'>Batches Handles by {trainer.first_name}</h5>
     {batches.map((x)=>{
      return(
        <div className="col-md-3 pe-0 mb-4">
        <div class="card p-3" style={{borderRadius:'15px'}}>
    {/* <img style={{borderRadius:'15px'}} src={require('../images/trainer/fakurian-design-58Z17lnVS4U-unsplash (1) 1.png')} class="card-img-top" alt="..."/> */}
    <div class="card-body py-0 px-1">
      <div className='pt-3'>
      <h5 class="card-title m-0 text-primary" style={{color:'#1B2559',cursor:'pointer'}}>{x.batch_id}</h5>
      <p class="card-text" style={{color:'#A3AED0',fontSize:'13px'}}>By {capitalizeFirstLetter(trainer.first_name)}</p>
      </div>
      <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{x.start_time.slice(0,10)}</span></p>
  
  <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{x.end_time.slice(0,10)}</span></p>
     </div>
     <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{formatTime(x.start_time)}</span></p>
  <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{formatTime(x.end_time)}</span></p>
     </div>
    </div>
  </div>
        </div>
      )
     })}
    </div>
            </div>
        </div>

    </div>
  )
}

export default Trainer_details