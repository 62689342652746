import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const View_marks = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View your Queries
    </Tooltip>
  );

  const {id}=useParams()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[marks,setmarks]=useState([])
    
    useEffect(()=>{
        axios.get(`${ipaddress2}/StudentMarksDisplayBasedOnCourse/${user.email}/${id}/`)
        .then((r)=>{
            console.log("Student marks",r.data)
            setmarks(r.data)
        })
    },[])


return (
    <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"marks"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
        <svg onClick={()=>{
              navigate('/student_marks')
            }} style={{cursor:'pointer'}}  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
        <h6 className={`text-center text-secondary py-4 ${marks.length>0 ? 'd-none':''}`}>No data available...🧐</h6>

            <div className={`table-responsive ${marks.length>0 ? '':'d-none'}`}>
                <h6><svg style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/student_marks')
                }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></h6>
            <table class="table">
  <thead>
    <tr>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">SI.No</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Test Name</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Conducted On</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Test Time</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Total Marks</p></th>
      <th scope="col" className='fw-normal text-secondary py-3'><p className="text-center m-0 fw-medium">Marks Scored</p></th>
    </tr>
  </thead>
  <tbody>
  {marks.map((x,index)=>{
      return(
        <tr>
      <td className='py-3' scope="row"><p className="text-center m-0">{index+1}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.test_name}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.test_date && x.test_date.slice(0,10)}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.test_date.split('T')[1].split('Z')[0]}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.out_of}</p></td>
      <td className='py-3'><p className="text-center m-0">{x.marks_gained}</p></td>

    </tr>
      )
    })}
  </tbody>
</table>
            </div>
        </div>
        </div>
    </div>
  )
}

export default View_marks