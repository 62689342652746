import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'

const Assign_marks = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Queries
    </Tooltip>
  );

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[batches,setbatches]=useState([])

    useEffect(()=>{
      axios.get(`${ipaddress2}/TrainerView/${user.id}/`)
      .then((r)=>{
        console.log("Batch details",r.data)
        setbatches(r.data)
      })
    },[])

    const[batch_id,setbatch_id]=useState()

    const[group_name,setgroup_name]=useState("")

    const create_group=()=>{
      axios.post(`${ipaddress2}/create-group/`,{
        group_name:group_name,
        trainer_id:user.emp_id,
        batch_id:batch_id                   
      })
      .then((r)=>{
        console.log("Group created",r.data)
        setgroup_name("")
        toast.success('Group created successfully',{
          autoClose:2000
        })
      })
    }

      // To get the time in hours and minutes format
      const formatTime = (timeString) => {
        const [hours, minutes] = timeString.split('T')[1].split(':');
        return `${hours}:${minutes}`;
      };

return (
    <div className='d-flex'>
        <Sidebar activevalue={"assignmarks"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{minHeight:'80vh'}} className='bg-light p-3 main-container'>
              <h5>Batches to View Marks</h5>
                <div>
                <h6 className={`text-secondary text-center py-4 ${batches.length>0 ? 'd-none':''}`}>No batches available...🧐</h6>

<div class={`table-responsive mt-4 ${batches.length>0 ? '':'d-none'}`}>                    
<table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='m-0 text-center'>SI.No</p></th>
      <th scope="col"><p className='m-0 text-center'>Subject Name</p></th>
      <th scope="col"><p className='m-0 text-center'>Classroom</p></th>
      <th scope="col"><p className='m-0 text-center'>Batch Start Date</p></th>
      <th scope="col"><p className='m-0 text-center'>Batch Start Time</p></th>
      <th scope="col"><p className='m-0 text-center'>Batch End Date</p></th>
      <th scope="col"><p className='m-0 text-center'>Batch End Time</p></th>
    </tr>
  </thead>
  <tbody>
    {batches.map((x,index)=>{
      return(
        <tr>
      <td scope="row"><p className='m-0 text-center'>{index+1}</p></td>
      <td style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/assign_students_marks/${x.id}`)
    }}><p className='m-0 text-center text-primary fw-medium'>{x.name.sub_course_name}</p></td>
      <td><p className='m-0 text-center'>{x.classroom.name}</p></td>
      <td><p className='m-0 text-center'>{x.start_time.slice(0,10)}</p></td>
      <td><p className='m-0 text-center'>{formatTime(x.start_time)}</p></td>
      <td><p className='m-0 text-center'>{x.end_time.slice(0,10)}</p></td>
      <td><p className='m-0 text-center'>{formatTime(x.end_time)}</p></td>   
    </tr>
      )
    })}
    
  </tbody>
</table>
</div>
                </div>
            </div>
        </div>

{/* Group Modal */}
<div class="modal fade" id="groupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h1 class="modal-title fs-6 text-primary" id="exampleModalLabel">Create the Group</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div>
          <label htmlFor="">Batch Id</label>
          <input type="text" className='form-control py-2 bg-light mt-1' value={batch_id} disabled='true' />
        </div>
        <div className='mt-3'>
          <label htmlFor="">Trainer Name</label>
          <input type="text" className='form-control py-2 bg-light mt-1' value={user.first_name}/>
        </div>
        <div className='mt-3'>
          <label htmlFor="">Group Name</label>
          <input type="text" className='shadow-none border-secondary-subtle form-control py-2 bg-light mt-1' value={group_name} onChange={(e)=>{
            setgroup_name(e.target.value)
          }}/>
        </div>

        <div className='mt-4 text-end'>
          <button className='btn btn-sm btn-primary px-3' data-bs-dismiss="modal" onClick={create_group}>Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Assign_marks