import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Chart as ChartJS } from 'chart.js/auto'
import { Line, Pie } from 'react-chartjs-2'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'

const Admin_dashboard = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // initialSlide: 0,
    arrows:false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const user=JSON.parse(sessionStorage.getItem('user'))

  const[user_details,setuser_details]=useState({})

  useEffect(()=>{
    axios.get(`${ipaddress2}/get-user-details/${user.email}/`)
    .then((r)=>{
      console.log("User details",r.data)
      setuser_details(r.data)
    })
    
  },[])

  const[titles,settitles]=useState([])
  const[total_marks,settotal_marks]=useState([])
  const[scored_marks,setscored_marks]=useState([])

  const employeecomparision = {
    labels: titles,
    datasets: [
      {
        label: 'Billing Amount',
        data: total_marks,
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Collected Amount',
        data: scored_marks,
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  
  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20, 
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };

  const[value_state,setvalue_state]=useState('all')

  const date=new Date()

  const[today_billing,settoday_billing]=useState({})
  const[monthwise_billing,setmonthwise_billing]=useState({})
  const[monthwise_prediction,setmonthwise_prediction]=useState({})
  const[collectables,setcollectables]=useState({})
  const[refunds,setrefunds]=useState({})
  const[monthly_collectables,setmonthly_collectables]=useState({})

  useEffect(()=>{
    axios.get(`${ipaddress2}/TodaysBilling/`)
    .then((r)=>{
      console.log("Todays billing",r.data)
      settoday_billing(r.data)
    })

    axios.get(`${ipaddress2}/RefundedAmount/`)
    .then((r)=>{
      console.log("Refunded Amount",r.data)
      setrefunds(r.data)
    })

    axios.get(`${ipaddress2}/MonthCollection/`)
    .then((r)=>{
      console.log("Monthy collectables",r.data)
      setmonthly_collectables(r.data)
    })

    axios.get(`${ipaddress2}/MonthWiseCollection/${date.getFullYear()}/${date.getMonth()+1}`)
    .then((r)=>{
      console.log("Month wise collection",r.data)
      setmonthwise_billing(r.data)
    })

    axios.get(`${ipaddress2}/MonthWisePrediction/${date.getFullYear()}/${date.getMonth()+1}`)
    .then((r)=>{
      console.log("Month wise prediction",r.data)
      setmonthwise_prediction(r.data)
    })

    axios.get(`${ipaddress2}/Collectables/${user.email}/`)
    .then((r)=>{
      console.log("Collectables",r.data)
      setcollectables(r.data)
    })

    fetch_revenue(year2)

    fetch_statistics(year3,month2)

  },[])

  const[statistics,setstatistics]=useState()
  const[statistics2,setstatistics2]=useState()

  const fetch_statistics=(year,month)=>{
    axios.get(`${ipaddress2}/month-wise-collection?month=${year}-${month}`)
    .then((r)=>{
      console.log("Statistics graph",r.data)
      setstatistics(r.data.billing_amount)
      setstatistics2(r.data.amount_achieved)
    })
    .catch((err)=>{
      console.log("Statistics error",err)
    })
  }

    // Pie Chart
    const chart2 = {
      labels: ['Projected Amount','Collected Amount'],
      datasets: [
        {
          label: 'Collection',
          data: [statistics,statistics2],
          fill: false,
          backgroundColor: ['#8D78C1','#282263'],
          tension: 0.1,
        },
      ],
    };


  const fetch_revenue=(year)=>{
    axios.get(`${ipaddress2}/month-wise-collection/${year}/`)
    .then((r)=>{
      console.log("Collection graph",r.data.datasets)
      const data=r.data.datasets
  
      const titles = data.map(item => item.month);
      const totalMarks = data.map(item => item.billing_amount);
      const marksAchieved = data.map(item => item.amount_achieved);
      settitles(titles)
      settotal_marks(totalMarks)
      setscored_marks(marksAchieved)
    })
  }

  // To get years
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1900; year--) {
      years.push(year);
    }
    return years;
  };

  const current_date=new Date()

  const [year, setyear] = useState(() => new Date().getFullYear());
  const [year3, setyear3] = useState(() => new Date().getFullYear());
  const [year2, setyear2] = useState(() => new Date().getFullYear());
  const [month, setmonth] = useState(() => String(new Date().getMonth() + 1).padStart(2, '0'));
  const [month2, setmonth2] = useState(() => String(new Date().getMonth() + 1).padStart(2, '0'));

  useEffect(() => {
    const currentDate = new Date();
    setyear(currentDate.getFullYear());
    setyear2(currentDate.getFullYear());
    setyear3(currentDate.getFullYear());
    setmonth(String(currentDate.getMonth() + 1).padStart(2, '0'));
    setmonth2(String(currentDate.getMonth() + 1).padStart(2, '0'));
  }, []);

  const[months,setmonths]=useState([])

  useEffect(()=>{
    const monthArray = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
    setmonths(monthArray);

    filter_employees(month,year)
    fetch_month_prediction(month,year)

    fetch_employees()
  },[])

  const[month_wise_prediction,setmonth_wise_prediction]=useState({})
  const fetch_month_prediction=(month,year)=>{
    axios.get(`${ipaddress}/MonthWisePrediction/${year}/${month}/`)
    .then((r)=>{

      console.log("Month Wise Prediction",r.data)
      setmonth_wise_prediction(r.data)
    })
    .catch((err)=>{
      console.log(`${ipaddress}/top-employees/?month=${year}-${month}`)
    })
  }

  const[employees,setemployees]=useState([])

  const filter_employees=(month,year)=>{
    console.log(year,month)
    axios.get(`${ipaddress}/top-employees/?month=${year}-${month}`)
    .then((r)=>{
      console.log(`${ipaddress}/top-employees/?month=${year}-${month}`)

      console.log("Employees filtered successfully",r.data)
      setemployees(r.data)
    })
    .catch((err)=>{
      console.log(`${ipaddress}/top-employees/?month=${year}-${month}`)
    })
  }

  // Fetching employee targets and achieved counts
  const[target_state,settarget_state]=useState(true)
  const[emp_targets,setemp_targets]=useState([])

  const fetch_employees=()=>{
    axios.get(`${ipaddress}/daily-employee-activity-billing-summary/`)
    .then((r)=>{
      console.log("Employee and targets",r.data)
      setemp_targets(r.data)
    })
  }

  const[state,setstate]=useState(true)
  const[state2,setstate2]=useState(true)

return (
    <div className='d-flex'>
        <Sidebar activevalue={"dashboard"}/>
        <div className='w-100'>
            <Navbar/>
            <div className='main-container pt-4 px-2 px-lg-5 pb-4' style={{minHeight:'100vh',backgroundColor:'#F0F0F0'}}>
            <div className="container">
              <div className="row m-0">
              <div className='col-md-8 ps-0'>
                <div className="row m-0">
                  <div className="col-md-4 mb-4 ps-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/admin_icon.png')} width={50} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Today's Billing</p>
                  <p className='m-0 dash_data'>${today_billing.billing_amount && today_billing.billing_amount}</p>
                </div>
               </div>
                  </div>
                  <div className="col-md-4 mb-4">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/Icon.png')} width={44} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Today's Collection</p>
                  <p className='m-0 dash_data'>${today_billing.amount_acheived && today_billing.amount_acheived}</p>
                </div>
               </div>
                  </div>
                  <div className="col-md-4 mb-4 mb-md-0 pe-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/Group 41.png')} width={44} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Monthly Billing</p>
                  <p className='m-0 dash_data'>${monthwise_billing.billing_amount && monthwise_billing.billing_amount}</p>
                </div>
               </div>
                  </div>

                 

                  <div className="col-md-4 mb-4 mb-md-0 ps-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3 justify-content-between'>
                <div className='d-flex align-items-center'>
                <img src={require('../images/admin_icon.png')} width={50} alt=""/>
                <div className={`ms-2 ${state2 ? '':'d-none'}`}>
                  <p className='m-0 dash_data1'>Monthly Collection</p>
                  <p className={`m-0 dash_data`}>${monthwise_billing.amount_acheived && monthwise_billing.amount_acheived}</p>
                  </div>

                  {/* Monthly refund */}
                  <div className={`ms-2 ${state2 ? 'd-none':''}`}>
                  <p className='m-0 dash_data1' >New Collectables</p>
                  <p className={`m-0 dash_data fw-medium`} style={{fontSize:'15px'}}>${monthly_collectables.new_collectables_total_collected && monthly_collectables.new_collectables_total_collected}</p>
                  <p className='m-0 dash_data1 mt-2' >Old Collectables</p>
                  <p className={`m-0 dash_data fw-medium`} style={{fontSize:'15px'}}>${monthly_collectables.old_collectables_total_collected && monthly_collectables.old_collectables_total_collected}</p>
                  </div>
                </div>
                <div>
               <svg onClick={()=>{
                setstate2(true)
               }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="d-block bi bi-chevron-bar-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708M2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4"/>
</svg>
<svg onClick={()=>{
                setstate2(false)
               }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mt-2 bi bi-chevron-bar-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708M1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"/>
</svg>
               </div>
               </div>
              
                  </div>

                  <div className="col-md-4 mb-4 mb-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/Icon.png')} width={44} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Droppedout Amount</p>
                  <p className='m-0 dash_data'>${refunds.droppedout_amount && refunds.droppedout_amount}</p>
                </div>
               </div>
                  </div>

                  <div className="col-md-4 mb-4 mb-md-0 pe-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3 justify-content-between'>
                <div className='d-flex align-items-center'>
                <img src={require('../images/Group 41.png')} width={44} alt=""/>
                <div className={`ms-2 ${state ? '':'d-none'}`}>
                  <p className='m-0 dash_data1'>Overall Refunds</p>
                  <p className={`m-0 dash_data`}>${refunds.over_all_refunded && refunds.over_all_refunded}</p>
                  </div>

                  {/* Monthly refund */}
                  <div className={`ms-2 ${state ? 'd-none':''}`}>
                  <p className='m-0 dash_data1'>Monthly Refunds</p>
                  <p className={`m-0 dash_data`}>${refunds.refunded_amount_this_month && refunds.refunded_amount_this_month}</p>
                  </div>
                </div>
                <div>
               <svg onClick={()=>{
                setstate(true)
               }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="d-block bi bi-chevron-bar-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708M2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4"/>
</svg>
<svg onClick={()=>{
                setstate(false)
               }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mt-2 bi bi-chevron-bar-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 4.146a.5.5 0 0 1 .708 0L8 7.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708M1 11.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"/>
</svg>
               </div>
               </div>
              
                  </div>
                </div>
                </div>


                <div className="col-md-4 pe-0">
                  <div className='bg-white p-3' style={{borderRadius:'15px',height:'240px'}}>
                    <div className='sidebar-valid2 p-3 d-flex align-items-center px-4 justify-content-between' style={{borderRadius:'15px'}}>
                      <div className='w-75'>
                      <p className='m-0' style={{fontSize:'14px'}}>Projection Total</p>
                      <p className='m-0 fw-medium' style={{fontSize:'25px'}}>{collectables.projection_total && collectables.projection_total}</p>
                      </div>
                      <img className='' src={require('../images/Graph.png')} alt="" />
                      <p></p>
                    </div>

                    <div>
                      <p className='m-0 mt-2 text-secondary' style={{fontSize:'12px'}}>Prediction</p>
                      <div className='' style={{height:'100px',overflowY:'scroll'}}>
                      <div className='d-flex justify-content-between mt-3'>
                        <div className='d-flex align-items-center'>
                          <img src={require('../images/ico2.png')} alt="" />
                          <div className='ms-3'>
                            <p className='m-0 fw-medium'>Old Collectables</p>
                            <p className='m-0 text-secondary' style={{fontSize:'11px'}}>05 JUL</p>
                          </div>
                        </div>
                        <p>{collectables.old_collectables && collectables.old_collectables}</p>
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div className='d-flex align-items-center'>
                          <img src={require('../images/ico3.png')} alt="" />
                          <div className='ms-3'>
                            <p className='m-0 fw-medium'>New Collectables</p>
                            <p className='m-0 text-secondary' style={{fontSize:'11px'}}>05 JUL</p>
                          </div>
                        </div>
                        <p>{collectables.new_collectables && collectables.new_collectables}</p>
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div className='d-flex align-items-center'>
                          <img src={require('../images/ico4.png')} alt="" />
                          <div className='ms-3'>
                            <p className='m-0 fw-medium'>Monthly Predicted Billing</p>
                            <p className='m-0 text-secondary' style={{fontSize:'11px'}}>05 JUL</p>
                          </div>
                        </div>
                        <p>{monthwise_prediction.projected_amount && monthwise_prediction.projected_amount}</p>
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div className='d-flex align-items-center'>
                          <img src={require('../images/ico2.png')} alt="" />
                          <div className='ms-3'>
                            <p className='m-0 fw-medium'>Monthly Predicted Collection</p>
                            <p className='m-0 text-secondary' style={{fontSize:'11px'}}>05 JUL</p>
                          </div>
                        </div>
                        <p>{monthwise_prediction.acheived_amount && monthwise_prediction.acheived_amount}</p>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


{/* Month wise prediction */}
<div className="row m-0 mt-4">
  <h5 className='mb-3'>Month Wise Predictions</h5>
                  <div className="col-md-6 col-lg-3 mb-4 ps-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/admin_icon.png')} width={50} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Projected Collection</p>
                  <p className='m-0 dash_data' style={{fontSize:'18px'}}>${month_wise_prediction.projected_amount && month_wise_prediction.projected_amount}</p>
                </div>
               </div>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-4">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/Icon.png')} width={44} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Projected Collection without GST</p>
                  <p className='m-0 dash_data' style={{fontSize:'18px'}}>${month_wise_prediction.projected_amount_without_gst && month_wise_prediction.projected_amount_without_gst}</p>
                </div>
               </div>
                  </div>
                  <div className="col-md-6 col-lg-3 mb-4 mb-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/Group 41.png')} width={44} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Achieved Amount</p>
                  <p className='m-0 dash_data' style={{fontSize:'18px'}}>${month_wise_prediction.acheived_amount && month_wise_prediction.acheived_amount}</p>
                </div>
               </div>
                  </div>

                 

                  <div className="col-md-6 col-lg-3 mb-4 mb-md-0 pe-md-0">
                  <div className='d-flex align-items-center bg-white p-2 dash py-3'>
                <img src={require('../images/admin_icon.png')} width={50} alt=""/>
                <div className='ms-2'>
                  <p className='m-0 dash_data1'>Pending Collection</p>
                  <p className='m-0 dash_data' style={{fontSize:'18px'}}>${month_wise_prediction.pending_collection && month_wise_prediction.pending_collection}</p>
                </div>
               </div>
                  </div>
                </div>

  
{/* Charts and Tables */}

<div className="row m-0">
  <div className="col-md-8 ps-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`} style={{borderRadius:'10px',height:'440px'}}>
                 <div className='d-flex justify-content-between align-items-center mb-3'>
                 <h5 className='m-0' style={{color:'#1B2559'}}>Yearly Revenue</h5>
                 <select value={year2} className='rounded-pill px-2 ms-2' onChange={(e)=>{
                    setyear2(e.target.value)
                    fetch_revenue(e.target.value)
                  }} name="" id="">
                    {/* <option value="">Select Year</option> */}
                    {getYears().map((x)=>{
                      return(
                        <>
                        <option value={x}>{x}</option>
                        </>
                      )
                    })}
                  </select>
                 </div>
                  <Line data={employeecomparision} options={options}/>
                  </div>
  </div>

  <div className="col-md-4 px-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`} style={{borderRadius:'10px',height:'440px'}}>
<div className='d-flex align-items-center justify-content-between pb-3'>
<h6 className='m-0' style={{color:'#1B2559'}}>Target Given</h6>
<div className='d-flex'>
  <p onClick={()=>{
    settarget_state(true)
  }} className={`m-0 px-2 ${target_state ? 'para1':'para2'}`} style={{borderRadius:'5px 0px 0px 5px',cursor:'pointer'}}>Activity</p>
  <p onClick={()=>{
    settarget_state(false)
  }} className={`m-0 px-2 ${target_state ? 'para2':'para1'}`} style={{borderRadius:'0px 5px 5px 0px',cursor:'pointer'}}>Billing</p>
</div>
</div>

<div className="table-responsive">
<table class="table">
  <thead className='border-bottom'>
    <tr>
      <th scope="col" style={{color:'#6B678C'}} className='fw-medium'>Employee Name</th>
      <th scope="col" style={{color:'#6B678C'}} className='fw-medium'>Target</th>
      <th scope="col" style={{color:'#6B678C'}} className='fw-medium'>Achieved</th>
    </tr>
  </thead>
  <tbody>
    {emp_targets.map((x)=>{
      return(
        <tr className='border-transparent'>
        <td className='py-3'>
          <p className='m-0 fw-medium' style={{fontSize:'14px',color:'#A3AED0'}}>{x.employee_name}</p></td>
        <td className='py-3'>
          <p className='fw-medium m-0 text-center' style={{color:'#A3AED0',fontSize:'14px'}}>{x.total_target}</p></td>
          <td className='py-3'>
          <p className='fw-medium m-0 text-center' style={{color:'#A3AED0',fontSize:'14px'}}>{x.total_achieved}</p></td>
      </tr>
      )
    })}
  </tbody>
</table>
</div>
                  </div>
  </div>
</div>


<div className="row m-0 mb-4">
  <div className="col-md-8 ps-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white px-2 px-md-4 py-4 shadow-sm mt-5`} style={{borderRadius:'15px',height:'366px'}}>
                 <div className='d-flex justify-content-between align-items-center mb-3'>
                 <h5 className='m-0' style={{color:'#1B2559'}}>Top Counsellor</h5>
                 {/* <img src={require('../images/new/Button.png')} alt="" /> */}
                 <div>
                 <select value={month} className='rounded-pill px-2' onChange={(e)=>{
                    setmonth(e.target.value)
                    filter_employees(e.target.value,year)
                  }} name="" id="">
                    {/* <option value="">Select Month</option> */}
                    {months && months.map((x)=>{
                      return(
                        <>
                        <option value={x}>{x}</option>
                        </>
                      )
                    })}
                  </select>
                  <select value={year} className='rounded-pill px-2 ms-2' onChange={(e)=>{
                    setyear(e.target.value)
                    filter_employees(month,e.target.value)
                  }} name="" id="">
                    {/* <option value="">Select Year</option> */}
                    {getYears().map((x)=>{
                      return(
                        <>
                        <option value={x}>{x}</option>
                        </>
                      )
                    })}
                  </select>
                 </div>
                 </div>
               
               {employees && employees.slice(0,5).map((x)=>{
                return(
                  <div className='d-flex justify-content-between mt-3 border-bottom pb-3 align-items-center'>
                  <div className='d-flex align-items-center'>
                    {/* <img src={require('../images/new/Avatar.png')} alt="" /> */}
                    <div className='ms-3'>
                      <p className='m-0 fw-medium' style={{color:'#1B2559'}}>{x.name}</p>
                      <p className='m-0 text-secondary' style={{fontSize:'11px'}}>counsellor3@gmail.com</p>
                    </div>
                  </div>
                  <p className='rounded px-3 m-0 d-flex align-items-center py-1' style={{color:'#05CD99',backgroundColor:'#E6FAF5'}}>{x.achieved}</p>
                </div>
                )
               })}
                      
                  </div>
  </div>

  <div className="col-md-4 px-0">
  <div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`} style={{borderRadius:'10px',height:'366px'}}>
                 <div className='d-flex justify-content-between align-items-center mb-3'>
                 <h5 className='m-0' style={{color:'#1B2559'}}>Statistics</h5>
                 <div>
                 <select value={month2} className='rounded-pill px-2' onChange={(e)=>{
                    setmonth2(e.target.value)
                    fetch_statistics(year3,e.target.value)
                  }} name="" id="">
                    {/* <option value="">Select Month</option> */}
                    {months && months.map((x)=>{
                      return(
                        <>
                        <option value={x}>{x}</option>
                        </>
                      )
                    })}
                  </select>
                  <select value={year3} className='rounded-pill px-2 ms-2' onChange={(e)=>{
                    setyear3(e.target.value)
                    fetch_statistics(e.target.value,month2)
                  }} name="" id="">
                    {/* <option value="">Select Year</option> */}
                    {getYears().map((x)=>{
                      return(
                        <>
                        <option value={x}>{x}</option>
                        </>
                      )
                    })}
                  </select>
                 </div>
                 </div>
                  <Pie data={chart2} className='mx-auto' style={{height:'84%',width:'84%'}} options={options}/>
                  </div>
  </div>
</div>

              </div>
            </div>
        </div>
    </div>
  )
}

export default Admin_dashboard