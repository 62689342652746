import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Preloader from '../Components/Preloader'
import Upcoming_events from './Upcoming_events'
import { toast } from 'react-toastify'
import Terms_and_conditions from './Terms_and_conditions'
import { Modal } from 'react-bootstrap'

const View_events = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View your Queries
    </Tooltip>
  );

    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[events,setevents]=useState([])
    
    useEffect(()=>{
      setloading(true)
        axios.get(`${ipaddress2}/upcomming-events/${user.id}/`)
        .then((r)=>{
            console.log("Events",r.data)
            setevents(r.data)
            setloading(false)
        })
    },[])

    // To get the time in hours and minutes format
    const formatTime = (timeString) => {
      const [hours, minutes] = timeString.split('T')[1].split(':');
      return `${hours}:${minutes}`;
    };

const[loading,setloading]=useState()
const[load,setload]=useState()


const register_events=(event_id)=>{
 setload(true)
 setTimeout(() => {
  complete_register(event_id)
 }, 1000);
}

const complete_register=(event_id)=>{
  axios.post(`${ipaddress2}/register-upcoming-events/`,{
    events:event_id,
    students:[user.id]
  })
  .then((r)=>{
    setload(false)
    setindex1(-1)
    toast.success('Successfully Registered',{
      autoClose:1000,
      theme:'colored',
      position:'top-center'
    })
  })
}

const[index1,setindex1]=useState(-1)
const[type,settype]=useState('All')

const[registered,setregistered]=useState([])

const fetch_registered=()=>{
  setloading(true)
  axios.get(`${ipaddress2}/registered-events/${user.id}/`)
  .then((r)=>{
    console.log("Registered events",r.data)
    setloading(false)
    setregistered(r.data)
  })
}

const[completed,setcompleted]=useState([])

const fetch_completed=()=>{
  setloading(true)
  axios.get(`${ipaddress2}/completed-events/`)
  .then((r)=>{
    console.log("Completed events",r.data)
    setloading(false)
    setcompleted(r.data)
  })
}

const[event_id,setevent_id]=useState()
const[amount,setamount]=useState()
const[gst_amount,setgst_amount]=useState()
const[btn_state,setbtn_state]=useState(false)
const[btn_state2,setbtn_state2]=useState(false)
const[load2,setload2]=useState(false)
const[url,seturl]=useState()
const[terms_status,setterms_status]=useState(false)
const[show,setshow]=useState(false)
const[show2,setshow2]=useState(false)


// Register for paid events

const generate_performa_invoice=()=>{
setload2(true)
setTimeout(() => {
  send()
}, 1000);
}

const send=()=>{
  const total=Number(amount)+Number(gst_amount)
  axios.get(`${ipaddress2}/EventsPayment/${total}/?customer_id=${user.id}&event_id=${event_id}&grand_total=${amount}&grand_gst_total=${gst_amount}&acceptance_status=${terms_status}`)
  .then((r)=>{
    console.log("Completed",r.data);
    setload2(false)
    setbtn_state(true)
    seturl(r.data.redirect_url)
  })
  .catch((err)=>{
    setload2(false)
    console.log("Performa generation error");
    
  })
}

return (
    <div>
      {loading ? (<Preloader/>):(
        <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"batches"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
     <div className="row m-0">
      <div className="col-12">
        <div className='d-flex justify-content-between align-items-center'>
        <h6 className='fs-5'>{type} Events</h6>
        <div>
        <div className='d-flex pb-3 gap-3'>
                    <p onClick={()=>{
                        settype('All')
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='All' ? '#57309C':'',cursor:'pointer',borderBottom:type==='All' ? '2px solid #57309C':'none'}}>All Events</p>
                    <p onClick={()=>{
                        settype('Registered')
                        fetch_registered()
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='Registered' ? '#57309C':'',cursor:'pointer',borderBottom:type==='Registered' ? '2px solid #57309C':'none'}}>Registered</p>
                    <p onClick={()=>{
                        settype('Completed')
                        fetch_completed()
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='Completed' ? '#57309C':'',cursor:'pointer',borderBottom:type==='Completed' ? '2px solid #57309C':'none'}}>Completed</p>
                   
                </div>
        </div>
        </div>

{/* All events */}
<div className={`${type==='All' ? '':'d-none'}`}>
<h6 className={`text-center text-secondary py-2 ${events.length>0 ? 'd-none':''}`}>No Events available...🧐</h6>
          <div className={`row m-0 ${events.length>0 ? '':'d-none'}`}>

          {events.map((x,index)=>{
            return(
              <div className="col-md-6 col-lg-4 mb-4 p-4">
                <div class="card p-3 pb-2 border-0" style={{height:'350px'}}>
      <img src={x.image} class="card-img-top" style={{height:'170px'}} alt="..."/>
      <div class="card-body d-flex flex-column align-items-center">
      <div className='d-flex justify-content-between w-100'>
        <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{formatTime(x.date_time)}</span></p>
          <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{x.date_time && x.date_time.slice(0,10)}</span></p>
        </div>
        <h5 class="card-title word-break fw-medium m-0" style={{fontSize:'17px',cursor:'pointer',height:'50px'}}>{x.description}</h5>
        {/* <p class="card-text py-1 px-4 rounded" style={{backgroundColor:'#FFDDDD',color:'#F12222'}}>status : Ongoing</p> */}


        <div className='d-flex w-100 align-items-center pt-3 border-top mt-1'>

        <a class={`btn w-100 fw-medium rounded-pill ${x.free_status==='free' ? '':'d-none'}`} style={{border:'1px solid #57309C',color:'#57309C'}} onClick={()=>{
      setindex1(index)
      register_events(x.id)
    }}>
       <span class={`spinner-border spinner-border-sm ${load && index===index1 ? '':'d-none'}`} aria-hidden="true"></span>
       <span className={`${load && index===index1 ? 'ms-2':'d-none'}`} role="status">Registering...</span>
       <span className={`${load && index===index1 ? 'd-none':''}`}>Register</span>
    </a>

    <a class={`btn w-100 fw-medium rounded-pill ${x.free_status==='cost' ? '':'d-none'}`} style={{border:'1px solid #57309C',color:'#57309C'}} onClick={()=>{
     setamount(x.fees)
     setgst_amount(x.gst_total)
      setevent_id(x.id)
      setshow(true)
    }}>
       <span className={`${load && index===index1 ? 'd-none':''}`}>Register</span>
    </a>
        </div>
      </div>
    </div>
                </div>
            )
          })}

                
          </div>
</div>

{/* Registered events */}
<div className={`${type==='Registered' ? '':'d-none'}`}>
<h6 className={`text-center text-secondary py-2 ${events.length>0 ? 'd-none':''}`}>No Events available...🧐</h6>
          <div className={`row m-0 ${events.length>0 ? '':'d-none'}`}>

          {registered.map((x,index)=>{
            return(
              <div className="col-md-6 col-lg-4 mb-4 p-4">
                <div class="card p-3 pb-2 border-0" style={{height:'350px'}}>
      <img src={x.events.image} class="card-img-top" style={{height:'170px'}} alt="..."/>
      <div class="card-body d-flex flex-column align-items-center">
      <div className='d-flex justify-content-between w-100'>
        <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{formatTime(x.events.date_time)}</span></p>
          <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{x.events.date_time && x.events.date_time.slice(0,10)}</span></p>
        </div>
        <h5 class="card-title word-break fw-medium m-0" style={{fontSize:'17px',cursor:'pointer',height:'50px'}}>{x.events.description}</h5>
        {/* <p class="card-text py-1 px-4 rounded" style={{backgroundColor:'#FFDDDD',color:'#F12222'}}>status : Ongoing</p> */}


        <p className='text-success pt-3 text-center fw-medium m-0 fs-5 border-top w-100 mt-3'>Registered</p>
      </div>
    </div>
                </div>
            )
          })}

                
          </div>
</div>

{/* Completed events */}
<div className={`${type==='Completed' ? '':'d-none'}`}>
<h6 className={`text-center text-secondary py-2 ${events.length>0 ? 'd-none':''}`}>No Events available...🧐</h6>
          <div className={`row m-0 ${events.length>0 ? '':'d-none'}`}>

          {completed.map((x,index)=>{
            return(
              <div className="col-md-6 col-lg-4 mb-4 p-4">
                <div class="card p-3 pb-2 border-0" style={{height:'350px'}}>
      <img src={x.image} class="card-img-top" style={{height:'170px'}} alt="..."/>
      <div class="card-body d-flex flex-column align-items-center">
      <div className='d-flex justify-content-between w-100'>
        <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{formatTime(x.date_time)}</span></p>
          <p className='d-flex align-items-center fw-medium' style={{color:'#57309C'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{x.date_time && x.date_time.slice(0,10)}</span></p>
        </div>
        <h5 class="card-title word-break fw-medium m-0" style={{fontSize:'17px',cursor:'pointer',height:'50px'}}>{x.description}</h5>

        <p className='text-secondary pt-3 text-center fw-medium m-0 fs-5 border-top w-100 mt-3'>Completed</p>
      </div>
    </div>
                </div>
            )
          })}

                
          </div>
</div>

      </div>
     </div>
        </div>
        </div>
    </div>
      )}

      {/* Paid events registration modal */}
<Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Register for Event
        </Modal.Title>
        <svg style={{cursor:'pointer'}} onClick={()=>{
          setshow(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='row m-0'>
        <div className="col-12">
          <label htmlFor="" className='fw-medium'>Amount need to pay</label>
          <input type="number" value={amount} disabled='true' className='form-control py-2 mt-2 bg-light' />
        </div>
        <div className="col-12 mt-4">
          <label htmlFor="" className='fw-medium'>Gst Total</label>
          <input type="number" value={gst_amount} disabled='true' className='form-control py-2 mt-2 bg-light' />
        </div>
        <p className='mt-3'><svg style={{cursor:'pointer'}} onClick={()=>{
          setterms_status(true)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class={`bi bi-check-square ${terms_status ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg style={{cursor:'pointer'}} onClick={()=>{
          setterms_status(false)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#0B5ED7" class={`bi bi-check-square-fill ${terms_status ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
 <span className='ms-2' style={{fontSize:'13px'}}>Agree to our <span className='text-primary text-decoration-underline' style={{fontSize:'13px',cursor:'pointer'}} onClick={()=>{
  setshow2(true)
  setshow(false)
}}>Terms & Conditions</span></span></p>
        <div className='d-flex justify-content-end mt-4'>
          <button onClick={generate_performa_invoice} disabled={terms_status ? false:true} className={`btn btn-primary px-4 ${btn_state ? 'd-none':''}`}>
          <span class={`spinner-border spinner-border-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
          <span className={`${load2 ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
          <span className={`${load2 ? 'd-none':''}`}><span className='ms-2'>Proceed</span></span>
          </button>

           <a href={url && url} target="_self" onClick={generate_performa_invoice} disabled={terms_status ? false:true} className={`btn btn-primary px-4 ${btn_state ? '':'d-none'}`}>
          <span class={`spinner-border spinner-border-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
          <span className={`${load2 ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
          <span className={`${load2 ? 'd-none':''}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paypal" viewBox="0 0 16 16">
          <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z"/>
        </svg><span className='ms-2'>Pay Now</span></span>
          </a>
        </div>
       </div>
      </Modal.Body>
      
    </Modal>

    <Terms_and_conditions setshow2={setshow2} show2={show2} setshow={setshow}/>
    </div>
  )
}

export default View_events