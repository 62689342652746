import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Trainers = () => {


  const navigate=useNavigate()

  const[trainers,settrainers]=useState([])

  const[count,setcount]=useState(0)

  useEffect(()=>{
    axios.get(`${ipaddress2}/AllTrainersView/`)
    .then((r)=>{
      console.log("All Trainers",r.data)
      settrainers(r.data)
    })
  },[count])

const[email,setemail]=useState('')
const[state,setstate]=useState(false)

const user=JSON.parse(sessionStorage.getItem('user'))

const block_trainer=(email,status,name)=>{
  axios.post(`${ipaddress2}/BlockTrainer/${user.email}/`,{
    email:email,
    status:status
  })

  .then((r)=>{
    setcount(count+1)
    console.log(r.data)
    if(status==true){
      toast.warn(`You blocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
    else{
      toast.success(`You unblocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  })
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"trainers"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{minHeight:'80vh'}} className='bg-light p-3 main-container'>
                <h5>Trainers Details</h5>
                <div className={`${state ? 'd-none':''}`}>
                <div class="table-responsive mt-4">                    
<table class="table">
  <thead>
    <tr>
      <th scope="col"><p className='m-0 text-center fw-medium'>SI.No</p></th>
      <th scope="col"><p className='m-0 text-center fw-medium'>Trainer Name</p></th>
      <th scope="col"><p className='m-0 text-center fw-medium'>Course Name</p></th>
      <th scope="col"><p className='m-0 text-center fw-medium'>Email</p></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {trainers.map((x,index)=>{
      return(
        <tr>
      <td scope="row"><p className='m-0 text-center'>{index+1}</p></td>
      <td><p className='m-0 text-center text-primary fw-medium' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/trainer_details/${x.trainer_name && x.trainer_name.emp_id}`)
      }}>{x.trainer_name && x.trainer_name.first_name && x.trainer_name.first_name}</p></td>
      <td><p className='m-0 text-center'>{x.specialization}</p></td>
      <td><p className='m-0 text-center'>{x.trainer_name && x.trainer_name.email && x.trainer_name.email}</p></td>
      <td><div className='text-center'>
        <button className={`btn btn-sm btn-danger px-3 ${x.trainer_name.blockstatus ? 'd-none':''}`} onClick={()=>{
          block_trainer(x.trainer_name.email,true,x.trainer_name.first_name)
        }}>Block</button>
        <button className={`btn btn-sm btn-success px-3 ${x.trainer_name.blockstatus ? '':'d-none'}`} onClick={()=>{
          block_trainer(x.trainer_name.email,false,x.trainer_name.first_name)
        }}>Unblock</button>
        </div></td>
    </tr>
      )
    })}
  </tbody>
</table>
</div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default Trainers