import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
// import Upcoming_batches from './Upcoming_batches'

const All_upcoming_batches = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View your Queries
    </Tooltip>
  );

    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[batches,setBatches]=useState([])
    const[count,setcount]=useState(0)
    
    useEffect(()=>{
        axios.get(`${ipaddress2}/upcomingBatchesView/`)
        .then((r)=>{
            console.log("All batches",r.data)
            setBatches(r.data.results)
        })
    },[count])

    const[completed_batches,setcompleted_batches]=useState([])
    const fetch_completed_batches=()=>{
      axios.get(`${ipaddress2}/completed-batches/`)
      .then((r)=>{
        console.log("Completed batches",r.data);
        setcompleted_batches(r.data)
      })
    }

    const formatTime = (timeString) => {
      const [hours, minutes] = timeString.split('T')[1].split(':');
      return `${hours}:${minutes}`;
    };

    const[value,setValue]=useState('running')

    const[show,setshow]=useState(false)
    const[trainer,settrainer]=useState('')
    const[batch_id,setbatch_id]=useState('')
    const[newtrainer,setnewtrainer]=useState('')
    const[trainers,settrainers]=useState([])

    useEffect(()=>{
      axios.get(`${ipaddress2}/Trainers/`)
    .then((r)=>{
        console.log("Trainers",r.data)
        settrainers(r.data)
    })
    },[])

const update_trainer=()=>{
axios.put(`${ipaddress2}/AddBatch/${user.emp_id}/`,{
  empid:newtrainer,
  batch_id:Number(batch_id),
})
.then((r)=>{
  toast.success('Trainer updated successfully',{
    autoClose:1000,
    theme:'colored',
    position:'top-center'
  })
  setcount(count+1)
  setnewtrainer('')
  setshow(false)
})
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"upcoming_batches"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light p-4 main-container' style={{minHeight:'100vh'}}>
<h5 className='mb-4'>Upcomming Batches</h5>

<div className={`table-responsive`}>
<h6 className={`text-center py-3 text-secondary ${batches.length>0 ? 'd-none':''}`}>No Running batches available...🧐</h6>
            <table class={`table shadow-sm ${batches.length>0 ? '':'d-none'}`}>
  <thead className=''>
    <tr>
      <th scope="col" className='border-0'><p className='text-center m-0 fw-bold py-2'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 fw-bold py-2'>Batch Name</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 fw-bold py-2'>Expected Start Date</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 fw-bold py-2'>Expected End Date</p></th>
      <th scope="col" className='border-0'><p className='text-center m-0 fw-bold py-2'>Status</p></th>
    </tr>
  </thead>
  <tbody>
  {batches.map((x,index)=>{
      return(
        <tr className={index%2==0 ? 'table-light':''}>
      <td className='border-0' scope="row"><p className="text-center m-0">{index+1}</p></td>
      <td className='border-0' style={{cursor:'pointer'}} onClick={()=>{
        // navigate(`/students_under_batch/${x.id}`)
      }}><p className="text-center m-0 fw-medium py-2">{x.batch_name}</p></td>
      <td className='border-0'><p className="text-center m-0 py-2">{x.expected_start_date && x.expected_start_date.slice(0,10)}</p></td>
      <td className='border-0'><p className="text-center m-0 py-2">{x.expected_closing_date && x.expected_closing_date.slice(0,10)}</p></td>
      <td className='border-0'><p className={`text-center m-0 py-2 fw-medium ${x.active_status ? 'text-success':'text-danger'}`}>{x.active_status}</p></td>
    </tr>
      )
    })}
  </tbody>
</table>
            </div>

        </div>
        </div>

    </div>
  )
}

export default All_upcoming_batches