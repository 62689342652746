import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import ClientForms from './ClientForms'
import axios from 'axios'
import { ipaddress,ipaddress2, role } from '../App'
import { toast } from 'react-toastify'
import Preloader from './Preloader'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Slider from 'react-slick'
import Slick_button_right from '../Slick_button_right'
import Slick_button_left from '../Slick_button_left'
import Modal from 'react-bootstrap/Modal';
import Generate_quotation from './Generate_quotation'


const Leads = ({designation}) => {
  console.log(designation)
  const[tab,setTab]=useState("all")
  const navigate=useNavigate()

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Upload Excel
    </Tooltip>
  );

  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Download Template
    </Tooltip>
  );

  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Invoice
    </Tooltip>
  );

  const[loading,setLoading]=useState(true)

  const[client_lead_id,setclient_lead_id]=useState("")
  const[lead_id,setlead_id]=useState("")
  const[stage,setstage]=useState("")

  const[leads,setleads]=useState([])
  const[followupleads,setfollowupleads]=useState([])
  const[prospectleads,setprospectleads]=useState([])
  const[registeredleads,setregisteredleads]=useState([])
  const[closedleads,setclosedleads]=useState([])
  const[allleads,setallleads]=useState([])

  const user=JSON.parse(sessionStorage.getItem('user'))
  const[count,setCount]=useState(0)

  const[employees,setemployees]=useState([])

    // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
    const fetchemployees=()=>{
      axios.get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
      .then((r)=>{
        console.log("Employees data",r.data)
        setemployees(r.data)
      })
      .catch((err)=>{
        console.log(err)
      })
    }


  // Fetch All Leads
  const fetchallleads=()=>{
    axios.get(`${ipaddress}/AllClientleads/${user.emp_id}/`)
    .then((r)=>{
      console.log("All client Leads",r.data)
      setallleads(r.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const fetchleads=()=>{
    axios.get(`${ipaddress}/ClientNewleadsView/${user.emp_id}/`)
    .then((r)=>{
      console.log("Client new Leads",r.data)
      setleads(r.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

      // --------------------------------------Fetch Closed Leads----------------------------------------------------
      const[walkinleads,setwalkinleads]=useState([])
  
      const fetchwalkinleads=()=>{
        axios.get(`${ipaddress}/ClientWalkinleadsView/${user.emp_id}/`)
        .then((r)=>{
          console.log("Client walkin Leads",r.data)
          setwalkinleads(r.data)
        })
        .catch((err)=>{
          console.log(err)
        })
      }

// Fetch Followup Leads
    const fetchfollowupleads=()=>{
      axios.get(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`)
      .then((r)=>{
        console.log("Client Followup Leads",r.data)
        setfollowupleads(r.data)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

    // Fetch today followup leads
  const fetch_today_followupleads=()=>{
    axios.post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`,{
      value:'today'
    })
    .then((r)=>{
      console.log("Client today followup Leads",r.data)
      setfollowupleads(r.data.today_followups)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  // Fetch pending followup leads
  const fetch_pending_followupleads=()=>{
    axios.post(`${ipaddress}/ClientFollowupleadsView/${user.emp_id}/`,{
      value:'pending'
    })
    .then((r)=>{
      console.log("Client pending followup Leads",r.data)
      setfollowupleads(r.data.overdue_followups)
    })
    .catch((err)=>{
      console.log(err)
    })
  }


    // Fetch Prospects Leads
    const fetchprospectsleads=()=>{
      axios.get(`${ipaddress}/ClientProspectleadsView/${user.emp_id}/`)
      .then((r)=>{
        console.log("Client Prospect Leads",r.data)
        setprospectleads(r.data)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

      // Fetch pending prospect leads
  const fetch_pending_prospectleads=()=>{
    axios.post(`${ipaddress}/ClientProspectleadsView/${user.emp_id}/`)
    .then((r)=>{
      console.log("Client pending prospect Leads",r.data)
      setprospectleads(r.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }


        // Fetch Registered Leads
        const fetchregisteredleads=()=>{
          axios.get(`${ipaddress}/ClientRegisteredleadsView/${user.emp_id}/`)
          .then((r)=>{
            console.log("Client Regsitered Leads",r.data)
            setregisteredleads(r.data)
          })
          .catch((err)=>{
            console.log(err)
          })
        }

          // Fetch pending registered leads
  const fetch_pending_registeredleads=()=>{
    axios.post(`${ipaddress}/TodayRegistrationCandidates/${user.emp_id}/`)
    .then((r)=>{
      console.log("Client pending registered Leads",r.data)
      setregisteredleads(r.data.client_leads)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

// Fetch Closed Leads
const fetchclosedleads=()=>{
  axios.get(`${ipaddress}/ClientClosedleadsView/${user.emp_id}/`)
  .then((r)=>{
    console.log("Client Closed Leads",r.data)
    setclosedleads(r.data)
  })
  .catch((err)=>{
    console.log(err)
  })
}

  useEffect(()=>{
    fetchfollowupleads()
    fetchprospectsleads()
    fetchregisteredleads()
    fetchclosedleads()
    fetchwalkinleads()
    fetchemployees()

    setLoading(false)
  },[count])

  const[count2,setcount2]=useState(0)
  useEffect(()=>{
    fetchallleads()
    fetchleads()
  },[count2])



  // -------------------------------------Select the Leads----------------------------------------
  const [selectedLeads, setSelectedLeads] = useState([]);
const[selecetdemp,setselectedemp]=useState("")

  const handleCheckboxChange = (e, leadId) => {
    if (e.target.checked) {
      setSelectedLeads([...selectedLeads, leadId]);
    } 
    else {
      setSelectedLeads(selectedLeads.filter(id => id !== leadId));
    }
  };

  const assign_leads=(value)=>{
    const emp_name=employees.find(x=>x.emp_id===value)
    axios.post(`${ipaddress}/AssignClientToView/`,{
      loggedin_emp_id:user.emp_id,
      emp_id:value,
      client_lead_ids:selectedLeads
    })

    .then((r)=>{
      console.log("Successfully client assigned",r.data)
      setselectedemp("")
      setSelectedLeads([])
      toast.success(`Assigned to ${emp_name.firstname}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    })
    .catch((err)=>{
      console.log("Assigning error",err)
      console.log({
        emp_id:value,
        client_lead_ids:selectedLeads
      })
    })
  }



  // -----------------------------------------Search and take all leads-------------------------------------------------------
      // -------------------------------------------------Search leads from all leads table-----------------------------------
  
      const[searchvalue,setsearchvalue]=useState("")
      const[category_name,setcategory_name]=useState("select")
      const[category_name2,setcategory_name2]=useState("select")
      const[category_name3,setcategory_name3]=useState("select")
      const[category_name4,setcategory_name4]=useState("select")
      const[category_name5,setcategory_name5]=useState("select")
      const[category_name6,setcategory_name6]=useState("select")
      const[category_name7,setcategory_name7]=useState("select")
      const[category_name8,setcategory_name8]=useState("select")

      const[category_state,setcategory_state]=useState(false)
  
    // Search leads

  const search_all_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchCategoryClientView/${user.emp_id}/${category_name}/${value}/`)
    .then((r)=>{
      console.log("Client search Leads",r.data)
      setallleads(r.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchallleads()
  }
  }

  const search_new_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchleads()
  }
  }

  const search_walkin_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name3}/${value}/?level=walkin`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setwalkinleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchwalkinleads()
  }
  }

  const search_followup_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name4}/${value}/?level=followup`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setfollowupleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchfollowupleads()
  }
  }

  const search_prospect_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name5}/${value}/?level=prospect`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setprospectleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchprospectsleads()
  }
  }

  const search_registered_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name6}/${value}/?level=registered`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setregisteredleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchregisteredleads()
  }
  }

  const search_closed_leads=(value)=>{
    if(value.length>0){
    axios.get(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name7}/${value}/?level=closed`)
    .then((r)=>{
      console.log("searched new Leads",r.data)
      setclosedleads(r.data)
      console.log(`${ipaddress}/SearchStageWiseClientView/${user.emp_id}/${category_name2}/${value}/?level=newlead`);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  else{
    fetchclosedleads()
  }
  }


// Upload Excel
const[excel,setexcel]=useState(null)

const uploadexcel=(file)=>{
  const formdata=new FormData()
  formdata.append('file',excel)

axios.post(`${ipaddress}/ImportExcelClientView/`,formdata)
.then((r)=>{
  console.log("Uploaded successfully",r.data)
  toast.success('File uploaded successfully',{
    autoClose:2000,
    theme:'colored',
      position:'top-center'
  })
  setexcel(null)
})
.catch((err)=>{
  console.log("Uploading error",err)
})
}

// ---------------------------------------------------Delete New laeds-----------------------------------------------------
const delete_lead=(id)=>{
  axios.put(`${ipaddress}/DeleteView/${id}/`)
  .then((r)=>{
    console.log("Deleted successfully",r.data)
    setCount(count+1)
    toast.success('B2B lead deleted successfully',{
      autoClose:2000,
      theme:'colored',
      position:'top-center'
    })
  })
}

// --------------------------------------------------Download Excel Template----------------------------------------------------
const download_template=()=>{
  axios.get(`${ipaddress}/DownloadClientExcel/`)
  .then((r)=>{
    console.log("Triggered",r.data)
    const pdfUrl = r.data.url;
    const link = document.createElement('a');
    link.setAttribute('target','_blank')
    link.href = pdfUrl;
    link.download = r.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
}

// To view the assigned permissions

const[permissions,setpermissions]=useState({})

useEffect(()=>{
  axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`)
  .then((r)=>{
    console.log("Permissions",r.data)
    setpermissions(r.data)
  })
},[])

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <Slick_button_right />,
prevArrow: <Slick_button_left />,
  // initialSlide: 0,
  // autoplay: true,
  speed: 500,
  // autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const[course_id,setcourse_id]=useState()
const[course_name,setcourse_name]=useState('')
const[show,setshow]=useState(false)
const[show1,setshow1]=useState(false)
const[show2,setshow2]=useState(false)
const[show3,setshow3]=useState(false)

// Combination of search
const fields=['Name','Email','Contact_Number','Course_Enquired','Source','Date','Lead_Stage']

const [fields_array,setfields_array]=useState({
  Course_Enquired:false,
  Source:false,
  Lead_Stage:false,
  Employee:false,
  Email:false,
  Name:false,
  Date:false,
  Contact_Number:false
})

const handleFieldChange = (field) => {
  setfields_array((prev) => ({
    ...prev,
    [field]: !prev[field] // Toggle the state of the specific field dynamically
  }));
};

const [formdata,setformdata]=useState({
  course_enquired:'',
  source:'',
  level_lead:'',
  email:'',
  lead_name:'',
  Date:'',
  contact_number:''
})

const combine_fields=(e)=>{
    const {name,value}=e.target
    setformdata(prev=>({
      ...prev,
      [name]:value
    }))
}

const[load,setload]=useState(false)

const cancel_combined_search=()=>{
  setshow(false)
  setformdata({
    course_enquired:'',
    source:'',
    level_lead:'',
    emp_id:'',
    email:'',
    lead_name:'',
    Date:'',
    contact_number:''
  })
  setfields_array({
    Course_Enquired:false,
    Source:false,
    Lead_Stage:false,
    Employee:false,
    Email:false,
    Name:false,
    Date:false,
    Contact_Number:false
  })
}
const combined_search=()=>{
  formdata.emp_id=Number(user.id)

axios.post(`${ipaddress}/CombinationSearchClientView/`,formdata)
.then((r)=>{
  console.log("Combined filter data",r.data);
  if(tab==='all'){
    setallleads(r.data)
  }
  if(tab==='newleads'){
    setleads(r.data)
  }
  if(tab==='walkin'){
    setwalkinleads(r.data)
  }
  if(tab==='referred'){
    setallleads(r.data)
  }
  if(tab==='followup'){
    setfollowupleads(r.data)
  }
  if(tab==='prospects'){
    setprospectleads(r.data)
  }
  if(tab==='registered'){
    setregisteredleads(r.data)
  }
  if(tab==='closed'){
    setclosedleads(r.data)
  }
 cancel_combined_search()

})
.catch((err)=>{
  console.log('Combined search error',err);
  
})
}


const[purpose,setpurpose]=useState(false)

const[quotation,setquotation]=useState(false)
const[quotation_id,setquotation_id]=useState()

const[service,setservice]=useState()
const[service_amount,setservice_amount]=useState()


return (
   <div>
    {loading ? (<Preloader/>):(
       <div className='d-flex'>
       <Sidebar designation={designation} activevalue={"leads"}/>
       <div className='w-100 main-div'>
           <Navbar designation={designation} pagename={"B2B Leads"}/>
           
           <div className='bg-light pt-2 h-100 main-container' style={{minHeight:'80vh'}}>
           <div className="container px-1 px-lg-4">
           <div className='d-flex justify-content-between align-items-center py-3'>
             <h5>B2B Leads</h5>
             <div className={`d-flex align-items-center justify-content-end`} style={{width:'40%'}}>
             <button className='btn btn-sm text-white px-4 me-3' style={{backgroundColor:'#467DF7',borderRadius:'15px'}} onClick={()=>{
               navigate('/clientregistrationform')
             }}>Add New</button>

<OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip}
    >
      <span><svg data-bs-toggle="modal"  data-bs-target="#excelModal" style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="text-primary bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
  <path d="M6 12v-2h3v2z"/>
  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3z"/>
</svg></span>
    </OverlayTrigger>

    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip2}
    >
      <span onClick={()=>{
        download_template()
      }} style={{cursor:'pointer'}} className='ms-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="text-primary bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
</svg>
      </span>
    </OverlayTrigger>

              <select value={selecetdemp} disabled={selectedLeads.length>0 ? false : true} name="" id="" onChange={(e)=>{
      assign_leads(e.target.value)
    }} className={`ms-3 form-select shadow-none ${tab==='newleads' && ( user.designation===role || user.designation==="admin")  ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">Assign to</option>
            {employees.map((x)=>{
             return(
               <>
               <option value={x.emp_id}>{x.firstname}</option>
               </>
             )
           })}
          </select>
              </div>
           </div>

           <Slider {...settings} className='px-2 mt-2'>
   <div className='p-2'>
        <p onClick={()=>{
                        setTab('all')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:tab==='all' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='all' ? '2px solid #57309C':'none'}}>All Leads</p>
        </div>
        <div className='p-2 text-center'>
        <p onClick={()=>{
                        setTab('newleads')
                        fetchleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='newleads' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='newleads' ? '2px solid #57309C':'none'}}>New Leads</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        setTab('walkin')
                        fetchwalkinleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='walkin' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='walkin' ? '2px solid #57309C':'none'}}>Walkin Leads</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        setTab('followup')
                        fetchfollowupleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='followup' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='followup' ? '2px solid #57309C':'none'}}>Followup Leads</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        setTab('prospects')
                        fetchprospectsleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='prospects' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='prospects' ? '2px solid #57309C':'none'}}>Prospect Leads</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        setTab('registered')
                        fetchregisteredleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='registered' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='registered' ? '2px solid #57309C':'none'}}>Registered Leads</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        setTab('closed')
                        fetchclosedleads()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:tab==='closed' ? '#57309C':'',cursor:'pointer',borderBottom:tab==='closed' ? '2px solid #57309C':'none'}}>Closed Leads</p>
        </div>
        
      </Slider>
   
   <div id="myTabContent">
   
   {/* ---------------------------------------------------All Client Leads-------------------------------------------------- */}
   <div className={`${tab==='all' ? '':'d-none'}`}>
     
     <div class={`table-responsive mt-4`}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
       <h6 className='text-primary'>All B2B Leads</h6>
       <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name(e.target.value)
          }
          else{
            fetchallleads()
          }
            setcategory_name(e.target.value)
          console.log(e.target.value)
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="level_lead">Lead Stage</option>
            <option value="created_at">Date</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name==="created_at" || category_name==='custom' || category_name==='mobile_number' || category_name==='level_lead' || category_name==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_all_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_all_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name==="created_at" && category_name!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_all_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name==="mobile_number" && category_name!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
       </div>
   
       <h6 className={`text-secondary text-center ${allleads.length>0 ? 'd-none':''}`}>Add the Leads...</h6>
          <div className='table-responsive rounded bg-white border-0 p-2' style={{minHeight:'100vh'}}>
          <table class={`table caption-top ${allleads.length>0 ? '':'d-none'}`}>
           <thead className='table-info'>
       <tr>
       <th scope="col" className='border-0'><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>SI.No</p></th>
         <th scope="col" className='border-0'><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Name</p></th>
         <th scope="col" className='border-0'><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Mobile No</p></th>
         <th scope="col" className='border-0'><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Email</p></th>
         <th scope="col" className={`border-0`}><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Course Enquired</p></th>
         <th scope="col" className={`border-0`}><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Purpose</p></th>
         <th scope="col" className='border-0'><p className='fw-bold text-center m-0 py-2' style={{color:'#5A607F',fontSize:'16px'}}>Lead Stage</p></th>
         {/* <th scope="col" className='pb-3'><span className='fw-normal' style={{color:'#5A607F',fontSize:'16px'}}>Lead Stage</span></th> */}
         <th className='border-0'></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {allleads.map((x,index)=>{
         return(
           <tr>
           <td scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></td>
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null   ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className={(x.othercourseenquired===null || x.othercourseenquired==="") && x.course_enquired==null ? 'text-center m-0 py-2':'d-none'}>{(x.othercourseenquired===null || x.othercourseenquired==="") && x.course_enquired==null ? `${x.purpose}` : '-'}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.level_lead_name}</p></td>
   
           <td className={`border-0 d-flex justify-content-center ${x.level_lead_name==='registered' || (x.course_enquired==null && (x.othercourseenquired===null || x.othercourseenquired==="")) ? 'd-none':''}`}>
            <div class="dropdown py-2 mx-auto">
     <button class="btn btn-sm text-white dropdown-toggle" style={{backgroundColor:'#57309C'}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
       Stages
     </button>
     <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
     <ul className='ps-0 d-flex flex-column align-items-center gap-2' style={{listStyleType:'none'}}>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage(x.level_lead_name)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
           }}>Followup</button></li>
       <li className={`${x.level_lead_name==='registered' || x.level_lead_name==='closed' ? 'd-none':''}`}><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#prospectsform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
           }}>Prospects</button></li>
       <li className={`${x.level_lead_name==='registered' || x.level_lead_name==='closed' ? 'd-none':''}`}><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
           navigate(`/mark_as_register_form/${x.client_lead_id}`)
         }}>Register</button></li>
       <li className={`${x.level_lead_name==='registered' || x.level_lead_name==='closed' ? 'd-none':''}`}><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage(x.level_lead_name)
           }}>Closed</button></li>
     </ul>
     </div>
   </div></td>

   {/* Purpose button */}
   <td className={`border-0 ${x.course_enquired==null && (x.othercourseenquired===null || x.othercourseenquired==="") && x.quotation_id==null ? '':'d-none'}`}>
      <div className='py-2 text-center'>
      <p className={`text-center btn-primary btn btn-sm px-3 ${x.quotation_status ? 'd-none':''}`} onClick={()=>{
        setclient_lead_id(x.client_lead_id)
        setshow2(true)
      }}>Generate Quotation</p>
      
      </div>
            </td>

            <td className={`border-0 ${x.course_enquired==null && (x.othercourseenquired===null || x.othercourseenquired==="") && x.quotation_status && (x.quotation_id && x.quotation_id.accepted_status!=='accepted') ? '':'d-none'}`}>
            <div className={x.quotation_status ? 'd-flex align-items-center justify-content-evenly':'d-none'}>
    <span>

{/* Accept quotation btn */}
    <p className='m-0' style={{cursor:'pointer'}} onClick={()=>{
             navigate(`/client_register_form/${x.client_lead_id}`)
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green" class={`bi bi-check-square-fill`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
    </p>
    </span>
<span className='ms-3'>
<svg style={{cursor:'pointer'}} data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage(x.level_lead_name)
             setquotation(true)
             setquotation_id(Number(x.quotation_id.id))
           }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708"/>
</svg>
</span>
<span className='ms-3'>
<svg style={{cursor:'pointer'}} onClick={()=>{
  setservice(x.quotation_id && x.quotation_id.service)
  setservice_amount(x.quotation_id && x.quotation_id.quote_amount)
  setquotation_id(Number(x.quotation_id.id))
        setclient_lead_id(x.client_lead_id)
        setshow3(true)
      }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
</span>
      </div>
            </td>

   <td className={`border-0 ${!x.invoice_status && x.level_lead_name==='registered' && x.admin_approval==false ? '':'d-none'}`}>
      <div className='py-2'>
      <p className='text-center text-success m-0'>Performa Invoice Generated</p>
      </div>
            </td>
            <td className={`border-0 ${x.admin_approval ? '':'d-none'}`}>
      <div className='py-2'>
      <p className='text-center text-warning m-0'>Admin has to approve</p>
      </div>
            </td>

   <td className={`${x.invoice_status && x.level_lead_name==='registered' && x.admin_approval==false ? 'border-0':'d-none'}`}><div className='d-flex justify-content-center align-items-center py-2'>
            
            <span style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/installment_form/${x.client_lead_id}`)
            }} className={`btn btn-sm text-white btn-success ${x.invoice_status && x.level_lead_name==='registered'  ? '':'d-none'}`}>Invoice Generated</span>
            </div></td>

<td className={`border-0 ${x.invoice_status && x.level_lead_name==='registered' && x.admin_approval==false ? '':'d-none'}`}>
            <div className="text-center py-2">
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip3}
    >
      <div className=''>
      <svg style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/invoice/${x.client_lead_id}`)
      }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
  <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
  <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z"/>
</svg>
      </div>
    </OverlayTrigger>
            </div>
            </td>
   
         </tr>
         )
       })}
     </tbody>
   </table>
          </div>
           
   </div>
     </div>
   
   {/* ---------------------------------------------------New Client Leads-------------------------------------------------- */}
     <div className={`${tab==='newleads' ? '':'d-none'}`}>
     {/* <h6 className={`text-secondary text-center ${leads.length>0 ? 'd-none':''}`}>No Leads available in New leads Category...</h6> */}
     
   <div className='mt-4'>
   <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>New B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name2(e.target.value)
          setcategory_state(!category_state)
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name2(e.target.value)
            fields_array.Lead_Stage=true
            formdata.level_lead='newlead'
          }
          else{
            fetchleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name2==="created_at" || category_name2==='custom' || category_name2==='mobile_number' || category_name2==='level_lead' || category_name2==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_new_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_new_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name2==="created_at" && category_name2!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_new_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name2==="mobile_number" && category_name2!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>

     <h6 className={`text-secondary text-center py-3 ${leads.length>0 ? 'd-none':''}`}>No Leads available in New leads Category...</h6>

   
   <div class={`table-responsive p-2 ${leads.length>0 ? '':'d-none'}`} style={{minHeight:'100vh'}}>
           {/* <h6 className='text-primary pb-2'>New B2B Leads</h6> */}
           <table class="table shadow-sm caption-top">
           <thead className=''>
       <tr>
         <th scope="col" className={`${permissions.assign_leads ? '':'d-none'} border-0`}></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></span></th>
         {/* <th scope="col" className={`${designation===role || designation==='admin' ? 'd-none':'border-0'}`}><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Assigned by</p></span></th> */}
         <th className='border-0'></th>
         <th className='border-0'></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {leads.map((x,index)=>{
         return(
           <tr className={index%2==0 ? 'table-light':'table-white'}>
           <td scope="row" className={`border-0 ${permissions.assign_leads ? '':'d-none'}`}>
            <div className="text-center py-2">
            <input onChange={(e)=>{
             handleCheckboxChange(e,x.client_lead_id)
           }} type="checkbox" />
            </div>
           </td>
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}> {x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
           {/* <td className={`border-0 ${designation===role || designation==='admin' ? 'd-none':''}`}><p className='text-center m-0 py-2'>{x.assigned_byy && x.assigned_byy}</p></td> */}
           <td className='border-0 d-flex justify-content-center'><div class="dropdown py-2">
     <button class="btn btn-sm text-white dropdown-toggle" style={{backgroundColor:'#57309C'}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
       Stages
     </button>
     <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
     <ul className='ps-0 d-flex flex-column align-items-center gap-2' style={{listStyleType:'none'}}>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage('newlead')
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
           }}>Followup</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#prospectsform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
           }}>Prospects</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
           navigate(`/mark_as_register_form/${x.client_lead_id}`)
         }}>Register</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage("newlead")
           }}>Closed</button></li>
     </ul>
     </div>
   </div></td>
   <td className={`border-0`}>
      <div className="text-center py-2">
      <button onClick={()=>{
        navigate(`/edit_client_lead/${x.client_lead_id}`)
      }} className='btn btn-sm'>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#624DE3" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg></button> 
      </div>
    </td>

    <td className={`border-0 ${user.designation==='admin' || permissions.delete_client_lead ? '':'d-none'}`}>
     <div className="text-center py-2">
     <button onClick={()=>{
       delete_lead(x.client_lead_id)
      }} className='btn btn-sm'>
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 20" fill="none">
  <path d="M3.51922 19.9996C2.95993 19.9996 2.48356 19.8029 2.09013 19.4095C1.69671 19.0161 1.5 18.5397 1.5 17.9804V2.49965H0.25V1.24965H5.25V0.288086H12.75V1.24965H17.75V2.49965H16.5V17.9804C16.5 18.5557 16.3073 19.0361 15.9219 19.4215C15.5365 19.8069 15.0561 19.9996 14.4808 19.9996H3.51922ZM15.25 2.49965H2.75V17.9804C2.75 18.2048 2.82211 18.3891 2.96634 18.5333C3.11057 18.6775 3.29486 18.7496 3.51922 18.7496H14.4808C14.6731 18.7496 14.8494 18.6695 15.0096 18.5093C15.1699 18.349 15.25 18.1727 15.25 17.9804V2.49965ZM6.25959 16.2496H7.50963V4.99965H6.25959V16.2496ZM10.4904 16.2496H11.7404V4.99965H10.4904V16.2496Z" fill="#A30D11"/>
</svg></button> 
     </div>
    </td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
   </div>
     </div>
   
   {/* ---------------------------------------------------Walkin Leads-------------------------------------------------- */}
   <div className={`${tab==='walkin' ? '':'d-none'}`}>
      <div class={`table-responsive mt-4 p-2`} style={{minHeight:'100vh'}}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>Walkedin B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name3(e.target.value)
          setcategory_state(!category_state)
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name3(e.target.value)
            formdata.level_lead='walkin'
          }
          else{
            fetchwalkinleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name3==="created_at" || category_name3==='custom' || category_name3==='mobile_number' || category_name3==='level_lead' || category_name3==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_walkin_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_walkin_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name3==="created_at" && category_name3!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_walkin_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name3==="mobile_number" && category_name3!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>
     <h6 className={`text-secondary text-center py-3 ${walkinleads.length>0 ? 'd-none':'py-3'}`}>No Leads available in Walkin leads Category...</h6>

           <table class={`table caption-top shadow-sm ${walkinleads.length>0 ? '':'d-none'}`}>
           <thead className=''>
       <tr>
       <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>SI.No</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></span></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {walkinleads.map((x,index)=>{
         return(
           <tr className={index%2==0 ? 'table-light':'table-white'}>
                  <td scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></td>
   
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
          
           <td className='border-0 d-flex justify-content-center'><div class="dropdown text-center py-2">
     <button class="btn btn-sm text-white dropdown-toggle" style={{backgroundColor:'#57309C'}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
       Stages
     </button>
     <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
     <ul className='ps-0 d-flex flex-column align-items-center gap-2' style={{listStyleType:'none'}}>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage('walkin')
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
           }}>Followup</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#prospectsform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
           }}>Prospects</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
           navigate(`/mark_as_register_form/${x.client_lead_id}`)
         }}>Register</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage("newlead")
           }}>Closed</button></li>
     </ul>
     </div>
   </div></td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
     </div>
   
   {/* ------------------------------------------------Client Followup Leads----------------------------------------------- */}
     <div className={`${tab==='followup' ? '':'d-none'}`}>
     <div class={`table-responsive mt-4 p-2`} style={{minHeight:'100vh'}}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>Followup B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name4(e.target.value)
          setcategory_state(!category_state)
          if(e.target.value==='pending'){
            fetch_pending_followupleads()
          }
          if(e.target.value==='today'){
            fetch_today_followupleads()
          }
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name4(e.target.value)
            fields_array.Lead_Stage=true
            formdata.level_lead='followup'
          }
          else{
            fetchfollowupleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
            <option value="today">Today followups</option>
            <option value="pending">Pending followups</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name4==="created_at" || category_name4==='custom' || category_name4==="today" || category_name4==="pending" || category_name4==='mobile_number' || category_name4==='level_lead' || category_name4==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_followup_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_followup_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name4==="created_at" && category_name4!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_followup_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name4==="mobile_number" && category_name4!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>
     <h6 className={`text-secondary text-center ${followupleads.length>0 ? 'd-none':'py-3'}`}>No Leads available in Followup Category...</h6>

           <table class={`table caption-top shadow-sm ${followupleads.length>0 ? '':'d-none'}`}>
           <thead className=''>
       <tr>
       <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>SI.No</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></span></th>
         <th scope="col" className='border-0'><span className='fw-medium'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></span></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {followupleads.map((x,index)=>{
         return(
           <tr className={index%2==0 ? 'table-light':'table-white'}>
                   <td scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></td>
   
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
          
           <td className='border-0 d-flex justify-content-center'><div class="dropdown py-2 text-center">
     <button class="btn btn-sm text-white dropdown-toggle" style={{backgroundColor:'#57309C'}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
       Stages
     </button>
     <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
     <ul className='ps-0 d-flex flex-column align-items-center gap-2' style={{listStyleType:'none'}}>
     <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage('followup')
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
           }}>Followup</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#prospectsform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
           }}>Prospects</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
           navigate(`/mark_as_register_form/${x.client_lead_id}`)
         }}>Register</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage("followup")
           }}>Closed</button></li>
     </ul>
     </div>
   </div></td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
     </div>
   
   
   {/* ------------------------------------------------Client Prospect Leads----------------------------------------------- */}
     <div className={`${tab==='prospects' ? '':'d-none'}`}>
     <div class={`table-responsive mt-4 p-2`} style={{minHeight:'100vh'}}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>Prospect B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name5(e.target.value)
          setcategory_state(!category_state)
          if(e.target.value==='pending'){
            fetch_pending_prospectleads()
          }
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name5(e.target.value)
            fields_array.Lead_Stage=true
            formdata.level_lead='prospect'
          }
          else{
            fetchprospectsleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
            <option value="pending">Pending prospects</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name5==="created_at" || category_name5==='custom' || category_name5==="pending" || category_name5==='mobile_number' || category_name5==='level_lead' || category_name5==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_prospect_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_prospect_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name5==="created_at" && category_name5!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_prospect_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name5==="mobile_number" && category_name5!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>
     <h6 className={`text-secondary text-center ${prospectleads.length>0 ? 'd-none':'py-3'}`}>No Leads available in Prospects Category...</h6>

           <table class={`table caption-top shadow-sm ${prospectleads.length>0 ? '':'d-none'}`}>
           <thead className=''>
       <tr>
       <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>SI.No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {prospectleads.map((x,index)=>{
         return(
           <tr className={index%2==0 ?'table-light':'table-white'}>
                   <td scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></td>
   
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
           
           <td className='border-0 d-flex justify-content-center'><div class="dropdown py-2 text-center">
     <button class="btn btn-sm text-white dropdown-toggle" style={{backgroundColor:'#57309C'}} type="button" data-bs-toggle="dropdown" aria-expanded="false">
       Stages
     </button>
     <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
     <ul className='ps-0 d-flex flex-column align-items-center gap-2' style={{listStyleType:'none'}}>
     <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage(x.level_lead_name)
             setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
           }}>Followup</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' onClick={()=>{
           navigate(`/mark_as_register_form/${x.client_lead_id}`)
         }}>Register</button></li>
       <li><button style={{width:'100px'}} className='btn btn-sm text-white btn-primary' data-bs-toggle="modal" data-bs-target="#closedform" onClick={()=>{
             setclient_lead_id(x.client_lead_id)
             setstage("prospect")
           }}>Closed</button></li>
     </ul>
     </div>
   </div></td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
     </div>
   
   {/* -------------------------------------------Client Registered Leads-------------------------------------------------- */}
     <div className={`${tab==='registered' ? '':'d-none'}`}>
     <div class={`table-responsive mt-4 p-2`}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>Registered B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name6(e.target.value)
          setcategory_state(!category_state)

          if(e.target.value==='pending'){
          fetch_pending_registeredleads()
          }
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name6(e.target.value)
            fields_array.Lead_Stage=true
            formdata.level_lead='registered'
          }
          else{
          fetchregisteredleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
            <option value="pending">Pending registrations</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name6==="created_at" || category_name6==='custom' || category_name6==='pending' || category_name6==='mobile_number' || category_name6==='level_lead' || category_name6==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_registered_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_registered_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name6==="created_at" && category_name6!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_registered_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name6==="mobile_number" && category_name6!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_registered_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>
     <h6 className={`text-secondary text-center ${registeredleads.length>0 ? 'd-none':'py-3'}`}>No Leads available in Registered Category...</h6>

           <table class={`table caption-top shadow-sm ${registeredleads.length>0 ? '':'d-none'}`}>
           <thead className=''>
       <tr>
       <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>SI.No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {registeredleads.map((x,index)=>{
         return(
           <tr className={index%2==0 ? 'table-light':'table-white'}>
                   <td scope="row" className='border-0'>{index+1}</td>
   
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
           <td className={`border-0`}><div className='d-flex justify-content-center align-items-center py-2'>
      <p className={`text-center text-success m-0 ${x.invoice_status==false && x.admin_approval==false ? '':'d-none'}`}>Performa Invoice Generated</p>
      <p className={`text-center text-warning m-0 ${x.admin_approval ? '':'d-none'}`}>Admin has to approve</p>
            
            <span style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/installment_form/${x.client_lead_id}`)
            }} className={`btn btn-sm text-white btn-success ${x.invoice_status && x.level_lead_name==='registered' && x.admin_approval==false ? '':'d-none'}`}>Invoice Generated</span>
            </div></td>

<td className={`border-0 ${x.invoice_status && x.admin_approval==false ? '':'d-none'}`}>
            <div className="text-center py-2">
            <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip3}
    >
      <div className=''>
      <svg style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/invoice/${x.client_lead_id}`)
      }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
  <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"/>
  <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z"/>
</svg>
      </div>
    </OverlayTrigger>
            </div>
            </td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
     </div>
   
   {/* ---------------------------------------------Client Closed Leads------------------------------------------------------ */}
     <div className={`${tab==='closed' ? '':'d-none'}`}>
     <div class={`table-responsive mt-4 p-2`}>
     <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='text-primary pb-2'>Closed B2B Leads</h6>
        <div className='d-flex align-items-center w-50 justify-content-end'>
        <select onChange={(e)=>{
          setcategory_name7(e.target.value)
          setcategory_state(!category_state)
          if(e.target.value==='custom'){
            setshow(true)
            setcategory_name7(e.target.value)
            fields_array.Lead_Stage=true
            formdata.level_lead='closed'
          }
          else{
            fetchclosedleads()
          }
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">All</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquired">Course</option>
            <option value="source">Source</option>
            <option value="mobile_number">Phone Number</option>
            <option value="created_at">Date</option>
            <option value="custom">Combined search</option>
          </select>
        <div class={`input-group rounded bg-white border ms-3 ${category_name7==="created_at" || category_name7==='custom' || category_name7==='mobile_number' || category_name7==='level_lead' || category_name7==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_closed_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div>

{/* Filter based on Date */}
    <input type="date" onChange={(e)=>{
      search_closed_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name7==="created_at" && category_name7!=='select' ? '':'d-none'}`} />

{/* Filter based on Phone */}
<input type="tel" maxLength={10} onChange={(e)=>{
      search_closed_leads(e.target.value)
    }} className={`form-control w-50 ms-3 ${category_name7==="mobile_number" && category_name7!=='select' ? '':'d-none'}`} />

{/* Filter based on Lead Stage */}
<select onChange={(e)=>{
      search_all_leads(e.target.value)
    }}  name="" id="" className={`ms-3 form-select shadow-none ${category_name==='level_lead' && category_name!=='select' ? '':'d-none'}`} style={{width:'50%',cursor:'pointer'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
    
        </div>
        </div>
        <h6 className={`text-secondary text-center ${closedleads.length>0 ? 'd-none':'py-3'}`}>No Leads available in Closed Category...</h6>
           <table class={`table caption-top shadow-sm ${closedleads.length>0 ? '':'d-none'}`}>
           <thead className=''>
       <tr>
       <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>SI.No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Name</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Mobile No</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Email</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Course Enquired</p></th>
         <th scope="col" className='border-0'><p className='text-center m-0 fw-bold text-secondary py-2'>Source</p></th>
         <th className='border-0'></th>
       </tr>
     </thead>
     <tbody>
       {closedleads.map((x,index)=>{
         return(
           <tr className={index%2==0 ? 'table-light':'table-white'}>
                <td scope="row" className='border-0'><p className='text-center m-0 py-2'>{index+1}</p></td>
   
           <td className='border-0'><p className='text-center m-0 text-primary py-2' style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/lead_details/${x.client_lead_id}`)
            }}>{x.name}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.contact_number}</p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.email}</p></td>
           <td className='border-0'><p className={x.othercourseenquired===null ? 'text-center m-0 py-2':'d-none'}>{x.course_enquiredd}</p>
           <p className={x.othercourseenquired===null ? 'd-none':'text-center m-0 py-2'}>{x.othercourseenquired} <span style={{fontStyle:'italic',color:'orangered',fontSize:'13px'}}>(Other)</span></p></td>
           <td className='border-0'><p className='text-center m-0 py-2'>{x.source}</p></td>
           <td className='border-0'>
            <div className="py-2 text-center">
            <button style={{width:'100px'}} className={`btn btn-sm text-white btn-primary`} onClick={()=>{
              setlead_id(x.customer_lead_id)
              setstage(x.level_lead_name)
              setcourse_id(x.course_enquired)
             setcourse_name(x.course_enquiredd)
             setshow1(true)
            }}>Followup</button>
            </div>
           </td>
         </tr>
         )
       })}
     </tbody>
   </table>
   </div>
     </div>
   </div>
           </div>
           </div>
          
       </div>
   
   {/* --------------------------MODAL FORMS-------------------------- */}
   <ClientForms quotation={quotation} quotation_id={quotation_id} show1={show1} setcount2={setcount2} setshow1={setshow1} client_lead_id={client_lead_id} lead_id={lead_id} setCount={setCount} stage1={stage} setstage={setstage} course_id={course_id} course_name={course_name}/>
   <Generate_quotation quotation_id={quotation_id} show={show2} service={service} service_amount={service_amount} setshow1={setshow3} show1={show3} setshow={setshow2} lead_id={client_lead_id} setcount={setcount2}/>

  {/* Upload excel modal */}
<div class="modal fade" id="excelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between">
        <h5 class="modal-title" id="exampleModalLabel">Upload Excel Sheet</h5>
        <svg data-bs-dismiss="modal" style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div className='d-flex justify-content-center mt-3'>
        <label htmlFor="file" style={{cursor:'pointer',border:'1px dashed gray'}} className='bg-light d-flex justify-content-center rounded py-2 px-4 w-75'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
  <path d="M13.6816 16.4231C13.6816 18.3165 13.0293 19.9276 11.7247 21.2566C10.4203 22.5855 8.825 23.25 6.93875 23.25C5.0525 23.25 3.45313 22.5855 2.14063 21.2566C0.828125 19.9276 0.171875 18.3165 0.171875 16.4231V5.50969C0.171875 4.1876 0.626562 3.06375 1.53594 2.13812C2.44552 1.21271 3.56135 0.75 4.88344 0.75C6.20552 0.75 7.32125 1.21271 8.23063 2.13812C9.14021 3.06375 9.595 4.1876 9.595 5.50969V15.8462C9.595 16.5802 9.33885 17.2124 8.82656 17.7428C8.31448 18.2732 7.69031 18.5384 6.95406 18.5384C6.21781 18.5384 5.58448 18.2774 5.05406 17.7553C4.52365 17.2332 4.25844 16.5969 4.25844 15.8462V5.46156H5.50844V15.8462C5.50844 16.2452 5.645 16.5853 5.91813 16.8666C6.19146 17.1478 6.5276 17.2884 6.92656 17.2884C7.32573 17.2884 7.66188 17.1478 7.935 16.8666C8.20833 16.5853 8.345 16.2452 8.345 15.8462V5.48562C8.34021 4.51437 8.00625 3.69063 7.34313 3.01438C6.68021 2.33813 5.86031 2 4.88344 2C3.91406 2 3.09479 2.34219 2.42563 3.02656C1.75646 3.71073 1.42188 4.53844 1.42188 5.50969V16.4231C1.41708 17.9665 1.95156 19.2818 3.02531 20.3691C4.09885 21.4564 5.40625 22 6.9475 22C8.46667 22 9.75802 21.4564 10.8216 20.3691C11.8851 19.2818 12.4218 17.9665 12.4316 16.4231V5.46156H13.6816V16.4231Z" fill="#5D5FE3"/>
</svg> <span className='ms-3' style={{fontSize:'16px',letterSpacing:'1px',color:'#5d5fe3'}}>Attach your Excel File...</span></label>
<input type="file" onChange={(e)=>{
  setexcel(e.target.files[0])
}} id='file'/>
        </div>
        <div className='mt-2'>
          <p className='text-center m-0' style={{color:'green'}}>{excel && excel.name}</p>
        </div>

      <div className='d-flex justify-content-end mt-4'>
        <button data-bs-dismiss="modal" className='btn btn-primary btn-sm rounded-pill d-flex align-items-center px-3'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7918C3.47159 14.7918 2.36548 14.3399 1.45269 13.4362C0.539894 12.5324 0.0834961 11.4278 0.0834961 10.1223C0.0834961 8.88837 0.508175 7.81866 1.35753 6.91321C2.20689 6.00778 3.20449 5.521 4.35032 5.45287C4.58403 3.93579 5.28182 2.68245 6.44368 1.69287C7.60554 0.703288 8.9577 0.208496 10.5002 0.208496C12.2412 0.208496 13.7182 0.814893 14.931 2.02769C16.1438 3.24048 16.7502 4.71742 16.7502 6.4585V7.50016H17.3912C18.3888 7.53221 19.2258 7.89846 19.9022 8.59891C20.5786 9.29936 20.9168 10.1484 20.9168 11.146C20.9168 12.1676 20.5713 13.0307 19.8802 13.7351C19.1891 14.4396 18.3327 14.7918 17.3111 14.7918H11.662C11.1826 14.7918 10.7823 14.6312 10.4611 14.3101C10.1399 13.9889 9.97933 13.5886 9.97933 13.1091V7.06347L7.79183 9.23894L7.05464 8.5218L10.5002 5.07628L13.9457 8.5218L13.2085 9.23894L11.021 7.06347V13.1091C11.021 13.2694 11.0878 13.4163 11.2213 13.5498C11.3549 13.6834 11.5018 13.7502 11.662 13.7502H17.271C18.0002 13.7502 18.6165 13.4984 19.12 12.995C19.6234 12.4915 19.8752 11.8752 19.8752 11.146C19.8752 10.4168 19.6234 9.80051 19.12 9.29704C18.6165 8.79357 18.0002 8.54183 17.271 8.54183H15.7085V6.4585C15.7085 5.01752 15.2007 3.78923 14.1851 2.7736C13.1694 1.75798 11.9411 1.25016 10.5002 1.25016C9.05919 1.25016 7.83089 1.75798 6.81527 2.7736C5.79964 3.78923 5.29183 5.01752 5.29183 6.4585H4.73094C3.76405 6.4585 2.92137 6.8144 2.2029 7.5262C1.48441 8.23801 1.12516 9.09738 1.12516 10.1043C1.12516 11.1113 1.48107 11.9706 2.19287 12.6825C2.90468 13.3943 3.76405 13.7502 4.771 13.7502H7.37516V14.7918H4.771Z" fill="#fff"/>
</svg> <span className='ms-2' onClick={()=>{
  uploadexcel()
}}>Upload</span></button>
      </div>
      </div>
    </div>
  </div>
</div>

{/* Combination of search */}
<Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Combination of Search
        </Modal.Title>
        <svg style={{cursor:'pointer'}}  onClick={()=>{
            cancel_combined_search()
          }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='p-2 bg-light'>
       <h6 className='fs-5 mb-4 text-center text-primary'>Select Categories</h6>
        <div className='d-flex align-items-center justify-content-between px-3 row m-0'>
          
       {fields.map((x)=>{
        return(
          <div className='col-md-6 col-lg-4'>
          <p style={{cursor:'pointer'}} onClick={()=>{
            handleFieldChange(x)
          }} className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${fields_array[x] ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg> 
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${fields_array[x] ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
<span className='ms-2'>{x}</span></p>
          </div>
        )
       })}
        </div>

        <div className={`m-0 border-top pt-4 mb-3 mt-2`}>
          <div className={`row m-0 align-items-center mb-4 ${fields_array.Lead_Stage ? '':'d-none'}`}>
{/* Filter based on Lead Stage */}
<div className="col-md-6">
<label htmlFor="" className='fw-medium'>Lead Stage</label>
</div>
<div className="col-md-6">
<select value={formdata.level_lead} onChange={combine_fields} name="level_lead" id="" className={`bg-transparent border-0 border-bottom rounded-0 form-select shadow-none py-2 mt-2`} style={{border:'2px solid white'}}>
            <option value="">All Leads</option>
            <option value="newlead">New Lead</option>
            <option value="walkin">Walkin</option>
            <option value="followup">Followup</option>
            <option value="prospect">Prospect</option>
            <option value="registered">Registered</option>
            <option value="closed">Closed</option>
          </select>
</div>
          </div>
          
          <div className={`row align-items-center m-0 mb-4 ${fields_array.Course_Enquired ? '':'d-none'}`}>
             {/* Filter based on course */}
<div className="col-md-6">
<label htmlFor="" className='fw-medium'>Course Enquired</label>
</div>

         <div className="col-md-6">
         <input type="text" value={formdata.course_enquired} name='course_enquired' onChange={combine_fields} className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`} style={{border:'2px solid white'}}/>
         </div>
          </div>

          <div className={`row align-items-center m-0 mb-4 ${fields_array.Source ? '':'d-none'}`}>
             {/* Filter based on source */}
             <div className="col-md-6">
             <label htmlFor="" className='fw-medium'>Source</label>
             </div>
         <div className="col-md-6">
         <select value={formdata.source} onChange={combine_fields} name="source" id="" className={`form-select bg-transparent border-0 border-bottom rounded-0 shadow-none py-2 mt-2`} style={{border:'2px solid white'}}>
            <option value="none" selected>Choose...</option>
      <option value="other">Other</option>
      <option value="walkin">Walkin</option>
      <option value="naukri">Naukri</option>
      <option value="linkedin">Linkedin</option>
      <option value="just dial">Just Dial</option>
      <option value="whatsapp">Whatsapp</option>
      <option value="facebook">Facebook</option>
      <option value="instagram">Instagram</option>
          </select>
         </div>
          </div>

          <div className={`row m-0 align-items-center mb-4 ${fields_array.Name ? '':'d-none'}`}>
             {/* Filter based on course */}
             <div className="col-md-6">
             <label htmlFor="" className='fw-medium'>Name</label>
             </div>
          <div className="col-md-6">
          <input type="text" value={formdata.lead_name} name='lead_name' onChange={combine_fields} className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`} style={{border:'2px solid white'}}/>
          </div>
          </div>

          <div className={`row m-0 align-items-center mb-4 ${fields_array.Email ? '':'d-none'}`}>
             {/* Filter based on course */}
             <div className="col-md-6">
             <label htmlFor="" className='fw-medium'>Email</label>
             </div>
             <div className="col-md-6">
             <input type="text" value={formdata.email} name='email' onChange={combine_fields} className={`form-control shadow-none py-2 bg-transparent mt-2 rounded-0 border-0 border-bottom`} style={{border:'2px solid white'}}/>
             </div>
          </div>

          <div className={`row m-0 align-items-center mb-4 ${fields_array.Contact_Number ? '':'d-none'}`}>
             {/* Filter based on course */}
             <div className="col-md-6">
              <label htmlFor="" className='fw-medium'>Contact Number</label>
             </div>
             <div className="col-md-6">
             <input type="tel" maxLength={10} value={formdata.contact_number} name='contact_number' onChange={combine_fields} className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`} style={{border:'2px solid white'}}/>
             </div>
          </div>

          <div className={`row m-0 align-items-center mb-4 ${fields_array.Date ? '':'d-none'}`}>
             {/* Filter based on course */}
             <div className="col-md-6">
             <label htmlFor="" className='fw-medium'>Date</label>
             </div>
             <div className="col-md-6">
             <input type="date" value={formdata.Date} name='Date' onChange={combine_fields} className={`form-control shadow-none py-2 bg-transparent border-0 border-bottom rounded-0 mt-2`} style={{border:'2px solid white'}}/>
             </div>
          </div>

          <div className='text-end mt-2'>
            <button className='btn btn-sm btn-primary px-4 py-2' onClick={combined_search}>
            <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} role="status" aria-hidden="true"></span>
            <span className={load ? 'd-none':''}>Search</span>
            </button>

          </div>
        </div>
       </div>
      </Modal.Body>
      
    </Modal>
   </div>
    )}
   </div>
  )
}

export default Leads