import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress, ipaddress2 } from '../App'
import axios from 'axios'

const Sidebar = ({activevalue}) => {
 
  const[open,setOpen]=useState(true)
  const[value,setValue]=useState("")
  useEffect(()=>{
    setValue(activevalue)
  },[])

  const user=JSON.parse(sessionStorage.getItem('user'))


  // Logout

  const navigate=useNavigate()

  const handleLogout=()=>{
    axios.post(`${ipaddress2}/StudentLogout/${user.email}/`)
    .then((r)=>{
      sessionStorage.removeItem("user")
      navigate('/student_login')
    })
    .catch((err)=>{
      console.log("Logout Error",err)
    })
  }


  return (
    <div style={{width:open ? '220px' : '60px',height:'100vh',overflowY:'scroll'}} className='bg-white shadow-sm sidebar d-none d-lg-block'>
            <div className='px-4 d-flex align-items-center justify-content-center' style={{position:'relative',height:'70px'}}>
                <img className={open ? 'd-inline' : 'd-none'} src={require('../images/student/Logo_Blue 1.png')} style={{width:'86%'}} alt="" />
            </div>


<ul class={`nav flex-column px-2 ${open ? 'w-100' : ''} mx-auto px-0 mt-2`}>
  
  <li class={`nav-item py-1  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
    <Link to='/student_dashboard' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="dashboard"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Dashboard </span></Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_batches' class={`nav-link d-flex align-items-center justify-content-center ${value==="batches" ? 'text-white' : 'text-dark'}`} href="#">
  <span className={`${open ? 'd-inline w-75' : 'd-none'}`}>Batches</span> </Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="courses" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_courses' class={`nav-link d-flex align-items-center justify-content-center ${value==="courses" ? 'text-white' : 'text-dark'}`} href="#">
  <span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Courses</span> </Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="marks" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_marks' class={`nav-link d-flex align-items-center justify-content-center ${value==="marks" ? 'text-white' : 'text-dark'}`} href="#"><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Marks</span> </Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="fees" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/installments' class={`nav-link d-flex align-items-center justify-content-center ${value==="fees" ? 'text-white' : 'text-dark'}`} href="#"><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Fees & Payment</span> </Link>
  </li>

</ul>


<ul class={`nav flex-column mt-3 border-top pt-3 ${open ? 'w-100' : ''} mx-auto px-2`}>
  <p className='text-secondary m-0 fw-medium px-2 pb-2' style={{fontSize:'13px'}}>OTHERS</p>

<li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="complaint" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/complaint' class={`nav-link d-flex align-items-center justify-content-center ${value==="complaint" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Suggestion</span></Link>
  </li>

<li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leave" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/your_leaves' class={`nav-link d-flex align-items-center justify-content-center ${value==="leave" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Leave Request</span></Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'}`} style={{backgroundColor:value==="invoice" ? '#4880FF' : '#fff',borderRadius:'5px'}}>
  <Link to='/project_dashboard' class={`nav-link d-flex align-items-center justify-content-center ${value==="invoice" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Project</span></Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="interview" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/interview_details' class={`nav-link d-flex align-items-center justify-content-center ${value==="interview" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Interview Details</span></Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="feedback" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/feedback' class={`nav-link d-flex align-items-center justify-content-center ${value==="feedback" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Feedback</span></Link>
  </li>

  <li class={`nav-item py-1 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="refer" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/refer' class={`nav-link d-flex align-items-center justify-content-center ${value==="refer" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Refer & Earn</span></Link>
  </li>

</ul>

    </div>
  )
}

export default Sidebar