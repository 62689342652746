import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import View_Customerleads from './View_customer_leads'
import View_client_Leads from './View_client_leads'
import { useNavigate, useParams } from 'react-router-dom'

const View_employee_leads = () => {

  const {id}=useParams()
    const navigate=useNavigate()
  const[value,setValue]=useState('customer')

  return (
    <div className='d-flex'>
        <Sidebar activevalue={"employees"}/>
        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3 bg-light" style={{minHeight:'100vh'}}>
                <div className='mb-3'>
                <button onClick={()=>{
        setValue("customer")
    }} className={`btn btn-sm fw-medium py-2 px-4`} style={{borderRadius:'6px 0px 0px 6px',fontSize:'14px',backgroundColor:value==='customer' ? '#57309C':'#ffff',color:value==='customer' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>B2C Leads</button>
    <button onClick={()=>{
        setValue("client")
    }} className={`btn btn-sm fw-medium py-2 px-4`} style={{borderRadius:'0px 6px 6px 0px',fontSize:'14px',backgroundColor:value==='client' ? '#57309C':'#ffff',color:value==='client' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>B2B Leads</button>
                </div>

                <div className={value==='customer' ? '':'d-none'}>
                  <View_Customerleads id={id}/>
                </div>

                <div className={value==='client' ? '':'d-none'}>
                  <View_client_Leads id={id}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default View_employee_leads