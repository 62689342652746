import axios from 'axios';
import React from 'react'
import { useState,useEffect } from 'react';
import { Zoom } from 'react-toastify';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

const ClientForms = ({lead_id,setCount,stage1,setstage,client_lead_id,course_id,course_name,show1,setshow1,setcount2,quotation,quotation_id}) => {

  // const[stage1,setstage1]=useState()

  // useEffect(()=>{
  //   setstage1(stage)
  // },[stage])

  console.log("Course idddddd",course_id)

  const user=JSON.parse(sessionStorage.getItem('user'))

  const[availablecourses,setAvailablecourses]=useState([])
  const[classtypes,setclasstypes]=useState([])
  const[batchtypes,setbatchtypes]=useState([])
  const[particular_lead_details,setparticular_lead_details]=useState({})

  useEffect(()=>{

    axios.get(`${ipaddress}/Allclasstypes/`)
    .then((r)=>{
      console.log("Class types",r.data)
      setclasstypes(r.data.classtypes)
    })

    axios.get(`${ipaddress}/Allbatchtypes/`)
    .then((r)=>{
      console.log("Batch types",r.data)
      setbatchtypes(r.data.batchtypes)
    })
  },[])

  const[course_fees,setcourse_fees]=useState({})

  useEffect(()=>{
    if(course_id>0){
      axios.get(`${ipaddress}/CourseFees/${course_id}`)
      .then((r)=>{
        console.log("Course Fees",r.data)
        setcourse_fees(r.data)
      })
      .catch((err)=>{
        console.log("Courses fetching Error",err)
      })
    }
  },[course_id])

// To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const [errorMessage, setErrorMessage] = useState('');
  // Back Date Validation
  const handleDateValidation = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
  
    setFormData({
      ...formData,
      [name]: date
    });
  
    return true;
  };

  // Prospect Back date validation
  const handleDateValidation2 = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
  
    setprospects({
      ...prospects,
      [name]: date
    });
  
    return true;
  };

  const [formData, setFormData] = useState({
    customer_lead_id:'',
    client_lead_id:'',
    emp_id:user.emp_id,
    level_lead:'followup',
    Followup_Date: getCurrentDate(),
    Expected_Registration_Walkin_Date: '',
    expected_walkin_date:'',
    expected_followup_date: '',
    Expected_Registration_Walkin_Time: '',
    expected_walkin_time:'',
    Need_Supervisor_Attention: false,
    created_at: '',
    Remarks:'',
  priority:''

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const followupdata=(e)=>{
    e.preventDefault()
    if(lead_id!==''){
    formData.customer_lead_id=lead_id
    }
    else{
      formData.client_lead_id=client_lead_id
    }

    axios.post(`${ipaddress}/MovetoFollowupView/`,formData)
    .then((r)=>{
      setshow1(false)
      toast.success('Moved to Followup',{
        autoClose: 2000,
        theme:'colored',
      position:'top-center'
      })
      console.log("Moved to Followup",r.data)
      console.log("Moved to Followup form",formData)
      setCount((prev)=>prev+1)
      setcount2(prev=>prev+1)
      setFormData({
        customer_lead_id:'',
        client_lead_id:'',
        emp_id:user.emp_id,
        level_lead:'followup',
        Followup_Date: getCurrentDate(),
        Expected_Registration_Walkin_Date: '',
        expected_walkin_date:'',
        expected_followup_date: '',
        Expected_Registration_Walkin_Time: '',
        expected_walkin_time:'',
        Need_Supervisor_Attention: false,
        created_at: '',
        Remarks:'',
  priority:''

      })
    })
    .catch((err)=>{
      console.log("Moved to followup error",err)
      console.log("Moved to Followup form",formData)

    })
  }


// ---------------------------------------------------Prospects Form--------------------------------------------------------
const [prospects, setprospects] = useState({
  customer_lead_id:'',
  client_lead_id:'',
  emp_id:user.emp_id,
  level_lead:'prospect',
  preferred_course : '',
  preferred_batch_type: '',
  preferred_batch_start_time: '',
  preferred_class_type: '',
  expected_registration_date: '',
  tentative_batch_start_date:'',
  billing_amount:'',
  expected_collection :'',
  mode_of_payment :'',
  date_of_payment :'',
  time_of_payment:'',
  remarks:'',
  priority:''
});

const handleprospectsdata = (e) => {
  const { name, value } = e.target;
  setprospects({
    ...prospects,
    [name]: value
  });
};

const prospectsdata=(e)=>{
  e.preventDefault()
  if(lead_id!==''){
    prospects.customer_lead_id=lead_id
    }
    else{
      prospects.client_lead_id=client_lead_id
    }

    prospects.billing_amount=course_fees.single_shot_fees

  axios.post(`${ipaddress}/MovetoProspectView/`,prospects)
  .then((r)=>{
    setShow(false)
    toast.success('Moved to Prospect',{
      autoClose: 2000,
      theme:'colored',
      position:'top-center'
    })
    setprospects({
      customer_lead_id:'',
  client_lead_id:'',
  emp_id:user.emp_id,
  level_lead:'prospect',
  preferred_course : '',
  preferred_batch_type: '',
  preferred_batch_start_time: '',
  preferred_class_type: '',
  expected_registration_date: '',
  tentative_batch_start_date:'',
  billing_amount:'',
  expected_collection :'',
  mode_of_payment :'',
  date_of_payment :'',
  time_of_payment:'',
  remarks:'',
  priority:''

    })
    console.log("Moved to Prospects",r.data)
    console.log("Moved to Prospects form",prospects)
    setCount((prev)=>prev+1)
    setcount2(prev=>prev+1)
  })
  .catch((err)=>{
    console.log("Moved to prospects error",err)
    console.log("Moved to prospects form",prospects)

  })
}


// -----------------------------------------------Closed Leads----------------------------------------------------
const [closeddata, setcloseddata] = useState({
  emp_id:user.emp_id,
  customer_lead_id:'',
  client_lead_id:'',
  level_lead:'closed',
  reason_for_closure:'',
  stage_of_closure:'',
  remarks:'',
  opportunities_in_future:false,
  only_job:false
});

const handlecloasedChange = (e) => {
  const { name, value } = e.target;
  setcloseddata({
    ...closeddata,
    [name]: value
  });
};

const closed_details=(e)=>{
  e.preventDefault()
  if(lead_id!==''){
    closeddata.customer_lead_id=lead_id
    }
    else{
      closeddata.client_lead_id=client_lead_id
    }
  closeddata.stage_of_closure=stage1
  
  if(quotation==true){
    axios.patch(`${ipaddress}/Quotation/`,{
      id:quotation_id,
      accepted_status:'rejected'
    })
    .then((r)=>{
      console.log("Rejected",r.data);
    })

    move_to_closed()
  }
  else{
    move_to_closed()
  }
}

const move_to_closed=()=>{
  axios.post(`${ipaddress}/MovetoClosedView/`,closeddata)
  .then((r)=>{
    toast.success('Moved to Closed',{
      autoClose: 2000,
      theme:'colored',
      position:'top-center'
    })
    console.log("Moved to closed",r.data)
    console.log("Moved to closed form",closeddata)
    setCount((prev)=>prev+1)
    setcount2(prev=>prev+1)
    setcloseddata({
      emp_id:user.emp_id,
      customer_lead_id:'',
      client_lead_id:'',
      level_lead:'closed',
      reason_for_closure:'',
      stage_of_closure:'',
      remarks:'',
      opportunities_in_future:false,
      only_job:false
    })
  })
  .catch((err)=>{
    console.log("Moved to closed error",err)
    console.log("Moved to closed form",closeddata)
    console.log(lead_id)

  })
}

const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  

return (
    <div>
        {/* ------------------------------------FOLLOWUP FORM----------------------------------------------------------- */}

<Modal size="md"
        aria-labelledby="example-modal-sizes-title-md" show={show1} onHide={()=>{
  setshow1(false)
}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        <div class="modal-body">
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Followup Form</h5>
        <button onClick={()=>{
          setshow1(false)
        }} className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>
        <form action="" onSubmit={followupdata}>
            <div className="row m-0">
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" disabled='true' value={lead_id!=='' ? lead_id : client_lead_id} class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Lead Id</label>
</div>
                </div>
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input required type="date" class="form-control shadow-none" id="floatingInput" disabled='true' placeholder="name@example.com" name='Followup_Date' value={formData.Followup_Date} 
  // onChange={(e)=>{
  //   // handleInputChange
  //   handleDateValidation(e.target.value,'Followup_Date');
  // }}
  onChange={handleInputChange}
  />
  <label for="floatingInput">Followup Date <span className='required-field'>*</span></label>
</div>
{/* {errorMessage && <div className="col-12 text-danger mb-3">{errorMessage}</div>} */}
                </div>

                <div className='col-12 mb-3'>
                  <label htmlFor="">Lead stage1</label>
                {/* <input required id="" className='form-control py-3 shadow-none mt-2' onChange={handleprospectsdata} value={stage1}/> */}
                <select name="" onChange={(e)=>{
                  setstage(e.target.value)
                  if(e.target.value==='prospect'){
                    setshow1(false)
                    setShow(true)
                  }
                }} value={stage1} className='form-select py-3 mt-2 shadow-none border-secondaruy-subtle' id="">
                  {/* <option value=""></option> */}
                  <option value="newlead">New Lead</option>
                  <option value="followup">Followup</option>
                  <option value="prospect">Prospect</option>
                  <option value="closed">Closed</option>
                </select>
                </div>

                <div className="col-md-6">
                <div class={`form-floating mb-3 ${stage1==='followup' ? '':'d-none'}`}>
  <input type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" name='expected_followup_date' value={formData.expected_followup_date} onChange={(e)=>{
    handleDateValidation(e.target.value,'expected_followup_date')
  }}/>
  <label for="floatingInput">Expected Followup Date <span className='required-field'>*</span></label>
</div>

                <div class={`form-floating mb-3 ${stage1==='walkin' || stage1==='prospect' || stage1==='followup' ? 'd-none':''}`}>
  <input type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" name='expected_walkin_date' value={formData.expected_walkin_date} onChange={(e)=>{
    handleDateValidation(e.target.value,'expected_walkin_date')
  }}/>
  <label for="floatingInput">Expected Walking Date <span className='required-field'>*</span></label>
</div>

<div class={`form-floating mb-3 ${stage1==='walkin' || stage1==='prospect' ? '':'d-none'}`}>
  <input type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" name='Expected_Registration_Walkin_Date' value={formData.Expected_Registration_Walkin_Date} onChange={(e)=>{
    handleDateValidation(e.target.value,'Expected_Registration_Walkin_Date')
  }}/>
  <label for="floatingInput">Expected Registration Date <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6">
                <div class={`form-floating mb-3 ${stage1==='walkin' || stage1==='prospect' || stage1==='followup' ? 'd-none':''}`}>
  <input type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" name='expected_walkin_time' value={formData.expected_walkin_time} onChange={handleInputChange}/>
  <label for="floatingInput">Expected Walkin Time <span className='required-field'>*</span></label>
</div>

<div class={`form-floating mb-3 ${stage1==='walkin' || stage1==='prospect' ? '':'d-none'}`}>
  <input type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" name='Expected_Registration_Walkin_Time' value={formData.Expected_Registration_Walkin_Time} onChange={handleInputChange}/>
  <label for="floatingInput">Expected Regsitration Time <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-12 mb-3">
  <label for="inputState" className="form-label shadow-none">Priority</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-3" name="priority" value={formData.priority} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
                <div className="col-12">
                <div class="mb-3">
                    <textarea required className='p-2 rounded border-secondary' id="" placeholder='Remarks...' rows="3" style={{width:'100%'}} name='Remarks' value={formData.Remarks} onChange={handleInputChange}></textarea>
</div>
                </div>
                <div className='col-12 d-flex align-items-center'>
                    <input name='Need_Supervisor_Attention' type="checkbox" id='checkbox1' onClick={(e)=>{
                      const {checked}=e.target
                      setFormData((prev)=>({
                        ...prev,
                        Need_Supervisor_Attention:checked
                      }))
                    }}/>
                    <label htmlFor="checkbox1" className='ms-2 text-primary' style={{fontSize:'14px',fontWeight:600}}>Need Supervisor Attention</label>
                </div>
                <div className="col-12 text-end mt-4">
                    <button type='submit' className='btn btn-sm px-5 py-2 btn-primary text-white fw-medium'>Submit</button>
                </div>

            </div>
        </form>
      </div>
        </Modal.Body>

      </Modal>

{/* ------------------------------------------PROSPECTS FORM------------------------------------------------------------- */}
<div class="modal fade" id="prospectsform" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-body">
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Prospects Form</h5>
        <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>
        <form action="" onSubmit={prospectsdata}>
            <div className="row m-0">
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" disabled='true' value={lead_id!=='' ? lead_id : client_lead_id} class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Lead Id</label>
</div>
                </div>
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" value={user.emp_id} disabled='true' class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Employee Id</label>
</div>
                </div>

                <div className="col-md-6 col-lg-4 mb-3">
                <input type="text" disabled='true' className='form-control shadow-none py-3' value={course_name && course_name} />
                </div>

                <div className="col-md-6 col-lg-4">

<div class="form-floating mb-3">
<input disabled='true' type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={course_fees.single_shot_fees} name='billing_amount'/>
<label for="floatingInput">Total Fees Amount <span className='required-field'>*</span></label>
</div>
</div>

{/* Installments */}
{course_fees.installments && course_fees.installments.map((x,index)=>{
return(
<div className="col-md-6 col-lg-4">
<div class="form-floating mb-3">
<input disabled='true' type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" value={x.amount} name='billing_amount'/>
<label for="floatingInput"> Installment {index+1} Amount<span className='required-field'>*</span></label>
</div>
</div>
)
})}

                <div className="col-md-6 col-lg-4 mb-3">
  <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.preferred_batch_type} name='preferred_batch_type'>
    <option value="">Preferred Batch Type <span className='required-field'>*</span></option>
    {batchtypes.map((x)=>{
      return(
        <>
        <option value={x}>{x}</option>
        </>
      )
    })}
  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.preferred_class_type} name='preferred_class_type'>
    <option value="">Preferred Class Type <span className='required-field'>*</span></option>
    {classtypes.map((c)=>{
      return(
        <>
        <option value={c}>{c}</option>
        </>
      )
    })}
  </select>
                </div>

                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={(e)=>{
    handleDateValidation2(e.target.value,'tentative_batch_start_date')
  }} value={prospects.tentative_batch_start_date} name='tentative_batch_start_date'/>
  <label for="floatingInput">Tentative Batch Start Date <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.preferred_batch_start_time} name='preferred_batch_start_time'/>
  <label for="floatingInput">Preferred Batch Start Time <span className='required-field'>*</span></label>
</div>
                </div>
                
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={(e)=>{
    handleDateValidation2(e.target.value,'expected_registration_date')
  }} value={prospects.expected_registration_date} name='expected_registration_date'/>
  <label for="floatingInput">Expected Registration Date <span className='required-field'>*</span></label>
</div>
                </div>
                
                
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.expected_collection} name='expected_collection'/>
  <label for="floatingInput">Expected Collection <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.mode_of_payment} name='mode_of_payment'>
    <option value="">Mode of Payment</option>
    <option value="Cash">Cash</option>
    <option value="Cheque">Cheque</option>
    <option value="Bank Transfer">Bank Transfer</option>
    <option value="UPI">UPI</option>
  </select>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.date_of_payment} name='date_of_payment'/>
  <label for="floatingInput">Date of Payment <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.time_of_payment} name='time_of_payment'/>
  <label for="floatingInput">Time of Payment</label>
</div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
    <select required id="inputState" className="form-select shadow-none bg-light py-3" name="priority" value={prospects.priority} onChange={handleprospectsdata}>
      <option selected>Choose Priority...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
                <div className="col-12">
                <div class="">
                    <textarea required className='p-2 rounded border-secondary' id="" placeholder='Remarks...' rows="3" style={{width:'100%'}} onChange={handleprospectsdata} value={prospects.remarks} name='remarks'></textarea>
</div>
                </div>
                <div className="col-12 text-end mt-4">
                    <button data-bs-dismiss="modal" type='submit' className='btn btn-sm px-5 py-2 btn-primary text-white fw-medium'>Submit</button>
                </div>

            </div>
        </form>
      </div>
    </div>
  </div>
</div>

{/* FOLLOWUP TO PROSPECT FORM */}

<Modal size="xl"
        aria-labelledby="example-modal-sizes-title-xl" show={show} onHide={()=>{
  setShow(false)
}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        <div>
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Prospects Form</h5>
        <button onClick={()=>{
          setShow(false)
        }} className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>
        <form action="" onSubmit={prospectsdata}>
            <div className="row m-0">
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" disabled='true' value={lead_id!=='' ? lead_id : client_lead_id} class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Lead Id</label>
</div>
                </div>
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" value={user.emp_id} disabled='true' class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Employee Id</label>
</div>
                </div>

                <div className="col-md-6 col-lg-4 mb-3">
                <input type="text" disabled='true' className='form-control shadow-none py-3' value={course_name && course_name} />
                </div>

                <div className="col-md-6 col-lg-4">

<div class="form-floating mb-3">
<input disabled='true' type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={course_fees.single_shot_fees} name='billing_amount'/>
<label for="floatingInput">Total Fees Amount <span className='required-field'>*</span></label>
</div>
</div>

{/* Installments */}
{course_fees.installments && course_fees.installments.map((x,index)=>{
return(
<div className="col-md-6 col-lg-4">
<div class="form-floating mb-3">
<input disabled='true' type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" value={x.amount} name='billing_amount'/>
<label for="floatingInput"> Installment {index+1} Amount<span className='required-field'>*</span></label>
</div>
</div>
)
})}

                <div className="col-md-6 col-lg-4 mb-3">
  <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.preferred_batch_type} name='preferred_batch_type'>
    <option value="">Preferred Batch Type <span className='required-field'>*</span></option>
    {batchtypes.map((x)=>{
      return(
        <>
        <option value={x}>{x}</option>
        </>
      )
    })}
  </select>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.preferred_class_type} name='preferred_class_type'>
    <option value="">Preferred Class Type <span className='required-field'>*</span></option>
    {classtypes.map((c)=>{
      return(
        <>
        <option value={c}>{c}</option>
        </>
      )
    })}
  </select>
                </div>

                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={(e)=>{
    handleDateValidation2(e.target.value,'tentative_batch_start_date')
  }} value={prospects.tentative_batch_start_date} name='tentative_batch_start_date'/>
  <label for="floatingInput">Tentative Batch Start Date <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.preferred_batch_start_time} name='preferred_batch_start_time'/>
  <label for="floatingInput">Preferred Batch Start Time <span className='required-field'>*</span></label>
</div>
                </div>
                
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={(e)=>{
    handleDateValidation2(e.target.value,'expected_registration_date')
  }} value={prospects.expected_registration_date} name='expected_registration_date'/>
  <label for="floatingInput">Expected Registration Date <span className='required-field'>*</span></label>
</div>
                </div>
                
                
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="number" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.expected_collection} name='expected_collection'/>
  <label for="floatingInput">Expected Collection <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <select required id="" className='form-select py-3 shadow-none' onChange={handleprospectsdata} value={prospects.mode_of_payment} name='mode_of_payment'>
    <option value="">Mode of Payment</option>
    <option value="Cash">Cash</option>
    <option value="Cheque">Cheque</option>
    <option value="Bank Transfer">Bank Transfer</option>
    <option value="UPI">UPI</option>
  </select>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input required type="date" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.date_of_payment} name='date_of_payment'/>
  <label for="floatingInput">Date of Payment <span className='required-field'>*</span></label>
</div>
                </div>
                <div className="col-md-6 col-lg-4">
                <div class="form-floating mb-3">
  <input type="time" class="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={handleprospectsdata} value={prospects.time_of_payment} name='time_of_payment'/>
  <label for="floatingInput">Time of Payment</label>
</div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
    <select required id="inputState" className="form-select shadow-none bg-light py-3" name="priority" value={prospects.priority} onChange={handleprospectsdata}>
      <option selected>Choose Priority...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
                <div className="col-12">
                <div class="">
                    <textarea required className='p-2 rounded border-secondary' id="" placeholder='Remarks...' rows="3" style={{width:'100%'}} onChange={handleprospectsdata} value={prospects.remarks} name='remarks'></textarea>
</div>
                </div>
                <div className="col-12 text-end mt-4">
                    <button type='submit' className='btn btn-sm px-5 py-2 btn-primary text-white fw-medium'>Submit</button>
                </div>

            </div>
        </form>
      </div>
        </Modal.Body>

      </Modal>

{/* -----------------------------------CLOSED FORM------------------------------------------------------------ */}
<div class="modal fade" id="closedform" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Closed Form</h5>
        <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>
        <form action="" onSubmit={closed_details}>
            <div className="row m-0">
                <div className="col-md-6">
                <div class="form-floating mb-3">
  <input type="text" disabled='true' value={lead_id!=='' ? lead_id : client_lead_id} class="form-control shadow-none" id="floatingInput" placeholder="name@example.com"/>
  <label for="floatingInput">Lead Id</label>
</div>
                </div>
                <div className="col-12">
                <div className="col-12">
                <textarea required onChange={handlecloasedChange} value={closeddata.reason_for_closure} name='reason_for_closure' className='p-2 rounded border-secondary' id="" placeholder='Reason for Closure...' rows="3" style={{width:'100%'}}></textarea>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="">stage1 of Closure</label>
                <input value={stage1} disabled='true' className='form-control p-2 rounded border-secondary shadow-none' id="" placeholder='stage1 for Closure...'/>
                </div>
                <div class="mb-3 mt-3">
                    <textarea required onChange={handlecloasedChange} value={closeddata.remarks} name='remarks' className='p-2 rounded border-secondary' id="" placeholder='Remarks...' rows="3" style={{width:'100%'}}></textarea>
</div>
                </div>
                <div className='col-12 d-flex align-items-center mt-3'>
                    <label htmlFor="checkbox1" className='ms-2 text-primary fw-medium'>Opportunities in Future</label>
                    <input type="radio" name='opportunities' className='me-1 ms-3' id='yes'/><label htmlFor="yes">Yes</label>
                    <input type="radio" name='opportunities' className='ms-3 me-1' id='no'/><label htmlFor="no">No</label>
                    <input type="checkbox" onChange={(e)=>{
                      if(e.target.checked){
                        closeddata.only_job=true
                      }
                      else{
                        closeddata.only_job=false
                      }
                    }} name='only_job' className='ms-3 me-1'/><label htmlFor="only_job">Only Job</label>
                </div>
                <div className="col-12 text-end mt-4">
                    <button data-bs-dismiss="modal" type='submit' className='btn btn-sm px-5 py-2 btn-primary text-white fw-medium'>Submit</button>
                </div>

            </div>
        </form>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default ClientForms