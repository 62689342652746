import React, { useEffect, useState } from 'react'
import Mode_of_payment_modal from './Mode_of_payment_modal'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import { useParams } from 'react-router-dom'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const Receipt_page = () => {

const {token}=useParams()

  const[printmode,setprintmode]=useState(false)

  const togglePrintMode = () => {
    setprintmode(prevMode => !prevMode);
  }

  const handleprint=()=>{
    window.print()
  }
  
  const[receiptdata,setreceiptdata]=useState({})
  const[installmentdata,setinstallmentdata]=useState([])
  const cust_number='CUSF73A89E'

  useEffect(()=>{

    axios.get(`${ipaddress2}/ReceiptView/${token}/`)
    .then((r)=>{
      console.log("Receipt data",r.data)
      setreceiptdata(r.data)
    })
    .catch((err)=>{
      console.log("Receipt Error",err)
    })
  },[token])

  const downloadCertificate = () => {
    const input = document.getElementById('receipt')
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF('landscape', 'pt', 'a4')
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      const imgX = (pdfWidth - imgWidth * ratio) / 10
      const imgY = (pdfHeight - imgHeight * ratio) / 10

      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
      pdf.save('receipt.pdf')
    }).catch(error => {
      console.error('Error generating PDF:', error)
    })
  }


  return (
    <div className='py-2'>
        {/* {invoidedata.map((y,index)=>{
          return( */}
            <div className='row bg-light m-0 pb-4 mb-4 justify-content-center'>
              <div className='d-flex justify-content-center py-3'>
                        <button onClick={downloadCertificate} className="btn btn-primary d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
</svg> <span className='ms-2'>Download</span></button>
              </div>
            <div className='col-md-8 bg-white pb-4' id='receipt'>
            <img src={require('../images/Group 27211.png')} alt="" style={{width:'100%'}} />

            <div className='px-4'>
            <div className='d-flex justify-content-between align-items-center mt-4 px-4'>
            <div>
            <p className='m-0 font1'><span className='text-secondary me-3'>To</span></p>
                    <p className='m-0 mt-1 font1'><span className='text-secondary me-3 fw-medium' style={{fontSize:'16px'}}>{receiptdata.customer && receiptdata.customer.customer_name}</span></p>
                    <p className='m-0 mt-1 font1'><span className='text-secondary me-3' style={{fontSize:'13px'}}>{receiptdata.customer && receiptdata.customer.email}</span></p>
            </div>
         
                <div>
                    <p className='m-0 font1 d-flex justify-content-between'><span className='text-secondary'>Invoice No </span><span className='text-dark fw-medium'>{receiptdata.invoice_id && receiptdata.invoice_id.invoice_number}</span></p>
                    <p className='m-0 mt-1 font1 d-flex justify-content-between'><span className='text-secondary'>Invoice Date </span> <span className='text-dark fw-medium'>{receiptdata.created_at && receiptdata.created_at.slice(0,10)}</span></p>
                    <p className='m-0 mt-1 font1 d-flex justify-content-between'><span className='text-secondary'>Due Date </span> <span className='text-dark fw-medium'>{receiptdata.invoice_id && receiptdata.invoice_id.created_at && receiptdata.invoice_id.created_at.slice(0,10)}</span></p>
                </div>
            </div>

            <div className="row mt-4 m-0 px-4">
                <div className="col-md-6 ps-0">
                    <div className='invoice_div rounded py-3'>
                        <h6 className='fw-medium pb-1' style={{color:'#250F3C',fontSize:'16px'}}>Billed by</h6>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>Company</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>Merida</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>Address</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>Jayanagar</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>City</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>Bangalore</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>State</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>Karnataka</p>
                      </p>
                    </div>
                </div>

{/* -----------------------------------------Client / Customer----------------------------------------------- */}
                <div className="col-md-6 ps-0">
                    <div className='invoice_div rounded py-3'>
                        <h6 className='fw-medium pb-1' style={{color:'#250F3C',fontSize:'16px'}}>Billed to</h6>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>Name</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>{receiptdata.customer && receiptdata.customer.customer_name}</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>Mobile No</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>{receiptdata.customer && receiptdata.customer.email}</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>City</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>{receiptdata.customer && receiptdata.customer.registration_id && receiptdata.customer.registration_id.City}</p>
                      </p>
                      <p className='m-0 d-flex'>
                        <p className='invoce-para1 m-0'>State</p>
                        <p className='invoce-para2 m-0 fw-medium text-black'>{receiptdata.customer && receiptdata.customer.registration_id && receiptdata.customer.registration_id.State}, {receiptdata.customer && receiptdata.customer.registration_id && receiptdata.customer.registration_id.Pincode}</p>
                      </p>
                    </div>
                </div>
            </div>

            <div className='mt-4 pb-1'>
            <div className="table-responsive">
            <table className="table-borderless w-100">
  <thead className='border-bottom invoice-table'>
    <tr className=''>
      <th scope="col" className='py-3'><p className='text-center m-0'>SI No</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Amount</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Due Date</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Mode of Payment</p></th>

    </tr>
  </thead>
  <tbody className=''>
    {/* {invoidedata.map((x,index)=>{
      return(
        <> */}
        <tr className=''>
      <th scope="row" className='invoice-table-cell'><p className='text-center m-0'>1</p></th>
      <td className='invoice-table-cell fw-medium' style={{fontSize:'16px'}}><p className='text-center m-0'>{receiptdata.fees && receiptdata.fees}</p></td>
      <td className='invoice-table-cell fw-medium'><p className='text-center m-0'>{receiptdata.invoice_id && receiptdata.invoice_id.created_at && receiptdata.invoice_id.created_at.slice(0,10)}</p></td>
      <td className='invoice-table-cell fw-medium'><p className='text-center m-0'>{receiptdata.payment_mode && receiptdata.payment_mode}</p></td>
      {/* <td className='invoice-table-cell fw-medium'>@mdo</td>
      <td className='invoice-table-cell fw-medium'>@mdo</td> */}
    </tr>
        {/* </>
      )
    })} */}
    {/* <tr className=''>
      <td colspan="4" className='' style={{color:'#40BA5A',fontSize:'17px'}}></td>
      <td className='text-center fw-medium invoice-table py-2' style={{fontSize:'15px'}}><span>Total Amount</span></td>
      <td className='invoice-table text-center py-2'><p className='m-0'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg> <span className=''>20000<span>/-</span></span></p></td>
    </tr>
    <tr className=''>
      <td colspan="4" className='' style={{color:'#40BA5A',fontSize:'17px'}}></td>
      <td className='text-center fw-medium invoice-table py-2' style={{fontSize:'15px'}}>Tax</td>
      <td className='invoice-table text-center py-2'><p className='m-0'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg> <span className=''>20000<span>/-</span></span></p></td>
    </tr>
    <tr className=''>
      <td colspan="4" className='' style={{color:'#40BA5A',fontSize:'17px'}}></td>
      <td className='text-center fw-medium text-white py-3' style={{fontSize:'15px',backgroundColor:'#250F3C',borderTop:'4px solid #fff'}}>Grand Total</td>
      <td className='text-center py-3 fw-medium' style={{fontSize:'15px',backgroundColor:'#D3D2D7',borderTop:'4px solid #fff'}}><p className='m-0'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg> <span className=''>20000<span>/-</span></span></p></td>
    </tr> */}
  </tbody>
</table>
</div>
            </div>

{/* ---------------------------------------------------Terms and conditions--------------------------------------------- */}
<div className="row m-0 mt-5 align-items-center">
<div className="col-6">
<p style={{color:'#000',fontWeight:500}} className='ps-0 m-0 mb-2'><span style={{color:'red'}}>*</span>Terms & Conditions</p>
<span style={{fontSize:'13px'}}>1. Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde facilis praesentium consequatur dolor quia tenetur beatae quaerat ad numquam neque.</span>
</div>
<div className="col-6 text-center">
    Signature
</div>
</div>


<div style={{position:'relative'}} className='mt-5'>
<div className='d-flex justify-content-evenly px-3 py-1 align-items-center' style={{backgroundColor:'#250F3C',height:'40px'}}>
    <p className='m-0 text-white'><span className='bg-white rounded px-1 d-felx align-items-center justify-content-center'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#000" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg></span> <span style={{fontSize:'12px'}}>merida@gmail.com</span></p>
<p className='m-0 text-white'><span className='bg-white rounded px-1 d-felx align-items-center justify-content-center'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#000" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg></span> <span style={{fontSize:'12px'}}>Jayanagar, Bangalore.</span></p>
</div>
<div className='d-flex gap-4' style={{backgroundColor:'#57309C',height:'14px'}}>
</div>
</div>


{/* <div className='mt-4 d-flex align-items-center justify-content-between'>
  <div>
    <span><span className='me-2'>For any queries,</span> <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> <span className='text-decoration-underline'>Contact us</span></span>
  </div>
  <div className={`${printmode ? 'd-none' : 'd-flex justify-content-end'}`}>
  <button data-bs-toggle="modal" data-bs-target="#mode_of_payment" className='btn btn-sm bg-primary text-white'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paypal" viewBox="0 0 16 16">
 <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z"/>
</svg> <span className='ms-1'>Pay Now</span></button>
  <button onClick={()=>{
    handleprint()
  }} className='btn btn-sm btn-primary text-white d-flex align-items-center ms-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
</svg><span className='ms-2'>Print</span></button>
  <button className='btn btn-sm btn-primary text-white ms-3'><span>Receipt</span></button>
  </div>
</div> */}
            </div>

            </div>
        </div>
          {/* )
        })}  */}

{/* ------------------------------------------------Ask for help------------------------------------------------------ */}
<div class="modal fade" id="need_help" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Need a help</h5>
        <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>

      </div>
    </div>
  </div>
</div>

<Mode_of_payment_modal/>
    </div>
  )
}

export default Receipt_page