import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Preloader from '../Components/Preloader'
import Upcoming_events from './Upcoming_events'

const Student_batches = () => {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View your Queries
    </Tooltip>
  );

    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[batches,setBatches]=useState([])
    
    useEffect(()=>{
      setloading(true)
        axios.get(`${ipaddress2}/StudentBatches/${user.email}/`)
        .then((r)=>{
            console.log("Student batches",r.data)
            setBatches(r.data)
            setloading(false)
        })
    },[])

    // To get the time in hours and minutes format
    const formatTime = (timeString) => {
      const [hours, minutes] = timeString.split('T')[1].split(':');
      return `${hours}:${minutes}`;
    };

const[loading,setloading]=useState()

return (
    <div>
      {loading ? (<Preloader/>):(
        <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"batches"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
     <div className="row m-0">
      <div className="col-lg-8">
      <h6 className='fs-5 mt-2'>Your Batches</h6>
      <h6 className={`text-center text-secondary py-4 ${batches.length>0 ? 'd-none':''}`}>No batches available...🧐</h6>
          <div className={`row m-0 ${batches.length>0 ? '':'d-none'}`}>

          {
            batches.map((x)=>{
              return(
                <div className="col-md-6 p-4">
                <div class="card p-3 pb-2 border-0">
      {/* <img src={require('../images/Rectangle 26.png')} class="card-img-top" style={{height:'170px'}} alt="..."/> */}
      <div class="card-body d-flex flex-column align-items-center">
        <h5 class="card-title" style={{fontSize:'21px',cursor:'pointer'}} onClick={()=>{
        navigate(`/groups/${x.id}`)
      }}>{x.name.sub_course_name}</h5>
        <p class="card-text py-1 px-4 rounded" style={{backgroundColor:'#FFDDDD',color:'#F12222'}}>status : Ongoing</p>
    
        <div className='d-flex justify-content-between w-100'>
          <p className='d-flex align-items-center' style={{color:'#989898'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{x.start_time && x.start_time.slice(0,10)}</span></p>
    <p className='d-flex align-items-center' style={{color:'#989898'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{x.end_time && x.end_time.slice(0,10)}</span></p>
        </div>

        <div className='d-flex justify-content-between w-100'>
          <p className='d-flex align-items-center' style={{color:'#989898'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{formatTime(x.start_time)}</span></p>
    <p className='d-flex align-items-center' style={{color:'#989898'}}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg> <span style={{fontSize:'13px'}} className='ms-2'>{formatTime(x.end_time)}</span></p>
        </div>


        <div className='d-flex w-100 align-items-center mt-2'>
        <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip}
    >
      <span onClick={()=>{
        navigate(`/your_queries/${x.id}`)
    }} style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4880FF" class="bi bi-chat-left-text" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
</svg></span>
    </OverlayTrigger>

    <a class="btn w-100 fw-medium ms-3" onClick={()=>{
        navigate(`/student_logsheet/${x.id}`)
    }} style={{border:'1px solid #57309C',color:'#57309C'}}>View Log Sheet</a>
        </div>
      </div>
    </div>
                </div>
              )
            })
          }
          </div>
      </div>

      <div className="col-lg-4 px-4">
        <Upcoming_events/>
      </div>
     </div>
        </div>
        </div>
    </div>
      )}
    </div>
  )
}

export default Student_batches