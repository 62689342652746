import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Student_login = () => {
  const [passwordtype,setPasswordtype]=useState(true)

  const[userid,setUserid]=useState("")
  const[password,setpassword]=useState("")
  const navigate=useNavigate()

  const[loading,setLoading]=useState()

  const handlesubmit=(e)=>{
    e.preventDefault()
    setLoading(true)
    console.log({
      email:userid,
      password:password
    });
    const formdata=new FormData()
    formdata.append('username',userid)
    formdata.append('password',password)
    axios.post(`${ipaddress2}/StudentLogin/`,{
      email:userid,
      password:password
    })
    .then((r)=>{
      console.log("Login Successfull",r.data)
      
      sessionStorage.setItem('user',JSON.stringify(r.data))
      navigate('/student_dashboard')
      setLoading(false)
    })
    .catch((error)=>{
      console.log(error);
      console.log("Login Error")
      toast.error('Invalid Email and Password',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
      setLoading(false)
    })
  }

return (
    <div className='bg-light student-login d-flex align-items-center' style={{height:'100vh'}}>
        <div className="row mx-auto m-0 bg-white justify-content-center p-4 student-login-div2" style={{position:'relative',borderRadius:'20px',height:'86%'}}>
        {/* <img src={require('../images/new/Vector5.png')} alt="" style={{height:'80px',width:'60px',position:'absolute',left:'-10px',bottom:'8%'}}/> */}
    <div className="col-lg-6 d-flex justify-content-center align-items-center h-100">
    <div className='rounded px-3 py-5 d-flex flex-column align-items-center justify-content-center' style={{width:'86%'}}>
      <h3 className='text-center m-0 fw-medium mt-3' style={{color:'#57309C',fontSize:'36px'}}>Welcome Student</h3>
      <p>We are glad to see you back with us</p>
    <form action="" className='p-2 py px-2 w-100' onSubmit={handlesubmit}>
    <div class="mb-3">
  <div class="input-group mb-3 mt-4 border py-2" style={{backgroundColor:'#F2F2F2',borderRadius:'10px'}}>
  <span class="input-group-text bg-transparent border-0 h-100" id="basic-addon1">
    <img src={require('../images/Vector2.png')} width={16} alt="" />
    
    </span>
  <input type="text" placeholder='Username' class="form-control border-0 bg-transparent shadow-none login-input" onChange={(e)=>{
    setUserid(e.target.value)
  }} aria-label="Username" aria-describedby="basic-addon1"/>
</div>
 </div>
<div class="mb-3">
  <div class="input-group mb-3 mt-4 border py-2" style={{backgroundColor:'#F2F2F2',borderRadius:'10px'}}>
  <span class="input-group-text bg-transparent border-0 h-100" id="basic-addon1">
    <img src={require('../images/Vector (1).png')} width={17} alt="" />
    </span>
  <input placeholder='Password' type={passwordtype ? 'password':'text'} onChange={(e)=>{
    setpassword(e.target.value)
  }} class="form-control border-0 bg-transparent country-input shadow-none BORDER-0 login-input" aria-label="Username" aria-describedby="basic-addon1"/>
  <span style={{cursor:'pointer'}} onClick={()=>{
    setPasswordtype(!passwordtype)
  }} class="input-group-text border-0 bg-transparent" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#8E9696" class="bi bi-eye-fill" viewBox="0 0 16 16">
  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
</svg></span>
</div>
  </div>
<div className='text-end mb-4'>
  <a href="" style={{color:'#FF845D',textDecoration:'none'}} data-bs-toggle="modal" data-bs-target="#forgotpassword">Forgot Password</a>
</div>
<div class="text-center">
    <button type='submit' className='btn btn-md py-3 px-5 text-white fw-medium w-100' style={{backgroundColor:'#57309C',borderRadius:'10px'}}>
    <span class={`spinner-border spinner-border-sm ${loading ? '':'d-none'}`} aria-hidden="true"></span>
    <span role="status" className={`${loading ? 'ms-2':'d-none'}`}>Loading...</span>
    <span className={`${loading ? 'd-none':''}`}>LOGIN NOW</span>
    </button>
</div>     
</form>
<p className='w-100 text-center mt-3' style={{fontSize:'15px',color:'#8E9696'}}><input type="checkbox" className='me-2' /> <span>By Signing Up you agree to our <a className='fw-medium' style={{textDecoration:'underline'}}> terms & conditions</a></span></p>

        
    </div>
  </div>

  <div className="col-lg-6 h-100 d-flex justify-content-end">
    <img src={require('../images/Rectangle 4.png')} style={{width:'90%',height:'100%'}} alt="" />
          </div>

</div>
    </div>
  )
}

export default Student_login