import axios from 'axios'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ipaddress } from '../App'
import { toast } from 'react-toastify'

const Generate_quotation = ({show,setshow,lead_id,setcount,fetchnewleads,service,service_amount,setshow1,show1,quotation_id}) => {
    console.log("quotation id",quotation_id);
    
    const[product,setproduct]=useState('')
    const[amount,setamount]=useState('')
    const[altered_amount,setaltered_amount]=useState('')

    const user=JSON.parse(sessionStorage.getItem('user'))

    const generate_quotation=()=>{
        axios.post(`${ipaddress}/Quotation/`,{
            employee_id:user.emp_id,
            customer_lead_id:lead_id,
            service:product,
            quote_amount:Number(amount)
        })
        .then((r)=>{
            toast.success('Quotation Generated!',{
                autoClose:1000,
                theme:'colored',
                position:'top-center'
            })
            setamount('')
            setproduct('')
            setshow(false)
            setcount(prev=>prev+1)
        })
    }

    const alter_quotation=()=>{
        axios.put(`${ipaddress}/Quotation/`,{
            id:quotation_id,
            altered_quotation:Number(altered_amount)
        })
        .then((r)=>{
            toast.success('Quotation Altered!',{
                autoClose:1000,
                theme:'colored',
                position:'top-center'
            })
            setaltered_amount('')
            setshow1(false)
            setcount(prev=>prev+1)
        })
    }

  return (
    <div>
       <Modal show={show} onHide={()=>{
        setshow(false)
       }}>
        <Modal.Header closeButton>
          <Modal.Title>Generate Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row m-0 p-4'>
            <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Lead Id</label>
                    <input type="text" disabled='true' value={lead_id} className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>
                <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Product Description</label>
                    <input type="text" value={product} onChange={(e)=>{
                        setproduct(e.target.value)
                    }} className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>
                <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Quotation Amount</label>
                    <input type="number"  value={amount} onChange={(e)=>{
                        setamount(e.target.value)
                    }} className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>

                <div className='mt-4 text-end'>
                    <button className='btn btn-primary px-4' onClick={generate_quotation}>submit</button>
                </div>
            </div>
        </Modal.Body>
      
      </Modal>

      {/* Edit quotation */}
      <Modal show={show1} onHide={()=>{
        setshow1(false)
       }}>
        <Modal.Header closeButton>
          <Modal.Title>Alter the Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='row m-0 p-4'>
            <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Lead Id</label>
                    <input type="text" disabled='true' value={lead_id} className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>
                <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Product Description</label>
                    <input type="text" value={service} disabled className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>
                <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Quotation Amount</label>
                    <input type="number"  value={service_amount} disabled className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>

                <div className="col-12 mb-4">
                    <label htmlFor="" className='fw-medium'>Altered Quotation Amount</label>
                    <input type="number"  value={altered_amount} onChange={(e)=>{
                        setaltered_amount(e.target.value)
                    }} className='form-control shadow-none border-secondary-subtle py-3 mt-2' name="" id="" />
                </div>

                <div className='mt-4 text-end'>
                    <button className='btn btn-primary px-4' onClick={alter_quotation}>submit</button>
                </div>
            </div>
        </Modal.Body>
      
      </Modal>
    </div>
  )
}

export default Generate_quotation
