import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ipaddress } from '../App'

const Customerregistrationform = () => {

  const navigate=useNavigate()

  const[states,setstates]=useState([])
  const[cities,setcities]=useState([])
  const[bachelor_degrees,setbachelor_degrees]=useState([])
  const[bachelor_specifications,setbachelor_specifications]=useState([])

  const[availablecourses,setAvailablecourses]=useState([])
  const[lead_levels,setlead_levels]=useState([])

  const user=JSON.parse(sessionStorage.getItem('user'))

  useEffect(()=>{

    axios(`${ipaddress}/States/`)
    .then((r)=>{
      console.log("States successfully fetched",r.data)
      setstates(r.data)
    })
    .catch((err)=>{
      console.log("States fetching Error",err)
    })

    axios(`${ipaddress}/AllcoursesnameView/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setAvailablecourses(r.data.coursename)
    })
    .catch((err)=>{
      console.log("Courses fetching Error",err)
    })

    axios(`${ipaddress}/Allleadlevels/`)
    .then((r)=>{
      console.log("Lead levels successfully fetched",r.data)
      setlead_levels(r.data.leadlevels)
    })
    .catch((err)=>{
      console.log("Lead levels fetching Error",err)
    })
  },[])


  const sourcedata=(e)=>{
    if(e.target.value==="walkin"){
      document.getElementById('contactedpersondiv').style.display="block"
    }
    if(e.target.value==="other"){
      document.getElementById('othersource').style.display="block"
    }
  }

  const fetchcities=(state)=>{
    axios(`${ipaddress}/Cities/${state}/`)
    .then((r)=>{
      console.log("Cities successfully fetched",r.data)
      setcities(r.data)
    })
    .catch((err)=>{
      console.log("Cities fetching Error",err)
    })
  }

  const[reason_for_contact,setreason_for_contact]=useState('Training')

  const [formData, setFormData] = useState({
    emp_id:'',
    name: '',
    email: '',
    contact_number: '',
    company_name:'',
    company_website:'',
    company_field:'',
    company_contact_number:'',
    gst_number:'',
    door_number:'',
    street_name:'',
    area:'',
    state:'',
    city:'',
    pincode:'',
    purpose:'',
    num_candidates_trained:0,
    place_of_training:'',
    preferred_duration_per_day:0,
    syllabus:'',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name:'',
    contacted_person_number:'',
    priority: '',
    level_lead: 'newlead',
    preferred_course:'',
    preferred_batch_type:'',
    preferred_batch_start_time:'',
    preferred_class_type:'',
    expected_registration_date:'',
    tentative_batch_start_date:'',
    assigned_to:'',
    mode_of_training:'',
    cost:'',
    reason_for_contact:'',
    othercourseenquired:'',
    service:[]
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevState) => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const[course,setcourse]=useState('')

const[loading,setloading]=useState(false)

  const senddetails=(e)=>{
    e.preventDefault()
    setloading(true)
    formData.emp_id=user.emp_id
    formData.reason_for_contact=reason_for_contact
    formData.email=mail

    if(value==='client'){
      formData.mode_of_training=null
      formData.service=service_id
    }

    axios.post(`${ipaddress}/ClientLeadsView/`,formData)
    .then((r)=>{
      console.log("Client successfully added",r.data)
      toast.success('Lead successfully added',{
        autoClose: 2000,
        theme:'colored',
      position:'top-center'
      })
      setloading(false)
      setTimeout(()=>{
        navigate('/leads')
      },3000)
    })
    .catch((err)=>{
      console.log("Client adding error",err)
      console.log(formData)
      setloading(false)
    })
  }
  
  const[formstate,setformstate]=useState(false)
  const[otpstate,setotpstate]=useState(false)
  const[backendotp,setbackendotp]=useState('')
  const[otp,setotp]=useState('')

  const sendotp=()=>{
    // e.preventDefault()
    axios.post(`${ipaddress}/send-otp/`,{
      email:formData.email
    })
    .then((r)=>{
      console.log("Otp sent suceessfully",r.data)
      setbackendotp(r.data.otp)
    })
    .catch((err)=>{
      console.log("Otp sending error",err)
    })
  }

  const verifyotp=()=>{
    console.log(otp,backendotp)
    if(otp===backendotp){
      toast.success('OTP verified successfully',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
      setotpstate(false)
      setformstate(true)
      setotp('')
    }
    else{
      toast.error('Invalid OTP',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  }

    // Phone number verification
    const[load2,setload2]=useState()
    const[message2,setmessage2]=useState()
  
    const verify_phone=(phone)=>{
      if(phone.length==10){
        setload2(true)
        setTimeout(() => {
          verify2(phone)
        }, 1000);
      }
    }
  
    const verify2=(phone)=>{
      axios.post(`${ipaddress}/MobileNumberValidation/`,{
        mobile_number:phone,
        customer:'Client'
      })
      .then((r)=>{
        console.log("Phone number verified",r.data);
        setload2(false)
          setmessage2(true)
          formData.contact_number=phone
      })
      .catch((err)=>{
        setmessage2(false)
        setload2(false)
      })
    }
  
  
    // Email verification
    const[load,setload]=useState()
    const[message,setmessage]=useState()

    const[mail,setmail]=useState('')

    const handle_email = (email) => {
      const atIndex = email.indexOf('@');
    
      if (atIndex !== -1 && email.length > atIndex + 1) {
        verify_email(email);
      } else {
        setload(false);
      }
    };

    const verify_email=(email)=>{
      // console.log("Mail",email);
      
      if(email.length>0){
        setload(true)
        setTimeout(() => {
          verify(email)
        }, 1000);
      }
    }
  
    const verify=(email)=>{
      axios.get(`${ipaddress}/EmailCheck/${email}/`)
      .then((r)=>{
        console.log("Email verified",r.data);
        setload(false)
  
        if(r.data.message==='mail_notexist_customerlead'){
          setmessage(true)
          setmail(email)
        }
        else{
          setmessage(false)
        }
      })
    }

    const[classtypes,setclasstypes]=useState([])
    const[batchtypes,setbatchtypes]=useState([])
    const[services,setservices]=useState([])
  
    useEffect(()=>{
  
      axios.get(`${ipaddress}/Allclasstypes/`)
      .then((r)=>{
        console.log("Class types",r.data)
        setclasstypes(r.data.classtypes)
      })
  
      axios.get(`${ipaddress}/Allbatchtypes/`)
      .then((r)=>{
        console.log("Batch types",r.data)
        setbatchtypes(r.data.batchtypes)
      })

      axios.get(`${ipaddress}/ServicesAvailable/`)
      .then((r)=>{
        console.log("Services",r.data)
        setservices(r.data)
      })
    },[])

    const[value,setValue]=useState('customer')

    const[service_id,setservice_id]=useState([])

    const service_data=(value)=>{
      const data=services.filter(x=>x.service_name===value)
      setservice_id([...service_id,Number(data[0].id)])
    }

return (
    <div className=' bg-light' style={{minHeight:'100vh'}}>
        <div className='container-fluid row m-0 pb-4 pt-3 animate__animated animate__fadeIn'>
          <div className='mb-2 d-flex'>
         <Link className='text-dark' to='/leads'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></Link> 
            <h5 className='text-success mx-auto'>CLIENT REGISTRATION FORM</h5>
          </div>
            <div className="col-12 bg-white py-3">
            <form className="" onSubmit={senddetails}>
{/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
                <div className="row m-0 border-bottom pb-2">
                <h6 className='text-primary'>Personal Details</h6>
                <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputEmail4" className="form-label">Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" name='name' value={formData.name} onChange={handleInputChange}/>
  </div>

  <div className='col-md-6 col-lg-4 mb-3'>
  <label for="inputPassword4" className="form-label">Contact Number <span className='required-field'>*</span></label>
<div class="input-group rounded bg-light border">
    <input type="tel" maxLength={10} className="form-control shadow-none bg-light py-2 border-0 bg-transparent" id="inputPassword4" name='contact_number' onChange={(e)=>{
      verify_phone(e.target.value)
    }}/>
  <span class="input-group-text border-0 bg-transparent" id="basic-addon2">
  <div class={`spinner-border spinner-border-sm text-success ${load2 ? '':'d-none'}`} role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198754" class={`bi bi-check2-circle ${!load2 && message2 ==true ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
  </span>
</div>
<p className={`text-danger mt-1 ${message2==false ? '':'d-none'}`} style={{fontSize:'13px'}}>*Mobile Number already exist</p>
</div>

<div className='col-md-6 col-lg-4 mb-3'>
<label for="inputPassword4" className="form-label">Email<span className='required-field'>*</span></label>
<div class="input-group rounded bg-light border">
    <input type="email" className="form-control shadow-none bg-light py-2 border-0 bg-transparent" id="inputPassword4" name='email' onChange={(e)=>{
      handle_email(e.target.value)
    }}/>
  <span class="input-group-text border-0 bg-transparent" id="basic-addon2">
  <div class={`spinner-border spinner-border-sm text-success ${load ? '':'d-none'}`} role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198754" class={`bi bi-check2-circle ${!load && message==true ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg>
  </span>
</div>
<p className={`text-danger mt-1 ${message==false ? '':'d-none'}`} style={{fontSize:'13px'}}>*Email already exist</p>
</div>
                </div>

<div>
  {/* -----------------------------------COMPANY DETAILS------------------------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Company Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Company Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2 py-2" id="inputEmail4" value={formData.company_name} name='company_name' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Company Website <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2 py-2" id="inputPassword4" value={formData.company_website} name='company_website' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Company Field <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2 py-2" id="inputEmail4" value={formData.company_field} name='company_field' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Company Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" className="form-control shadow-none bg-light py-2 py-2" maxLength={10} id="inputPassword4" value={formData.company_contact_number} name='company_contact_number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputPassword4" className="form-label">GST Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2 py-2" id="inputPassword4" value={formData.gst_number} name='gst_number' onChange={handleInputChange}/>
  </div>
                </div>

{/* --------------------------------------COMPANY ADDRESS----------------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Company Address</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Door Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" value={formData.door_number} name='door_number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Street Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" maxLength={10} value={formData.street_name} name='street_name' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Area</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.area} name='area' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">State</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" value={formData.state} name='state' onChange={(e)=>{
        fetchcities(e.target.value)
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
    }}>
      <option selected>Choose...</option>
      {states.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">City</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" value={formData.city} name='city' onChange={handleInputChange}>
      <option selected>Choose...</option>
      {cities.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputPassword4" className="form-label">Pincode</label>
    <input type="tel" className="form-control shadow-none bg-light py-2" maxLength={6} id="inputPassword4" value={formData.pincode} name='pincode' onChange={handleInputChange}/>
  </div>
                </div>


{/* ----------COURSE DETAILS----------------------- */}
<div className={`row m-0 border-bottom py-3`}>
                <h6 className='text-primary'>Course Details</h6>
  <div className={`col-md-6 col-lg-4 mb-3 ${value==='customer' ? '':'d-none'}`}>
  <label for="inputState" className="form-label shadow-none">Course Enquired <span className='required-field'>*</span></label>
  <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.othercourseenquired} name='othercourseenquired' onChange={handleInputChange}/>
  </div>
  <div className={`col-md-6 col-lg-4 mb-3 ${value==='customer' ? '':'d-none'}`}>
  <label for="inputPassword4" className="form-label">Enquiry Location <span className='required-field'>*</span></label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.enquiry_location} name='enquiry_location' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Source <span className='required-field'>*</span></label>
    <select id="source" className="form-select shadow-none bg-light py-2" value={formData.source} name='source' onChange={(e)=>{
      if(e.target.value==="walkin"){
        document.getElementById('contactedpersondiv').style.display="block"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
      else if(e.target.value==="other"){
        document.getElementById('other-source').style.display="block"
        document.getElementById('contactedpersondiv').style.display="none"
      }
       else{
        document.getElementById('contactedpersondiv').style.display="none"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
    }}>
      <option selected>Choose...</option>
      <option value="other">Other</option>
      <option value="walkin">Walkin</option>
      <option value="naukri">Naukri</option>
      <option value="linkedin">Linkedin</option>
      <option value="just dial">Just Dial</option>
      <option value="whatsapp">Whatsapp</option>
      <option value="facebook">Facebook</option>
      <option value="instagram">Instagram</option>
    </select>
  </div>
  <div>
  
  <div className='' style={{display:'none'}} id='contactedpersondiv'>
  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_name} name='contacted_person_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_number} name='contacted_person_number'/>
  </div>
  </div>

{/* Other source */}
<div className="col-md-6 col-lg-4 mb-3" id='other-source' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter the source</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.source} name='source' onChange={handleInputChange}/>
  </div>
  </div>
                </div>

{/* --------------------------------LEAD DETAILS-------------------- */}
<div className={`row m-0 border-bottom py-3 ${value==='customer' ? '':'d-none'}`}>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Priority</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" name="priority" value={formData.priority} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Level of Lead <span className='required-field'>*</span></label>
    <input id="inputState" disabled='true' className="form-control shadow-none bg-light py-2" name="level_lead" value={formData.level_lead} onChange={handleInputChange}/>
  </div>
</div>

<div className="border-bottom py-3">
<h6 className='text-primary'>Purpose</h6>

<div className='py-3 pb-4'>
    <p onClick={()=>{
        setValue("customer")
        setreason_for_contact('Training')
    }} className={`btn btn-sm fw-medium py-2 px-3 m-0`} style={{borderRadius:'6px 0px 0px 6px',fontSize:'14px',backgroundColor:value==='customer' ? '#57309C':'#ffff',color:value==='customer' ? '#ffff':'#57309C',border:'1px solid #57309C',cursor:'pointer'}}>Course Training</p>
    <p onClick={()=>{
        setValue("client")
        setreason_for_contact('Software development')
    }} className={`btn btn-sm fw-medium py-2 px-3 m-0`} style={{borderRadius:'0px 6px 6px 0px',fontSize:'14px',backgroundColor:value==='client' ? '#57309C':'#ffff',color:value==='client' ? '#ffff':'#57309C',border:'1px solid #57309C',cursor:'pointer'}}>Software development & Digital marketing services</p>

</div>

{/* Training */}
 <div className={`row m-0 ${value==='customer' ? '':'d-none'}`}>
 <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Purpose <span className='required-field'>*</span></label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.purpose} name='purpose' onChange={handleInputChange}/>
  </div>
 <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Number of candidates <span className='required-field'>*</span></label>
    <input type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.num_candidates_trained} name='num_candidates_trained' onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Place of training <span className='required-field'>*</span></label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.place_of_training} name='place_of_training' onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Preffered duration per day <span className='required-field'>*</span></label>
    <input type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.preferred_duration_per_day} name='preferred_duration_per_day' onChange={handleInputChange}/>
  </div>
  
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Syllabus <span className='required-field'>*</span></label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" name="syllabus" value={formData.syllabus} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>Our syllabus</option>
      <option>Client Syllabus</option>
    </select>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
    <label htmlFor="">Preferred Batch Type <span className='text-danger'>*</span></label>
  <select id="" className='form-select py-2 shadow-none mt-2 bg-light' value={formData.preferred_batch_type} onChange={handleInputChange} name='preferred_batch_type'>
    <option value="">Choose...</option>
    {batchtypes.map((x)=>{
      return(
        <>
        <option value={x}>{x}</option>
        </>
      )
    })}
  </select>
                </div>
  
  <div className="col-md-6 col-lg-4 mb-3 mt-md-1">
    <label htmlFor="">Mode of Training <span className='text-danger'>*</span></label>
                <select id="" className='form-select py-2 shadow-none bg-light mt-2' onChange={handleInputChange} value={formData.mode_of_training} name='mode_of_training'>
    <option value="">Choose...</option>
    {classtypes.map((c)=>{
      return(
        <>
        <option value={c}>{c}</option>
        </>
      )
    })}
  </select>
                </div>
 </div>

 {/* Software development */}
 <div className={`row m-0 ${value==='client' ? '':'d-none'}`}>
 <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Purpose<span className='required-field'>*</span></label>
    <select type="text" className="form-select shadow-none bg-light py-2" id="inputPassword4" value={formData.purpose} name='purpose' onChange={(e)=>{
      const {name,value}=e.target
      setFormData((prev)=>({
        ...prev,
        [name]:value
      }))
      service_data(e.target.value)
    }}>
      <option value="">Choose...</option>
      {services.map((x)=>{
        return(
          <>
          <option value={x.service_name}>{x.service_name}</option>
          </>
        )
      })}
    </select>
  </div>

  {/* <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Actual Cost <span className='required-field'>*</span></label>
    <input type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={actual_cost} name='actual_cost' disabled='true'/>
  </div> */}

 <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Cost <span className='required-field'>*</span></label>
    <input type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.cost} name='cost' onChange={handleInputChange}/>
  </div>
 </div>
</div>
  <div className="col-12 text-end mt-3">
    <button type="submit" className="btn btn-primary text-white fw-medium px-2 px-lg-5">
    <span class={`spinner-border spinner-border-sm ${loading ? '':'d-none'}`} aria-hidden="true"></span>
    <span className={`${loading ? 'ms-2':'d-none'}`} role="status">Submitting...</span>
    <span className={`${loading ? 'd-none':''}`}>Submit</span>
    </button>
  </div>
</div>
</form>
            </div>
        </div>


    </div>
  )
}

export default Customerregistrationform