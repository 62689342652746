import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'
import axios from 'axios'

const Edit_customer_lead = () => {

  const user=JSON.parse(sessionStorage.getItem('user'))

  const {id}=useParams()

  const[availablecourses,setAvailablecourses]=useState([])
  const[lead_levels,setlead_levels]=useState([])

  const[bach_perc,setbach_perc]=useState(true)
  const[mast_perc,setmast_perc]=useState(true)

  useEffect(()=>{
    axios.get(`${ipaddress}/AllcoursesnameView/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setAvailablecourses(r.data.coursename)
    })
    .catch((err)=>{
      console.log("Courses fetching Error",err)
    })

    axios.get(`${ipaddress}/Allleadlevels/`)
    .then((r)=>{
      console.log("Lead levels successfully fetched",r.data)
      setlead_levels(r.data.leadlevels)
    })
    .catch((err)=>{
      console.log("Lead levels fetching Error",err)
    })

    axios.get(`${ipaddress}/LeadDetailsView/${id}/`)
    .then((r)=>{
      console.log("Lead details successfully fetched",r.data)
      setFormData(r.data)
    })
    .catch((err)=>{
      console.log("Lead details fetching Error",err)
    })
  },[])


  const sourcedata=(e)=>{
    if(e.target.value==="walkin"){
      document.getElementById('contactedpersondiv').style.display="block"
    }
    if(e.target.value==="other"){
      document.getElementById('othersource').style.display="block"
    }
  }

  const [formData, setFormData] = useState({
    emp_id:'',
    name: '',
    email: '',
    contact_number: '',
    gender: '',
    bachelor_degree: '',
    bachelor_specification : '',
    percentage_bachelor : '',
    master_degree: '',
    master_specification: '',
    percentage_master: '',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name:'',
    contacted_person_number:'',
    technical_skills_known: [],
    soft_skills_known: [],
    priority: '',
    level_lead: '',
    preferred_course:'',
    preferred_batch_type:'',
    preferred_batch_start_time:'',
    preferred_class_type:'',
    expected_registration_date:'',
    tentative_batch_start_date:'',
    created_at:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevState) => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const navigate=useNavigate()

  const senddetails=(e)=>{
    e.preventDefault()

    formData.emp_id=user.emp_id
    axios.put(`${ipaddress}/CustomerLeadsView/${id}/`,formData)
    .then((r)=>{
      console.log("Successfully updated",r.data)
      console.log(formData)
      toast.success('Successfully updated',{
        autoClose: 2000,
        theme:'colored',
      position:'top-center'
      })

      setTimeout(()=>{
        navigate('/customerleads')
      },4000)
      
    })
    .catch((err)=>{
      console.log("Lead updating error",err)
      console.log(formData)
    })
  }
  

return (
    <div className='animate__animated animate__slideInUp bg-light'>
        <div className='container-fluid row m-0 pb-4 pt-3'>
          <div className='mb-2 d-flex'>
         <Link className='text-dark' to='/customerleads'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></Link> 
            <h5 className='text-success mx-auto'>EDIT CUSTOMER LEAD</h5>
          </div>
            <div className="col-12 bg-white py-3 shadow">
            <form className="" onSubmit={senddetails}>
{/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
                <div className="row m-0 border-bottom pb-2">
                <h6 className='text-primary'>Personal Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" name='name' value={formData.name} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Email <span className='required-field'>*</span></label>
    <input required type="email" className="form-control shadow-none bg-light py-2" id="inputPassword4" name='email' value={formData.email} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" className="form-control shadow-none bg-light py-2" maxLength={10} id="inputPassword4" name='contact_number' value={formData.contact_number} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Gender <span className='required-field'>*</span></label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name='gender' value={formData.gender} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>Male</option>
      <option>Female</option>
      <option>Transgender</option>
    </select>
  </div>
                </div>

{/* ----------------------------------EDUCATIONAL DETAILS----------------------------------------------------------- */}
<div className="row m-0 py-3">
                <h6 className='text-primary'>Educational Details</h6>
                
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Bachelor Degree </label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name='bachelor_degree' value={formData.bachelor_degree} onChange={(e)=>{
      setbach_perc(false)
      const {name,value}=e.target

      setFormData((prev)=>({
        ...prev,
        [name]:value
      }))
    }}>
      <option selected>Choose...</option>
      <option>B.Tech</option>
    </select> 
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Bachelor Specification</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      if(e.target.value==="other"){
      document.getElementById('bachother').style.display="block"
      }
      else{
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      document.getElementById('bachother').style.display="none"
      }
    }}>
      <option selected>Choose...</option>
      <option value="other">Other</option>
      <option>Software</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Percentage Achieved</label>
    <input disabled={bach_perc} required type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.percentage_bachelor} name='percentage_bachelor' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3" id='bachother' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter your Specification</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.bachelor_specification} name='bachelor_specification' onChange={handleInputChange}/>
  </div>

                </div>
                <div className="row m-0 border-bottom pb-3">

  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Master Degree</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      setmast_perc(false)
      const {name,value}=e.target

      setFormData((prev)=>({
        ...prev,
        [name]:value
      }))
    }}>
      <option selected>Choose...</option>
      <option>M.Tech</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Master Specification</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      if(e.target.value==="other"){
      document.getElementById('masterother').style.display="block"
      }
      else{
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      document.getElementById('masterother').style.display="none"
      }
    }}>
      <option selected>Choose...</option>
      <option value="other">Other</option>
      <option value="software">Sofware</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Percentage Achieved</label>
    <input required type="number" disabled={mast_perc} className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.percentage_master} name='percentage_master' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3" id='masterother' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter your Specification</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.master_specification} name='master_specification' onChange={handleInputChange}/>
  </div>
                </div>


{/* ----------COURSE DETAILS----------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Course Details</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Course Enquired <span className='required-field'>*</span></label>
       <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.course_enquired} name='course_enquired' onChange={handleInputChange}/>

  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Enquiry Location <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.enquiry_location} name='enquiry_location' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Source <span className='required-field'>*</span></label>
    <select required id="source" className="form-select shadow-none bg-light py-2" value={formData.source} name='source' onChange={(e)=>{
      if(e.target.value==="walkin"){
        document.getElementById('contactedpersondiv').style.display="block"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
      else if(e.target.value==="other"){
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
        document.getElementById('other-source').style.display="block"
        document.getElementById('contactedpersondiv').style.display="none"
      }
       else{
        document.getElementById('contactedpersondiv').style.display="none"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
    }}>
      <option value="none" selected>Choose...</option>
      <option value="other">Other</option>
      <option value="walkin">Walkin</option>
      <option value="naukri">Naukri</option>
      <option value="linkedin">Linkedin</option>
      <option value="just dial">Just Dial</option>
      <option value="whatsapp">Whatsapp</option>
      <option value="facebook">Facebook</option>
      <option value="instagram">Instagram</option>
    </select>
  </div>
  
  <div style={{display:'none'}} id='contactedpersondiv'>
  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_name} name='contacted_person_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_number} name='contacted_person_number'/>
  </div>
  </div>
{/* Other source */}
<div className="col-md-6 col-lg-4 mb-3" id='other-source' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter the source</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.source} name='source' onChange={handleInputChange}/>
  </div>
                </div>

{/* --------------------------------TECHNOLOGIES KNOWN-------------------- */}
<div className="row m-0 border-bottom py-3">
  <div className="col-12 mb-3">
  <span className='text-primary fw-medium me-4'>Technical Skills Known : </span>
    <input type="checkbox" id="java" name="technical_skills_known" value="java" onChange={handleCheckboxChange}/>
  <label for="java" className="form-label shadow-none ms-1">Java</label>
  <input type="checkbox" className='ms-2 ms-lg-3' name="technical_skills_known" value="python" onChange={handleCheckboxChange} id="python" />
  <label for="python" className="form-label shadow-none ms-1">Python</label>
  <input type="checkbox" className='ms-2 ms-lg-3' id="React Js" name="technical_skills_known" value="react_js" onChange={handleCheckboxChange}/>
  <label for="React Js" className="form-label shadow-none ms-1">React Js</label>
  <input type="checkbox" className='ms-2 ms-lg-3' id="Javascript" name="technical_skills_known" value="javascript" onChange={handleCheckboxChange}/>
  <label for="Javascript" className="form-label shadow-none ms-1">Javascript</label>
  </div>
  <div className="col-12 mb-3">
  <span className='text-primary fw-medium me-5'>Soft Skills Known : </span>
    <input type="checkbox" name="soft_skills_known" value="communication" onChange={handleCheckboxChange} id="java" />
  <label for="java" className="form-label shadow-none ms-1">Communication</label>
  <input type="checkbox" className='ms-2 ms-lg-3' name="soft_skills_known" value="aptitude" onChange={handleCheckboxChange} id="python" />
  <label for="python" className="form-label shadow-none ms-1">Aptitude</label>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Priority</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name="priority" value={formData.priority} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Level of Lead <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.enquiry_location} name='enquiry_location' onChange={handleInputChange}/>
  
  </div>
</div>

{/* --------------------------------Additional Details------------------------------ */}
{/* <div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Batch Details</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Preffered Course</label>
    <select required id="inputState" className="form-select shadow-none bg-light" name="preferred_course" value={formData.preferred_course} onChange={handleInputChange}>
      <option selected>Choose...</option>
      {availablecourses.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Preffered Batch Type</label>
    <select required id="inputState" className="form-select shadow-none bg-light" name="preferred_batch_type" value={formData.preferred_batch_type} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option value='weekend'>Weekend</option>
      <option value='weekdays'>Weekdays</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Preferred Class Type</label>
    <select required id="source" className="form-select shadow-none bg-light" name="preferred_class_type" value={formData.preferred_class_type} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option value="online">Online</option>
      <option value="offline">Offline</option>
      <option value="hybrid">Hybrid</option>
    </select>
  </div>

  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Preferred Batch Start Time</label>
    <input required type="time" className="form-control shadow-none bg-light" id="inputPassword4" name="preferred_batch_start_time" value={formData.preferred_batch_start_time} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Expected Registration date</label>
    <input required type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="expected_registration_date" value={formData.expected_registration_date} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Tentative Batch start date</label>
    <input required type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="tentative_batch_start_date" value={formData.tentative_batch_start_date} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3 mt-2">
    <label for="inputPassword4" className="form-label">Created at</label>
    <input required type="date" className="form-control shadow-none bg-light" id="inputPassword4" name="created_at" value={formData.created_at} onChange={handleInputChange}/>
  </div>
  
                </div> */}

  <div className="col-12 text-end mt-3">
    <button type="submit" className="btn btn-primary text-white fw-medium px-2 px-lg-5">Submit</button>
  </div>
</form>
            </div>
        </div>
    </div>
  )
}

export default Edit_customer_lead