import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Slider from 'react-slick'
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar, Doughnut, Pie } from 'react-chartjs-2'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress, ipaddress2, role } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import Add_activity_targets from './Add_activity_targets'

const Employee_profile = () => {

  const {id}=useParams()

  const user=JSON.parse(sessionStorage.getItem('user'))

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      const options = {
        plugins: {
          legend: {
            position: 'bottom', // Set legend position to bottom
            labels: {
              boxWidth: 20, 
              maxWidth: 350, // Adjust this value as needed
            },
          },
        },
        // Other chart options
      };

      const options2 = {
        plugins: {
          legend: {
            position: 'bottom', // Set legend position to bottom
            labels: {
              boxWidth: 20,
              maxWidth: 350, // Adjust this value as needed
              generateLabels: () => [], // Return an empty array to remove labels
            },
          },
        },
        // Other chart options
      };

      const navigate=useNavigate()

      const[title,settitle]=useState([])
const[targets,settargets]=useState([])
const[achieved,setachieved]=useState([])

useEffect(()=>{
  axios.get(`${ipaddress2}/employee/${id}/activities/`)
.then((r)=>{
  console.log("Activities graph",r.data.datasets)
  const data=r.data.datasets

  const titles = data.map(item => item.activity_name);
  const totalMarks = data.map(item => item.Target);
  const marksAchieved = data.map(item => item.achieved);
  settitle(titles)
  settargets(totalMarks)
  setachieved(marksAchieved)
})
},[])

      const employeecomparision = {
        labels: title,
        datasets: [
          {
            label: 'Target',
            data: targets,
            fill: false,
            backgroundColor: '#1814F3',
            tension: 0.1,
            barThickness: 8,
          },
          {
            label: 'Achieved',
            data: achieved,
            fill: false,
            backgroundColor: '#74C228',
            tension: 0.1,
            barThickness: 8,
          },
        ],
      };
      

      const[emp_data,setemp_data]=useState({})
      const[modal_name,setmodal_name]=useState()
      const[activity_name,setactivity_name]=useState("")
      const[target_count,settarget_count]=useState(0)

      const fetchemp_data=()=>{
        axios.get(`${ipaddress}/MyprofileView/${id}/`)
        .then((r)=>{
          console.log("Employee data found",r.data)
          setemp_data(r.data)
        })
        .catch((err)=>{
          console.log("Employee data fetching error",err)
        })
      }

      const[modals,setmodals]=useState([])
      const[activities,setactivities]=useState([])

  useEffect(()=>{
    fetchemp_data()

    axios.get(`${ipaddress}/AllModules/`)
    .then((r)=>{
      console.log("Module Names",r.data)
      setmodals(r.data)
    })

  },[id])

  const block_trainer=(status)=>{
    axios.post(`${ipaddress2}/BlockTrainer/${emp_data.email}/`,{
      email:emp_data.email,
      status:status
    })
  
    .then((r)=>{
      fetchemp_data()
      if(status==true){
        toast.warn(`${emp_data.firstname} has been inactivated`,{
          autoClose:2000,
          theme:'colored',
        position:'top-center'
        })
      }
      else{
        toast.success(`${emp_data.firstname} has been activated`,{
          autoClose:2000,
          theme:'colored',
        position:'top-center'
        })
      }
    })
  }

// To assign the Target count

const assigntarget=(value)=>{
  axios.post(`${ipaddress}/ActivityView/${user.emp_id}/`,{
    module_name:modal_name,
    EmployeeId:[id],
    login_user:user.emp_id,
    Activity_data:fields
  })
  .then((r)=>{
    console.log("Activity Assigned",r.data)
    toast.success('Activity Assigned Successfully',{
      autoClose:1000,
      theme:'colored',
      position:'top-center'
    })
    setTimeout(() => {
      window.location.reload()
    }, 2000);
setFields([{Activity_Name: '', targets: 0 }])
setmodal_name('')
  })

  .catch((err)=>{
    console.log("Assigning activity error",err)
    console.log({
      emp_id:[id],
      login_user:user.emp_id,
      Activity_data:fields,
      module_name:modal_name
    })
  })
}


const[delete_customer_lead,setdelete_customer_lead]=useState(false)
const[delete_client_lead,setdelete_client_lead]=useState(false)
const[assign_activity,setassign_activity]=useState(false)
const[assign_students,setassign_students]=useState(false)
const[view_all_employees,setview_all_employees]=useState(false)
const[view_newleads,setview_newleads]=useState(false)
const[assign_leads,setassign_leads]=useState(false)
const[create_projects,setcreate_projects]=useState(false)
const[add_events,setadd_events]=useState(false)
const[add_activity_module,setadd_activity_module]=useState(false)
const[batch_permission,setbatch_permission]=useState(false)


const[selecetdemp,setselectedemp]=useState("")

  // const handleCheckboxChange = (e) => {
  //   const {name}=e.target

  //   if (e.target.checked) {
  //     setSelectedpermissions((prev)=>({
  //       ...prev,
  //       [name]:'True'
  //     }));
  //   } 
  //   else {
  //    setSelectedpermissions((prev)=>({
  //       ...prev,
  //       [name]:'False'
  //     }));
  //   }
  // };

  const assign_permissions=()=>{
    axios.post(`${ipaddress2}/PermissionsView/${user.emp_id}/`,{
      'employee':id,
      'delete_customer_lead':delete_customer_lead,
      'delete_client_lead':delete_client_lead,
      'assign_activity':assign_activity,
      'view_all_employees':view_all_employees,
      'assign_leads':assign_leads,
      'add_events':add_events,
      'create_projects':create_projects,
      'assign_students':assign_students,
      'view_newleads':view_newleads,
      'add_activity_module':add_activity_module,
      'batch_permission':batch_permission
    })
    .then((r)=>{
      console.log("Permissions assigned",r.data)
      toast.success(`Permissions assigned successfully`,{
        autoClose:1000,
        theme:'colored',
      position:'top-center'
      })
      
      console.log({
        'delete_customer_lead':delete_customer_lead,
      'delete_client_lead':delete_client_lead,
      'assign_activity':assign_activity,
      'view_all_employees':view_all_employees,
      'add_events':add_events,
      'create_projects':create_projects
      })
      setcount(count+1)
    })
    .catch((err)=>{
      console.log({
        'delete_customer_lead':delete_customer_lead,
      'delete_client_lead':delete_client_lead,
      'assign_activity':assign_activity,
      'view_all_employees':view_all_employees
      })
      console.log(err)
    })
  }

    // To view the assigned permissions

    const[count,setcount]=useState(0)
const[permissions,setpermissions]=useState({})

useEffect(()=>{
  if(user.designation==='admin'){
    axios.get(`${ipaddress}/PermissionsView/${id}/`)
    .then((r)=>{
      console.log("Permissions",r.data)
      setpermissions(r.data)
      setdelete_customer_lead(r.data.delete_customer_lead)
      setdelete_client_lead(r.data.delete_client_lead)
      setassign_activity(r.data.assign_activity)
      setview_all_employees(r.data.view_all_employees)
      setassign_leads(r.data.assign_leads)
      setcreate_projects(r.data.create_projects)
      setadd_events(r.data.add_events)
      setassign_students(r.data.assign_students)
      setview_newleads(r.data.view_newleads)
      setadd_activity_module(r.data.add_activity_module)
      setbatch_permission(r.data.batch_permission)

      console.log("ADMINNNN")
    })
  }
  else{
    axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`)
    .then((r)=>{
      console.log("Permissions",r.data)
      setpermissions(r.data)
      setdelete_customer_lead(r.data.delete_customer_lead)
      setdelete_client_lead(r.data.delete_client_lead)
      setassign_activity(r.data.assign_activity)
      setview_all_employees(r.data.view_all_employees)
      setassign_leads(r.data.assign_leads)
      setcreate_projects(r.data.create_projects)
      setadd_events(r.data.add_events)
      setassign_students(r.data.assign_students)
      setview_newleads(r.data.view_newleads)
      setadd_activity_module(r.data.add_activity_module)
      setbatch_permission(r.data.batch_permission)
      console.log("EMPLOYEEEE")

    })
  }
},[count])

const [fields, setFields] = useState([{Activity_Name: '', targets: 0 }]);

const handleAddMore = () => {
  setFields([...fields, {Activity_Name: '', targets: 0 }]);
};

const handleChange = (index, e) => {
  const { name, value } = e.target;
  const newFields = [...fields];
  newFields[index][name] = value;
  setFields(newFields);
};

// Filter for chart

const[filter_type,setfilter_type]=useState('')
const[start_date,setstart_date]=useState('')
const[end_date,setend_date]=useState('')

const filter_chart=(value,end_time)=>{
  axios.get(`${ipaddress2}/employee/${id}/activities/`)
.then((r)=>{
  console.log("Filter Activities graph",r.data.datasets)
  const data=r.data.datasets

  const titles = data.map(item => item.activity_name);
  const totalMarks = data.map(item => item.Target);
  const marksAchieved = data.map(item => item.achieved);
  settitle(titles)
  settargets(totalMarks)
  setachieved(marksAchieved)
})
}

// Charts for leads
const[sourcedata,setsourcedata]=useState([])
const[sourcecount,setsourcecount]=useState([])

const[coursedata,setcoursedata]=useState([])
const[emp_compdata,setemp_compdata]=useState([])

useEffect(()=>{
  axios.get(`${ipaddress}/Leads_chart_by_course/${id}/`)
  .then((r)=>{
    console.log("Course based leads",r.data.datasets)
    setcoursedata(r.data.datasets)
  })

  axios.get(`${ipaddress}/Leads_chart_by_source_bargraph/${user.emp_id}/`)
    .then((r)=>{
      console.log("Sourse based leads",r.data)
        setsourcedata(r.data.datasets);
    })
},[])

const leadsbycourse = {
  labels: coursedata.map(x=>x.label),
  datasets: [
    {
      label: 'New Leads',
      data: coursedata.map(item => item.data[0]),
      fill: false,
      backgroundColor: 'rgb(27,98,169)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Followups',
      data: coursedata.map(item => item.data[1]),
      fill: false,
      backgroundColor: 'rgb(255,173,51)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Prospects',
      data: coursedata.map(item => item.data[2]),
      fill: false,
      backgroundColor: 'rgb(255,76,76)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Regsitered',
      data: coursedata.map(item => item.data[3]),
      fill: true,
      backgroundColor: 'rgb(72,193,255)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Closed',
      data: coursedata.map(item => item.data[4]),
      fill: false,
      backgroundColor: 'rgb(173,173,133)',
      tension: 0.1,
      barThickness: 8,
    },
  ],
};

const leadsbysource = {
  labels: sourcedata.map(x=>x.label),
  datasets: [
    {
      label: 'New Leads',
      data: sourcedata.map(item => item.data[0]),
      fill: false,
      backgroundColor: 'rgb(27,98,169)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Followups',
      data: sourcedata.map(item => item.data[1]),
      fill: false,
      backgroundColor: 'rgb(255,173,51)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Prospects',
      data: sourcedata.map(item => item.data[2]),
      fill: false,
      backgroundColor: 'rgb(255,76,76)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Regsitered',
      data: sourcedata.map(item => item.data[3]),
      fill: true,
      backgroundColor: 'rgb(72,193,255)',
      tension: 0.1,
      barThickness: 8,
    },
    {
      label: 'Closed',
      data: sourcedata.map(item => item.data[4]),
      fill: false,
      backgroundColor: 'rgb(173,173,133)',
      tension: 0.1,
      barThickness: 8,
    },
  ],
};

const[show,setshow]=useState(false)
const[show2,setshow2]=useState(false)

return (
    <div className='d-flex'>
    <Sidebar  activevalue={"employees"}/>
    <div className='w-100 main-div'>
        <Navbar pagename={"Employees"}/>
        <div className="bg-light py-3 pt-4 main-container" style={{minHeight:'100vh'}}>
          <div className='container row m-0 d-flex justify-content-between pb-2'>
            <div className="col-md-8">
            <h5 className=''>{emp_data.firstname}'s Profile</h5>
            </div>
          <div className='col-md-4 d-flex align-items-center justify-content-end' style={{}}>
           <button onClick={()=>{
            navigate(`/view_employee_activity/${id}`)
           }} className={`btn btn-sm text-white px-3 me-3`} style={{backgroundColor:'#57309C',borderRadius:'15px'}}>View Activity</button>
          
            <button className={`btn btn-sm text-white px-3 ${permissions.assign_activity || user.designation==='admin' || user.designation===role ? '':'d-none'}`} style={{backgroundColor:'#57309C',borderRadius:'15px'}} onClick={()=>{
            setshow(true)
           }}>Add Target</button>
            {/* <button className={`btn btn-sm text-white px-3 ${permissions.assign_activity || user.designation==='admin' || user.designation==='central manager' ? '':'d-none'}`} style={{backgroundColor:'#57309C',borderRadius:'15px'}} onClick={()=>{
            setshow2(true)
           }}>Add Target</button> */}
          </div>
          </div>

          <div className='container row m-0 d-flex justify-content-between mt-4'>
            <div className="col-md-8">
            <div className="">
      <Slider {...settings}>
        <div className='p-2 px-3'>
        <div className="py-3 px-2 px-lg-3 shadow-sm" style={{backgroundColor:'#fff',borderRadius:'15px'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className=''>
                        <p className='m-0' style={{color:'#202224',fontSize:'13px'}}>New Leads</p>
                        <span className='fw-medium' style={{fontSize:'14px',color:'#202224'}}>{emp_data.new_leads_count}</span>
                      </div>
                      <img src={require('../images/Icon (2).png')} width={36} height={36} alt="" />
                    </div>
                  </div>
        </div>
        <div className='p-2 px-3'>
        <div className="py-3 px-2 px-lg-3 shadow-sm" style={{backgroundColor:'#fff',borderRadius:'15px'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className=''>
                        <p className='m-0' style={{color:'#202224',fontSize:'13px'}}>Followup</p>
                        <span className='fw-medium' style={{fontSize:'14px',color:'#202224'}}>{emp_data.followup_leads_count}</span>
                      </div>
                      <img src={require('../images/ico1.png')} width={36} height={36} alt="" />
                    </div>
                  </div>
        </div>
        <div className='p-2 px-3'>
        <div className="py-3 px-2 px-lg-3 shadow-sm" style={{backgroundColor:'#fff',borderRadius:'15px'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className=''>
                        <p className='m-0' style={{color:'#202224',fontSize:'13px'}}>Prospect</p>
                        <span className='fw-medium' style={{fontSize:'14px',color:'#202224'}}>{emp_data.prospect_leads_count}</span>
                      </div>
                      <img src={require('../images/Group 27231.png')} width={36} height={36} alt="" />
                    </div>
                  </div>
        </div>
        <div className='p-2 px-3'>
        <div className="py-3 px-2 px-lg-3 shadow-sm" style={{backgroundColor:'#fff',borderRadius:'15px'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className=''>
                        <p className='m-0' style={{color:'#202224',fontSize:'13px'}}>Registered</p>
                        <span className='fw-medium' style={{fontSize:'14px',color:'#202224'}}>{emp_data.registered_leads_count}</span>
                      </div>
                      <img src={require('../images/ico1.png')} width={36} height={36} alt="" />
                    </div>
                  </div>
        </div>
        <div className='p-2 px-3'>
        <div className="py-3 px-2 px-lg-3 shadow-sm" style={{backgroundColor:'#fff',borderRadius:'15px'}}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className=''>
                        <p className='m-0' style={{color:'#202224',fontSize:'13px'}}>Closed</p>
                        <span className='fw-medium' style={{fontSize:'14px',color:'#202224'}}>{emp_data.closed_leads_count}</span>
                      </div>
                      <img src={require('../images/Group 27232.png')} width={36} height={36} alt="" />
                    </div>
                  </div>
        </div>
      </Slider>
    </div>

{/* Activity Chart */}

<div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 shadow-sm mt-5`} style={{borderRadius:'10px',height:'421px'}}>
                  <div className='d-flex justify-content-between align-items-center mb-3 mb-lg-4'>
                  <h5 className='text-dark m-0'>Activities Chart</h5>
                  <div className='d-flex'>
                  <select name="" value={filter_type} onChange={(e)=>{
                    if(e.target.value==='custom'){
                      setfilter_type(e.target.value)
                    }
                    else{
                      setfilter_type(e.target.value)
                      setstart_date('')
                      setend_date('')
                      filter_chart(e.target.value,'')
                    }
                  }} className='border-secondary-subtle rounded-pill px-2' style={{cursor:'pointer'}} id="">
                    <option value="">Filter</option>
                    <option value="daily">Today</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="custom">Custom</option>
                  </select>

                 <div className={`${filter_type==='custom' ? 'd-flex':'d-none'}`}>
                 <div className='d-flex ms-4 align-items-center'>
                    <label htmlFor="" className='fw-medium'>From : </label>
                    <input type="date" value={start_date} onChange={(e)=>{
                      setstart_date(e.target.value)
                    }} className='border-secondary-subtle rounded ms-2 px-2' />
                  </div>
                  <div className='d-flex ms-4 align-items-center'>
                    <label htmlFor="" className='fw-medium'>To : </label>
                    <input value={end_date} onChange={(e)=>{
                      setend_date(e.target.value)
                      filter_chart('custom',e.target.value)
                    }} type="date" className='border-secondary-subtle rounded ms-2 px-2' />
                  </div>
                 </div>
                  </div>
                  </div>
                  <Bar data={employeecomparision} options={options}/>
                  </div>

            </div>
          <div className='col-md-4 px-3' style={{height:'546px'}}>
          <div className='bg-white d-flex flex-column shadow-sm rounded align-items-center py-5' style={{width:'100%',height:'100%'}}>
          <div className='rounded-circle p-2' style={{backgroundColor:'#EEF3F2',height:'100px',width:'100px'}}>
                    <img src={emp_data.image} className='rounded-circle' style={{height:'100%',width:'100%'}} alt="" />
                    </div>
        <p className='m-0 fw-medium mt-2 text-primary' style={{fontSize:'16px',cursor:'pointer'}} onClick={()=>{
          navigate(`/view_employee_leads/${emp_data.emp_id}`)
        }}>{emp_data.firstname}</p>
        <span className='text-secondary mb-2'>{emp_data.designation}</span>

        <button onClick={()=>{
          block_trainer(false)
        }} className={`bg-danger-subtle rounded text-danger px-3 py-1 border-0 ${emp_data.status ? '': 'd-none'}`}>Inactive</button>
        <button onClick={()=>{
          block_trainer(true)
        }} className={`bg-success-subtle rounded text-success px-3 py-1 border-0 ${emp_data.status ? 'd-none': ''}`}>Active</button>

        <div className='mt-4 px-2 px-md-4'  style={{width:'100%'}}>
            <p style={{color:'#467DF7'}} className='m-0 mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope p-1 bg-info-subtle rounded-circle" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> <span className='ms-2' style={{fontSize:'13px'}}>{emp_data.email}</span></p>
{/* <p style={{color:'#467DF7'}} className='m-0 mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone p-1 bg-info-subtle rounded-circle" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg><span className='ms-2' style={{fontSize:'13px'}}>+91 8989989898</span></p>
<p style={{color:'#467DF7'}} className='m-0 mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt p-1 bg-info-subtle rounded-circle" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg><span className='ms-2' style={{fontSize:'13px'}}>Bangalore</span></p> */}

<button className={`btn text-white btn-sm w-100 mt-4 ${user.designation==='admin' ? '':'d-none'}`} data-bs-toggle="modal" data-bs-target="#assignpermissions" style={{backgroundColor:'#57309C'}}>Assign Permissions</button>
      
</div>
          </div>
          </div>
          </div>

{/* Other charts */}

                <div className="row m-0 mt-lg-4 pt-3 px-3 mb-4 gap-0 gap-lg-4 justify-content-center">
                <div className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 py-3`} style={{borderRadius:'10px'}}>
                  <h5 className='text-dark mb-3 mb-lg-4'>Leads chart based on Course</h5>
                  <Bar data={leadsbycourse} options={options}/>
                  </div>

                <div className={`col-md-9 mb-5 mb-lg-0 bg-white p-3 mt-2 py-3`} style={{borderRadius:'10px'}}>
                  <h5 className='text-dark mb-3 mb-lg-4'>Leads chart based on Source</h5>
                  <Bar data={leadsbysource} options={options}/>
                  </div>
                 
                 
                </div>

        </div>
    </div>

{/* Activity target assigning modal */}
<Modal show={show2} size='lg' onHide={()=>{
  setshow2(false)
}}>
        
        <Modal.Body>
        <div class="d-flex justify-content-between align-items-center">
        <h6 class="modal-title fs-5 text-primary" id="exampleModalLabel">Add Activity for {emp_data.firstname}!</h6>
        <svg style={{cursor:'pointer'}} onClick={()=>{
          setshow2(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>

      <div className='row m-0'>
        <div className="col-6">
            {/* <img src={require('../images/Banner4 3.png')} style={{width:'100%'}} alt="" /> */}
        </div>
        <div className="col-6 d-flex align-items-center py-5" style={{height:'373px'}}>
            <div className='w-100 row m-0' style={{height:'100%',overflowY:fields.length>1 ? 'scroll':'none'}}>
              <div className={`col-12 p-0 ${fields.length>1 ? 'mb-4':''}`}>
                <select name="" onChange={(e)=>{
                  setmodal_name(e.target.value)
                  console.log(e.target.value)
                }} className='form-select shadow-none border-secondary-subtle border-bottom border-0 rounded-0' id="">
                  <option value="">Select the Module</option>
                  {modals.map((x)=>{
                    return(
                      <>
                      <option value={x.id}>{x.module_name}</option>
                      </>
                    )
                  })}
                </select>
              </div>
      {fields.map((field, index) => (
        <div key={index} className='p-0'>
          <div className='col-12 p-0 bg-info'>
            {/* <label htmlFor="">Activity Name</label> */}
          <input className='activity form-control shadow-none border-0 border-bottom rounded-0 border-secondary-subtle'
            type="text"
            name="Activity_Name"
            value={field.Activity_Name}
            placeholder='Activity Name'
            onChange={(e) => handleChange(index, e)}
          />
          </div>
          <div className='col-12 border-0 mt-4 pt-2 mb-4'>
          {/* <label htmlFor="">Target</label> */}
          <input className='activity form-control shadow-none border-0 border-bottom border-dark-subtle rounded-0'
            type="number"
            name="targets"
            placeholder='Target'
            // value={field.targets}
            onChange={(e) => {
              const { name, value } = e.target;
  const newFields = [...fields];
  newFields[index][name] = Number(value);
  setFields(newFields);
            }}
          />
          </div>
        </div>
      ))}

               <div className={`text-end ${fields.length>1 ? 'pt-2 mt-4':''}`}>
               <button type="button" className='btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill' onClick={handleAddMore}>Add More</button>
               <button className='btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill ms-3' data-bs-dismiss="modal" onClick={assigntarget}>Assign</button>
               </div>
            </div>
        </div>
      </div>
        </Modal.Body>
        
      </Modal>



{/* Assign Permissions */}
<div className="modal fade" id="assignpermissions" tabindex="-1" aria-labelledby="assignpermissions" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between align-items-center">
        <h6 className="modal-title" id="exampleModalLabel">Assign Permissions</h6>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div className="modal-body">
       <div className='px-3'>
       <div className='d-flex align-items-center shadow-sm px-3 bg-white py-3' style={{cursor:'pointer'}} onClick={()=>{
        setdelete_customer_lead(!delete_customer_lead)
       }}>
          {/* <input type="checkbox" name='delete_customer_lead' onChange={(e)=>{
              handleCheckboxChange(e,'delete_customer_lead')
            }}/> */}
            <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${delete_customer_lead ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${delete_customer_lead ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>

          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Delete Customer Leads</label>
        </div>
        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setdelete_client_lead(!delete_client_lead)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${delete_client_lead ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${delete_client_lead ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Delete Client Leads</label>
        </div>
        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setassign_activity(!assign_activity)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${assign_activity ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${assign_activity ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Assign Activity</label>
        </div>
        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setview_all_employees(!view_all_employees)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${view_all_employees ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${view_all_employees ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>View all Employees</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setassign_leads(!assign_leads)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${assign_leads ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${assign_leads ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Assign Leads</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setbatch_permission(!batch_permission)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${batch_permission ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${batch_permission ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Add Batch</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setadd_events(!add_events)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${add_events ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${add_events ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Add Events</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setcreate_projects(!create_projects)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${create_projects ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${create_projects ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Create Projects</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setassign_students(!assign_students)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${assign_students ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${assign_students ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Assign Students</label>
        </div>

        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setview_newleads(!view_newleads)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${view_newleads ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${view_newleads ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>View Walkin Leads</label>
        </div>

        
        <div className='d-flex align-items-center mt-3 bg-white shadow-sm p-3' style={{cursor:'pointer'}} onClick={()=>{
        setadd_activity_module(!add_activity_module)
       }}>
        <span style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#467DF7" class={`bi bi-check-circle-fill ${add_activity_module ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-circle ${add_activity_module ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>
</span>
          <label style={{cursor:'pointer'}} htmlFor="" className='ms-2'>Add Activity Module</label>
        </div>

        <div className='text-end mt-4 mb-2'>
          <button className='btn text-white btn-sm px-4 py-1' style={{backgroundColor:'#57309C'}} onClick={()=>{
            assign_permissions()
          }} data-bs-dismiss="modal">Assign</button>
        </div>
       </div>
      </div>
    </div>
  </div>

  <Add_activity_targets setshow={setshow} show={show} setshow2={setshow2} id={id} name={emp_data.firstname}/>
</div>
</div>
  )
}

export default Employee_profile