import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress } from '../App'
import { Context } from './Context/Context_provider'
import Event_payment_cash_modal from '../invoide_and_receipt/Event_payment_modal'

const Registered_students = () => {

  const {register_value}=useParams()
  const {course_id}=useParams()


    const {id}=useParams()

    const[count,setcount]=useState(0)

    const user=JSON.parse(sessionStorage.getItem('user'))
    const[students,setstudents]=useState([])
    const[course_students,setcourse_students]=useState([])
    const[course_fees,setcourse_fees]=useState({})

    useEffect(()=>{
       if(register_value==='event'){
        axios.get(`${ipaddress}/DisplayEventRegisteredStudents/${user.emp_id}/?event_id=${id}`)
        .then((r)=>{
            console.log("Regsitered event students",r.data);
            setstudents(r.data)
        })
       }
       if(register_value==='course'){
        axios.get(`${ipaddress}/RegisteredExtraCourse/${user.emp_id}/?course_id=${id}`)
        .then((r)=>{
            console.log("Regsitered course students",r.data);
            setcourse_students(r.data)
        })
       }

      if(register_value==='course' && course_id>0){
        axios.get(`${ipaddress}/CourseFees/${course_id}`)
        .then((r)=>{
          console.log("Course Fees",r.data)
          setcourse_fees(r.data)
          // setgst(r.data.single_shot_fees*0.18)
        })
        .catch((err)=>{
          console.log("Courses fetching Error",err)
        })
      }

    },[count])

    const[customer_id,setcustomer_id]=useState()
    const[amount,setamount]=useState()
    const[gst,setgst]=useState()
    const[event_id,setevent_id]=useState()
    const[email,setemail]=useState('')
    const[message,setmessage]=useState('')
    const[btn,setbtn]=useState(false)
    const[installment_status,setinstallment_status]=useState(false)

    const[load,setload]=useState(false)

    const check=()=>{
     setload(true)
     setTimeout(() => {
      if(register_value==='event'){
        check_event_student()
      }
      else{
        check_course_student()
      }
     }, 1000);
    }

    const[show1,setshow1]=useState(false)

    const check_event_student=()=>{
      axios.post(`${ipaddress}/DisplayEventRegisteredStudents/${user.emp_id}/`,{
        email:email,
        event_id:id
    })
    .then((r)=>{
        console.log("Value",r.data);
        setbtn(true)
        setamount(r.data.fees)
        setgst(r.data.gst_total)
        setcustomer_id(email)
        setmessage('')
        setload(false)
    })
    .catch((err)=>{
        setmessage('Student already registered')
        setbtn(false)
        setload(false)
    })
    }

    const check_course_student=()=>{
      
      axios.post(`${ipaddress}/RegisteredExtraCourse/${user.emp_id}/`,{
        email:email,
        course_id:id
    })
    .then((r)=>{
        console.log("Value",r.data);
        setbtn(true)
        setamount(r.data.fees)
        setgst(r.data.gst_total)
        setcustomer_id(email)
        setmessage('')
        setload(false)
    })
    .catch((err)=>{
        setmessage('Student already registered')
        setbtn(false)
        setload(false)
    })
    }

    const navigate=useNavigate()

return (
    <div className='d-flex'>
        <Sidebar/>
        <div className="w-100">
            <Navbar/>

            <div className="p-3 bg-light main-container" style={{minHeight:'100vh'}}>
            <h5 className='mb-3'><svg style={{cursor:'pointer'}} onClick={()=>{
                navigate(`/payments`)
              }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg><span className='ms-2 fs-5'>Registered Students</span></h5>
                <div>
                  <h6 className={`${students.length==0 && register_value==='event' ? 'text-center py-4':'d-none'}`}>No data available...🧐</h6>
                  <h6 className={`${course_students.length==0 && register_value==='corse' ? 'text-center py-4':'d-none'}`}>No data available...🧐</h6>


                <div className={`table-responsive p-3 ${students.length>0 && register_value==='event' ? '':'d-none'}`}>
                <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      <th scope="col"><p className='text-center m-0'>Student Name</p></th>
      <th scope="col"><p className='text-center m-0'>Event Name</p></th>
    </tr>
  </thead>
  <tbody>
  {students.map((x,index)=>{
    return(
        <tr>
        <td scope="row"><p className='text-center m-0'>{index+1}</p></td>
        <td><p className='text-center m-0'>{x.students && x.students.customer_name}</p></td>
        <td><p className='text-center m-0'>{x.events && x.events.description}</p></td>
      </tr>
    )
  })}
  </tbody>
</table>
                </div>

{/* Course registered */}
<div className={`table-responsive p-3 ${course_students.length>0 && register_value==='course' ? '':'d-none'}`}>
                <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      <th scope="col"><p className='text-center m-0'>Student Name</p></th>
      <th scope="col"><p className='text-center m-0'>Course Name</p></th>
    </tr>
  </thead>
  <tbody>
  {course_students.map((x,index)=>{
    return(
        <tr>
        <td scope="row"><p className='text-center m-0'>{index+1}</p></td>
        <td><p className='text-center m-0'>{x.student_details && x.student_details.customer_name}</p></td>
        <td><p className='text-center m-0'>{x.events && x.events.description}</p></td>
      </tr>
    )
  })}
  </tbody>
</table>
                </div>
                </div>
            </div>
        </div>
        <Event_payment_cash_modal register_value={register_value} course_id={id} setcount={setcount} amount={amount} grand_gst_total={gst} customer_id={customer_id} installment_status={installment_status} event_id={id}/>

        <svg data-bs-toggle="modal" data-bs-target="#eventpayment" style={{position:'fixed',right:'20px',bottom:'20px',cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#0D6EFD" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
</svg>

<div class="modal fade" id="eventpayment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h1 class="modal-title fs-5 m-0" id="exampleModalLabel">Check Student</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div className='d-flex align-items-center '>
            <input type="text" value={email} onChange={(e)=>{
                setemail(e.target.value)
            }} className='form-control shadow-none border-secondary-subtle py-3' />
            <button className='btn btn-sm px-3 btn-primary py-2 mt-3 ms-3' onClick={check}>
            <div class={`spinner-grow spinner-grow-sm ${load ? '':'d-none'}`} style={{fontSize:'15px'}} role="status">
  <span class={`visually-hidden ${load ? '':'d-none'}`}>Loading...</span>
</div>
<span className={`${load ? 'd-none':''}`}>Check</span>
            </button>
        </div>
       <div className={`text-center mt-4 ${btn ? '':'d-none'}`}>
       <p className='mt-3'><svg style={{cursor:'pointer'}} onClick={()=>{
          setinstallment_status(true)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class={`bi bi-check-square ${installment_status ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg style={{cursor:'pointer'}} onClick={()=>{
          setinstallment_status(false)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#0B5ED7" class={`bi bi-check-square-fill ${installment_status ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
 <span className='ms-2'>Pay through installment</span></p>
       <button className={`${btn ? '':'d-none'} btn btn-success btn-sm px-3 w-50 py-2`} data-bs-toggle="modal" data-bs-target="#event_payment" onClick={()=>{
          setbtn(false)
          setemail('')
          if(register_value==='course' && installment_status==true){
            setamount(course_fees.installment_fees)
          }
          if(register_value==='course' && installment_status==false){
            setamount(course_fees.single_shot_fees)
          }
        }}>Make Payment</button>
       </div>
       {message && (<p className='text-danger mt-1'>*{message}</p>)} 
      </div>
      
    </div>
  </div>
</div>
    </div>
  )
}

export default Registered_students