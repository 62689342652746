import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Globalsearch from './Globalsearch'
import Offcanvas_navbar from './Offcanvas_navbar'
import { useEffect } from 'react'
import { OverlayTrigger,Tooltip } from 'react-bootstrap'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useState } from 'react'
import Employee_Notification from './Notification'

const Navbar = ({designation,pagename}) => {
  const user=JSON.parse(sessionStorage.getItem('user'))

    // Logout

    const navigate=useNavigate()

    const[user_details,setuser_details]=useState({})

    useEffect(()=>{
        axios.get(`${ipaddress2}/get-user-details/${user.email}/`)
        .then((r)=>{
          console.log("User details",r.data)
          setuser_details(r.data)
        })
    },[])

    const handleLogout=()=>{
      axios.post(`${ipaddress2}/User_Logout/${user.emp_id}/`)
      .then((r)=>{
        sessionStorage.removeItem("user")
        navigate('/')
      })
      .catch((err)=>{
        console.log("Logout Error",err)
      })
    }


    const[state,setstate]=useState(false)
    const[state2,setstate2]=useState(false)

    console.log("State value",state)
return (

    <div className=''>
      
    {/* ---------------MOBILE AND TAB SCREEN NAVBAR */}
 <nav class="navbar bg-body-tertiary d-lg-none mb-4">
<div class="container-fluid">
 <a class="navbar-brand"><img src={require('../images/student/Logo_Blue 1.png')} width={130} alt="" /></a>
 <div class="d-flex" role="search">
 <span className={user.designation==='admin' ? 'd-none':'me-3'} style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#notification" aria-controls="notification">
       <img className={`${state2 ? 'd-none':''}`} src={require('../images/Vector.png')} alt="" />
       <img className={`${state2 ? '':'d-none'}`} src={require('../images/ion_notifications-circle.png')} alt="" />
        </span>
 <span className={user.designation==='admin' ? 'me-3':'d-none'} style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#admin_notification" aria-controls="admin_notification">
       <img className={`${state ? 'd-none':''}`} src={require('../images/Vector.png')} alt="" />
       <img className={`${state2 ? '':'d-none'}`} src={require('../images/ion_notifications-circle.png')} alt="" />

        </span>
 <span type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-justify-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
</svg>
      </span>
 </div>
</div>
</nav>

{/* ----------------------------------------LARGE SCREEN-------------------------------------------------------------- */}
   <div className="d-lg-flex justify-content-between m-0 px-1 px-lg-5 d-none bg-white" style={{height:'70px',position:'fixed',width:'100%',zIndex:4}}>
     <div className='main-container-3 d-flex align-items-center w-100'>
     <div className=''>
     <div class="d-flex align-items-center" style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#searchoffcanvas" aria-controls="searchoffcanvas">
<span class="input-group-text border-0 bg-transparent" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#57309C" class="bi bi-search" viewBox="0 0 16 16">
<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
<p className='text-secondary m-0 ms-1'>Search course, student or instructor</p>
</div>
     </div>
     <div className='w-25 ms-auto d-flex align-items-center justify-content-evenly'>
       <span className={user.designation==='admin' ? 'd-none':''} style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#notification" aria-controls="notification">
       <img className={`${state2 ? 'd-none':''}`} src={require('../images/Vector.png')} alt="" />
       <img className={`${state2 ? '':'d-none'}`} src={require('../images/ion_notifications-circle.png')} alt="" />
        </span>
      
        <span className={user.designation==='admin' ? '':'d-none'} style={{cursor:'pointer'}} data-bs-toggle="offcanvas" data-bs-target="#admin_notification" aria-controls="admin_notification">
       <img className={`${state ? 'd-none':''}`} src={require('../images/Vector.png')} alt="" />
       <img className={`${state ? '':'d-none'}`} src={require('../images/ion_notifications-circle.png')} alt="" />
        </span>


       <div className='d-flex'>
       <div class="dropdown d-flex align-items-center">
<img src={require('../images/user.jpg')} width={50} alt="" data-bs-toggle="dropdown" aria-expanded="false" style={{cursor:'pointer'}}/>

<div className='ms-2'>
            <p style={{cursor:'pointer'}} className='m-0 fw-medium' onClick={()=>{
              // navigate('/profile')
            }}>{user.firstname}</p>
            <span style={{fontSize:'13px'}}>{user.designation}</span>
              </div>

<ul class="dropdown-menu mt-3">
<li><Link to='/profile' class="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
<path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
</svg> <span className='ms-2'>View Profile</span></Link></li>

<li><a onClick={()=>{
      handleLogout()
    }} class={`dropdown-item d-flex align-items-center ${user.designation==='admin' ? 'd-none':''}`} style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg> <span className='ms-2'>Logout</span></a></li>

<li><a onClick={()=>{
      handleLogout()
    }} class={`dropdown-item d-flex align-items-center ${user.designation==='admin' ? '':'d-none'}`} style={{cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
</svg> <span className='ms-2'>Logout</span></a></li>

</ul>
</div>

       </div>
     </div>
     </div>
       </div>

<Globalsearch/>
<Offcanvas_navbar designation={designation}/>
<Employee_Notification setstate={setstate} setstate2={setstate2}/>
 </div>

  )
}

export default Navbar