import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Allleads from './All_leads'
import All_client_leads from './All_client_leads'

const View_all_leads = () => {
    const[value,setValue]=useState('customer')
  return (
    <div className='d-flex'>
        <Sidebar activevalue={"allleads"}/>
        <div className="w-100">
            <Navbar/>
            <div className="bg-light p-3 main-container" style={{minHeight:'100vh'}}>
            <div className='mb-3'>
    <button onClick={()=>{
        setValue("customer")
    }} className={`btn btn-sm fw-medium py-2`} style={{borderRadius:'6px 0px 0px 6px',fontSize:'14px',backgroundColor:value==='customer' ? '#57309C':'#ffff',color:value==='customer' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>All B2C Leads</button>
    <button onClick={()=>{
        setValue("client")
    }} className={`btn btn-sm fw-medium py-2`} style={{borderRadius:'0px 6px 6px 0px',fontSize:'14px',backgroundColor:value==='client' ? '#57309C':'#ffff',color:value==='client' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>All B2B Leads</button>

</div>

<div className={`${value==='customer' ? '':'d-none'}`}>
<Allleads/>
</div>

<div className={`${value==='client' ? '':'d-none'}`}>
<All_client_leads/>
</div>
            </div>
        </div>
    </div>
  )
}

export default View_all_leads