import React, { useState,useEffect } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'

const Assigned_leads = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const[value,setValue]=useState("customer")

    const[customer_leads,setCustomer_leads]=useState([])
    const[client_leads,setClient_leads]=useState([])

    const fetchcustomerleads=()=>{
        axios.get(`${ipaddress}/AssignedCustomerLeadsView/${user.emp_id}/`)
        .then((r)=>{
            console.log("Assigned customer leads",r.data)
            setCustomer_leads(r.data)
        })
        .catch((err)=>{
            console.log("Customer assigned leads error",err)
        })
    }

    const fetchclientleads=()=>{
        axios.get(`${ipaddress}/AssignedClientLeadsView/${user.emp_id}/`)
        .then((r)=>{
            console.log("Assigned client leads",r.data)
            setClient_leads(r.data)
        })
        .catch((err)=>{
            console.log("client assigned leads error",err)
        })
    }

    useEffect(()=>{
        fetchcustomerleads()
    },[])


  return (
    <div className='d-flex'>
        <Sidebar activevalue={"assignedleads"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>

<div className='mb-3'>
    <button onClick={()=>{
        setValue("customer")
        fetchcustomerleads()
    }} className={`btn btn-sm fw-medium py-2`} style={{borderRadius:'6px 0px 0px 6px',fontSize:'14px',backgroundColor:value==='customer' ? '#57309C':'#ffff',color:value==='customer' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>Assigned B2C Leads</button>
    <button onClick={()=>{
        setValue("client")
        fetchclientleads()
    }} className={`btn btn-sm fw-medium py-2`} style={{borderRadius:'0px 6px 6px 0px',fontSize:'14px',backgroundColor:value==='client' ? '#57309C':'#ffff',color:value==='client' ? '#ffff':'#57309C',border:'1px solid #57309C'}}>Assigned B2B Leads</button>

</div>


                <div class={`${value==='customer' ? '':'d-none'}`}>
  <div className='d-flex justify-content-between align-items-center pb-3'>
    <h6 className='text-primary'>Assigned B2C Leads</h6>
    <div className='d-flex align-items-center w-50 justify-content-end'>
    <div class="input-group rounded bg-light border" style={{width:'50%'}}>
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
  <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
</svg></span>
  <input type="text" onChange={(e)=>{
    // search_all_leads(e.target.value)
  }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
</div>
    </div>
    </div>
  <h6 className={`text-secondary text-center ${customer_leads.length>0 ? 'd-none':''}`}>No Data Found...🧐</h6>
<div className={`table-responsive rounded bg-white border-0 p-3 ${customer_leads.length>0 ? '':'d-none'}`} style={{minHeight:'100vh'}}>
<table class={`table caption-top rounded`}>
  <thead className='invoice-table table-secondary'>
    <tr>
      <th scope="col" className='py-2'></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Name</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Mobile No</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Email</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Course Enquired</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Lead Stage</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Assigned To</p></th>
    </tr>
  </thead>
  <tbody>
    {customer_leads.map((x,index)=>{
      return(
        <tr>
        <td className='py-3' scope="row"><p className='text-center m-0'>{index+1}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.name}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.contact_number}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.email}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.course_enquiredd}</p></td>
        <td className={`py-3`}><span className={`${x.level_lead_name==='registered' ? 'bg-success text-white  rounded-pill py-1 px-3':''}`}><p className='text-center m-0'>{x.level_lead_name}</p></span></td>
        <td className='py-3'><p className='text-center m-0'>{x.assigned_too}</p></td>

      </tr>
      )
    })}
  </tbody>
</table>
</div>
        
</div>

<div class={`${value==='client' ? '':'d-none'}`}>
  <div className='d-flex justify-content-between align-items-center pb-3'>
    <h6 className='text-primary'>Assigned B2B Leads</h6>
    <div className='d-flex align-items-center w-50 justify-content-end'>
    <div class="input-group rounded bg-light border" style={{width:'50%'}}>
  <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
  <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
</svg></span>
  <input type="text" onChange={(e)=>{
    // search_all_leads(e.target.value)
  }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
</div>
    </div>
    </div>
  <h6 className={`text-secondary text-center ${client_leads.length>0 ? 'd-none':''}`}>No Data Found...🧐</h6>
<div className={`table-responsive rounded bg-white border-0 p-3 ${client_leads.length>0 ? '':'d-none'}`} style={{minHeight:'100vh'}}>
<table class={`table caption-top rounded`}>
  <thead className='invoice-table table-secondary'>
    <tr>
      <th scope="col" className='py-2'></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Name</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Company Name</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Comapny Contact No</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Course Enquired</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Lead Stage</p></th>
      <th scope="col" className='py-2'><p className='fw-normal text-center m-0 text-secondary'>Assigned To</p></th>
    </tr>
  </thead>
  <tbody>
    {client_leads.map((x,index)=>{
      return(
        <tr>
        <td className='py-3' scope="row"><p className='text-center m-0'>{index+1}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.name}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.company_name!=null ? x.company_name : 'null'}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.contact_number!=null ? x.contact_number : 'null'}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.course_enquiredd}</p></td>
        <td className={`py-3`}><p className='text-center m-0'>{x.level_lead_name}</p></td>
        <td className='py-3'><p className='text-center m-0'>{x.assigned_too}</p></td>
      </tr>
      )
    })}
  </tbody>
</table>
</div>
        
</div>
            </div>
        </div>
    </div>
  )
}

export default Assigned_leads