import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress } from '../App'

const Refer = () => {
  const navigate=useNavigate()

  const user=JSON.parse(sessionStorage.getItem('user'))

  const[referrals,setreferrals]=useState([])

  useEffect(()=>{
    axios.get(`${ipaddress}/Student-Referal-leads/${user.id}/`)
  .then((r)=>{
    console.log('Referrals',r.data)
    setreferrals(r.data)
  })
},[])

  return (
    <div className='d-flex'>
        <Sidebar activevalue={"refer"}/>
        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3 bg-light" style={{minHeight:'100vh'}}>
            <div className='d-flex justify-content-between align-items-center'>
                <h5>Refer Your Friends & Earn</h5>
                <button className='btn btn-sm btn-primary px-3' onClick={()=>{
                  navigate('/referral_form')
                }}>Refer & Earn</button>
            </div>

            <div className={`mt-5 ${referrals.length>0 ? '':'d-none'}`}>
            <h6 className='text-primary fw-medium pb-2'>Your Referrals</h6>
              <div className="table-responsive">
              <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-center m-0'>SI.No</p></th>
      <th scope="col"><p className='text-center m-0'>Name</p></th>
      <th scope="col"><p className='text-center m-0'>Email</p></th>
      <th scope="col"><p className='text-center m-0'>Contact Number</p></th>
    </tr>
  </thead>
  <tbody>
    {referrals.map((x,index)=>{
      return(
        <tr>
      <td scope="row"><p className='text-center m-0'>{index+1}</p></td>
      <td><p className='text-center m-0'>{x.customer_lead_id.name}</p></td>
      <td><p className='text-center m-0'>{x.customer_lead_id.email}</p></td>
      <td><p className='text-center m-0'>{x.customer_lead_id.contact_number}</p></td>
    </tr>
      )
    })}
  </tbody>
</table>
              </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Refer