import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import Slider from 'react-slick'
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';
import Modal from 'react-bootstrap/Modal';
import Add_upcoming_batch from './Add_details/Add_upcoming_batch';
import { Link } from 'react-router-dom';

const Add_details = () => {

    const[show,setshow]=useState(false)

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
        // initialSlide: 0,
        // autoplay: true,
        speed: 500,
        // autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const[type,settype]=useState('course')

    const renderTooltip3 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add Installment
        </Tooltip>
      );

      const renderTooltip4 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add Sub Course
        </Tooltip>
      );

const[course,setcourse]=useState('')
const[installment_days,setinstallment_days]=useState(0)
const[single_shot_days,setsingle_shot_days]=useState(0)
const[single_shot_fees,setsingle_shot_fees]=useState(0)
const[course_duration,setcourse_duration]=useState(0)


// Installments
const [codingquestions, setcodingQuestions] = useState([0]);
  
  const handleAddcodingQuestion = () => {
    setcodingQuestions([...codingquestions, [0]]);
  };

const handlecodingQuestionChange = (index, event) => {
    const newQuestions = codingquestions.map((q, qIndex) =>
      qIndex === index ? Number(event.target.value) : q
    );
    setcodingQuestions(newQuestions);
  };

// Sub courses
const [subcourses, setsubcourses] = useState(['']);
  
  const handleAddsubcourses = () => {
    setsubcourses([...subcourses, '']);
  };
  
  const handlesubcoursesChange = (index, event) => {
    const newCourses = subcourses.map((course, courseIndex) =>
        courseIndex === index ? event.target.value : course
    );
    setsubcourses(newCourses);
};

const user=JSON.parse(sessionStorage.getItem('user'))

  const add_course=()=>{
    axios.post(`${ipaddress2}/CoursesAddition/${user.email}/`,{
        'course':course,
        'installments':codingquestions,
        'courses':subcourses,
        'installment_days':installment_days,
        'single_shot_days':single_shot_days,
        'single_shot_fees':single_shot_fees,
        'course_duration':course_duration
    })
    .then((r)=>{
        console.log("Successfully course added",r.data)
        setcodingQuestions([0])
        setsubcourses([''])
        setcourse('')
        setsingle_shot_days(0)
        setsingle_shot_fees(0)
        setcourse_duration(0)
        setinstallment_days(0)

        toast.success(`${course} Course added successfully`,{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
    })
    .catch((err)=>{
        console.log(err)
        console.log({
            'course':course,
            'installments':codingquestions,
            'courses':subcourses,
            'installment_days':installment_days,
            'single_shot_days':single_shot_days,
            'single_shot_fees':Number(single_shot_fees),
            'course_duration':course_duration
        })
    })
  }


//   Add Batch

const[batchtypes,setbatchtypes]=useState([])
const[classrooms,setclassrooms]=useState([])
const[trainers,settrainers]=useState([])
const[available_courses,setavailable_courses]=useState([])
const[main_course,setmain_course]=useState()

const fetch_details=()=>{
    axios.get(`${ipaddress2}/AddBatchType/${user.email}/`)
    .then((r)=>{
        console.log("Batch types",r.data)
        setbatchtypes(r.data)
    })

    axios.get(`${ipaddress}/SendCourses/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setavailable_courses(r.data)
    })

    axios.get(`${ipaddress2}/Classrooms/`)
    .then((r)=>{
        console.log("Class rooms",r.data)
        setclassrooms(r.data)
    })

    axios.get(`${ipaddress2}/SubCoursesView/${user.email}/`)
    .then((r)=>{
        console.log("Sub courses",r.data)
        setsubcourses(r.data)
    })

    axios.get(`${ipaddress2}/Trainers/`)
    .then((r)=>{
        console.log("Trainers",r.data)
        settrainers(r.data)
    })
}

const[filtered_sub_courses,setfiltered_sub_courses]=useState([])

const fetch_subcourses=(id)=>{
    axios.get(`${ipaddress2}/SubCoursesView/${user.email}/?id=${id}`)
    .then((r)=>{
        console.log("Filtered Sub courses",r.data)
        setfiltered_sub_courses(r.data)
    })

}

const[batch_type,setbatch_type]=useState('')
const[sub_course,setsub_course]=useState('')
const[classroom,setclassroom]=useState('')
const[trainer,settrainer]=useState('')
const[start_date,setstart_date]=useState('')
const[start_time,setstart_time]=useState('')
const[end_date,setend_date]=useState('')
const[end_time,setend_time]=useState('')


const add_batch=()=>{
    axios.post(`${ipaddress2}/AddBatch/${user.email}/`,{
        'batch_type':Number(batch_type),
        'sub_course_id':Number(sub_course),
        'classroom':Number(classroom),
        'trainer':Number(trainer),
        'start_date':start_date,
        'start_time':start_time,
        'end_time':end_time,
    })
    .then((r)=>{
        console.log("Successfully batch added",r.data)
        setbatch_type('')
        setclassroom('')
        settrainer('')
        setsub_course('')
        setstart_date('')
        setstart_time('')
        setend_time('')

        toast.success(`New Batch added successfully`,{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
    })
    .catch((err)=>{
        console.log(err)
        console.log({
            'batch_type':Number(batch_type),
            'sub_course_id':Number(sub_course),
            'classroom':Number(classroom),
            'trainer':Number(trainer),
            'start_date':start_date,
            'start_time':start_time,
            'end_time':end_time
        })
    })
  }


//   Add Trainer

const[employees,setemployees]=useState([])
const[specializations,setspecializations]=useState([])

const fetch_data=()=>{
    axios.get(`${ipaddress2}/AllprofileView/`)
    .then((r)=>{
        console.log("All employeeess",r.data)
        setemployees(r.data)
    })

    axios.get(`${ipaddress2}/CoursesAddition/${user.email}/`)
    .then((r)=>{
        console.log("All courses",r.data)
        setspecializations(r.data)
    })
}


const[name,setname]=useState('')
const[specialization,setspecialization]=useState('')

const add_trainer=()=>{
    setload(true)
    axios.post(`${ipaddress2}/Trainers/`,{
        'trainer':name,
        'specialization':Number(specialization)
    })
    .then((r)=>{
        console.log("Successfully Trainer added",r.data)
        setname('')
        setspecialization('')

        toast.success(`Trainer assigned successfully`,{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setload(false)
    })
    .catch((err)=>{
        console.log(err)
        console.log({
            'trainer':name,
        'specialization':Number(specialization)
        })
    })
  }

//   Add activity module

const[activity_module,setactivity_module]=useState('')
const[activity_name,setactivity_name]=useState('')
const[activity_id,setactivity_id]=useState()

const add_activity_module=()=>{
    setload(true)
    setTimeout(() => {
        send()
    }, 1000);
}

const send=()=>{
    axios.post(`${ipaddress}/AddModules/`,{
        module_name:activity_module
    })
    .then((r)=>{
        console.log("Activity module",r.data)
        setload(false)
        if(r.data==='Module already exists'){
            toast.error('Module Name already exists',{
                autoClose:2000,
                theme:'colored',
            position:'top-center'
            })
        }
        else{
            toast.success('Module name added successfully',{
                autoClose:1000
            })
            setactivity_module('')
            setactivitystate(false)
            fetch_modules()
        }
    })
}

const[activitystate,setactivitystate]=useState(false)
const[load,setload]=useState()
const[modules,setmodules]=useState([])

const fetch_modules=()=>{
    axios.get(`${ipaddress2}/AllModules/`)
    .then((r)=>{
        console.log("Modules",r.data)
        setmodules(r.data)
    })
}

const update_modules=()=>{
    axios.put(`${ipaddress2}/EditActivityModuleName/${activity_id}/`,{
        new_name:activity_name
    })
    .then((r)=>{
        console.log("Module name updated",r.data)
        setshow(false)
        fetch_modules()
        setactivity_name('')
        setactivity_id(0)
    })
}


// Add Project

const[categorystate,setcategorystate]=useState(false)
const[categoryform1state,setcategoryform1state]=useState(false)
const[file,setfile]=useState(null)
const[categories,setcategories]=useState([])
const[languages,setlanguages]=useState([])
const[selectedlanguages,setselectedlanguages]=useState([])
const[selectedlanguages_name,setselectedlanguages_name]=useState([])

const[category,setcategory]=useState()
const[category_name,setcategory_name]=useState('')

const[project_description,setproject_description]=useState('')
const[project_name,setproject_name]=useState('')
const[difficulty,setdifficulty]=useState('')
const[project_type,setproject_type]=useState('')
const[project_cost,setproject_cost]=useState()

const fetch_categories=()=>{
    axios.get(`${ipaddress2}/CategoryChoicesView/`)
    .then((r)=>{
        console.log("Categories",r.data)
        setcategories(r.data)
    })
}

const fetch_languages=(id)=>{
axios.get(`${ipaddress2}/DisplaySubCourseBasedOnCategory/${id}/`)
.then((r)=>{
    console.log("Languages",r.data)
    if(r.data.length>0){
        setlanguages(r.data)
    }
    else{
        setlanguages([])
        setselectedlanguages([])
        setselectedlanguages_name([])
    }
})
}

const add_project=()=>{
    setload(true)
    const formdata=new FormData()
    formdata.append('project_name',project_name)
    formdata.append('difficulty_level',difficulty)
    formdata.append('category',category)
    formdata.append('description',project_description)
    formdata.append('document',file)
    formdata.append('document',file)
    formdata.append('languages',selectedlanguages)
    if(project_type==='premium'){
        formdata.append('premium_status',true)
    }
    if(project_type==='free'){
        formdata.append('premium_status',false)
    }

    formdata.append('cost',Number(project_cost))

    axios.post(`${ipaddress}/AddProjectsForStudents/${user.email}/`,formdata)
    .then((r)=>{
        console.log("Project added",r.data)
        setfile(null)
        setproject_description('')
        setproject_name('')
        setcategory('')
        setdifficulty('')
        setproject_cost('')
        setproject_type('')
        setselectedlanguages([])
        setselectedlanguages_name([])
        toast.success('Project added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setload(false)
    })
    .catch((err)=>{
        console.log(err)
        setload(false)
        console.log(selectedlanguages)

    })

}

const[category_img,setcategory_img]=useState(null)
const[cat_description,setcat_description]=useState('')
const[cat_title,setcat_title]=useState('')
const[created_category,setcreated_category]=useState({})

const add_category=async ()=>{
    setload(true)
  
    const form=new FormData()
    form.append('category_name',category_name)
    form.append('description',cat_description)
    form.append('title',cat_title)
    form.append('image',category_img)

    axios.post(`${ipaddress2}/AddCategory/`,form)
    .then((r)=>{
        console.log(r.data)
        setcreated_category(r.data)
        if(r.data.detail==='Category already exists'){
            toast.warn('Category already exists',{
                autoClose:2000,
                theme:'colored',
            position:'top-center'
            })
            setload(false)
        }
        else{
            toast.success('Category added successfully',{
                autoClose:1000
            })
            fetch_categories(   )
            setcat_description('')
            setcat_title('')
            setcategory_img(null)
            setcategory_name('')
            setcategoryform1state(true)
            setload(false)
        }
    })
    .catch((err)=>{
        setload(false)
        console.log(err)
        console.log(sublanguages)
    })
}


const[sublanguages,setsublanguages]=useState([{
    'sub_category_name':'',
    'logo':null
}])

const handleAddLanguage = () => {
    setsublanguages([...sublanguages, { sub_category_name: '', logo: null }]);
};

const handleLanguageChange = (index, field, value) => {
    const newSublanguages = [...sublanguages];
    newSublanguages[index][field] = value;
    setsublanguages(newSublanguages);
};

// Add Sublanguages
const add_sublanguages=async ()=>{
    setload(true)
    const formData = new FormData();
        sublanguages.forEach((flashcard, index) => {
          formData.append(`flashcards[${index}].logo`, flashcard.logo);
          formData.append(`flashcards[${index}].sub_category_name`, flashcard.sub_category_name);
        });
    
        const response = await axios.post(`${ipaddress2}/Add_Sub_Category/${created_category.id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if(response){
            console.log(response.data)
            setload(false)
            setsublanguages([{
                sub_category_name:'',
                logo:null
            }])
            toast.success('Sub languages successfully added',{
                autoClose:1000,
                theme:'colored',
            position:'top-center'
            })
            setcategorystate(false)
        }
}

// Add Events

const[eventdescription,seteventdescription]=useState('')
const[event_date,setevent_date]=useState('')
const[event_time,setevent_time]=useState('')
const[event_img,setevent_img]=useState(null)
const[event_type,setevent_type]=useState('')
const[event_link,setevent_link]=useState('')
const[amount,setamount]=useState(0)
const[gst_total,setgst_total]=useState(0)

const[allevents,setallevents]=useState([])

// const fetch_events=()=>{
//   axios.get(`${ipaddress2}/upcomming-events/`)
//   .then((r)=>{
//     setallevents(r.data)
//   })
// }

const add_event=()=>{
    setload(true)
    const form=new FormData()
    form.append('description',eventdescription)
    form.append('image',event_img)
    form.append('event_time',event_time)
    form.append('event_date',event_date)
    form.append('free_status',event_type)
    form.append('link',event_link)
    if(event_type==='cost'){
        form.append('fees',amount)
    form.append('gst_total',gst_total)
    }

    axios.post(`${ipaddress2}/upcomming-events/`,form)
    .then((r)=>{
        setload(false)
        toast.success('Event added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setevent_img(null)
        setevent_date('')
        setevent_time('')
        seteventdescription('')
        setevent_type('')
        setgst_total(0)
        setevent_link('')
        setamount(0)
        // fetch_events()
    })
    .catch((err)=>{
        setload(false)
        console.log(err)
    })

}


// Assign Students to batch

const[students,setstudents]=useState([])
const[incomplete_batches,setincomplete_batches]=useState([])
const[selectedstudents,setselectedstudents]=useState([])
const[selceted_batch,setseleced_batch]=useState()

const fetch_students=()=>{
    axios.get(`${ipaddress2}/StudentsToBeAddedToBatch/`)
    .then((r)=>{
        console.log("Students",r.data)
        setstudents(r.data)
    })

    axios.get(`${ipaddress2}/incomplete-batches/`)
    .then((r)=>{
        console.log("Incomplete batches",r.data)
        setincomplete_batches(r.data)
    })
}

const handleCheckboxChange = (e, student_id) => {
    if (e.target.checked) {
      setselectedstudents([...selectedstudents, student_id]);
    } 
    else {
      setselectedstudents(selectedstudents.filter(id => id !== student_id));
    }
  };

const assign_students=()=>{
setload(true)
axios.post(`${ipaddress}/add-student-to-batch/`,{
    customer_ids:selectedstudents,
    batch:selceted_batch
})
.then((r)=>{
    toast.success('Students assigned successfully',{
        autoClose:1000,
        theme:'colored',
            position:'top-center'
    })
    setload(false)
   setTimeout(() => {
    window.location.reload()
   }, 2000);
})
}


// Add Interview

const[company_name,setcompany_name]=useState('')
const[location,setlocation]=useState('')
const[position,setposition]=useState('')
const[interview_date,setinterview_date]=useState('')
const[interview_time,setinterview_time]=useState('')
const[interview_type,setinterview_type]=useState('')
const[job_description,setjob_description]=useState('')

const add_interview=()=>{
    setload(true)
axios.post(`${ipaddress}/interview-details/`,{
date:interview_date,
time:interview_time,
company_name:company_name,
position:position,
interview_location:location,
interview_type:interview_type,
jd:job_description
})

.then((r)=>{
    toast.success('Interview added successfully',{
        autoClose:1000,
        theme:'colored',
            position:'top-center'
    })
    setload(false)

    setinterview_time('')
    setinterview_date('')
    setinterview_type('')
    setposition('')
    setcompany_name('')
    setlocation('')
    setjob_description('')
})
}

// Add Upcoming course

const[coursedescription,setcoursedescription]=useState('')
const[course_date,setcourse_date]=useState('')
const[course_name,setcourse_name]=useState('')
const[course_img,setcourse_img]=useState(null)

const[allcourses,setallcourses]=useState([])

const fetch_courses=()=>{
    axios.get(`${ipaddress}/DisplayCourses/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setallcourses(r.data)
    })
}

const add_upcoming_course=()=>{
    setload(true)
    const form=new FormData()
    form.append('description',coursedescription)
    form.append('image',course_img)
    form.append('date_time',course_date)
    form.append('course_name',course_name)

    axios.post(`${ipaddress2}/UpcomingCoursesView/`,form)
    .then((r)=>{
        setload(false)
        toast.success('Upcoming course added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setcourse_img(null)
        setcourse_date('')
        setcoursedescription('')
        setcourse_name('')
    })
    .catch((err)=>{
        setload(false)
        console.log(err)
    })

}

// Add syllabus

const[sub_course_id,setsubcourse_id]=useState()

// Installments
const [syllabuses, setsyllabuses] = useState(['']);
  
  const handleAddsyllabuses = () => {
    setsyllabuses([...syllabuses, ['']]);
  };

const handlesyllabusesChange = (index, event) => {
    const newsyllabus = syllabuses.map((q, qIndex) =>
      qIndex === index ? event.target.value : q
    );
    setsyllabuses(newsyllabus);
  };

const add_syylabus=()=>{
    setload(true)
    axios.post(`${ipaddress}/SendSyllabusForCourse/add-syllabus/${sub_course_id}/`,{
        topic_name:syllabuses
    })
    .then((r)=>{
        toast.success('Syllabus added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setload(false)
        setsyllabuses([''])
        setsubcourse_id('')
    })
}

// Add classroom
const[class_room,setclass_room]=useState('')
const[capacity,setcapacity]=useState()

const add_classroom=()=>{
    setload(true)
    axios.post(`${ipaddress}/ClassroomView/`,{
        name:class_room,
        capacity:Number(capacity)
    })
    .then((r)=>{
        toast.success('Classroom added successfully',{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
        setload(false)
        setcapacity('')
        setclass_room('')
    })
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"details"}/>
        <div className="w-100">
            <Navbar/>

            <div className="p-3 bg-light main-container" style={{minHeight:'100vh'}}>
                <div className="container">
                <div className="mt-3 container">
      <Slider {...settings} className='px-2'>
      {/* <div className="d-flex"> */}
      <div className='p-2'>
        <p onClick={()=>{
                        settype('course')
                    }} className='py-2 justify-content-center px-4 d-flex align-items-center pb-3' style={{color:type==='course' ? '#57309C':'',cursor:'pointer',borderBottom:type==='course' ? '2px solid #57309C':'none'}}>Add Course</p>
        </div>
        <div className='p-2 text-center'>
        <p onClick={()=>{
                        settype('batch')
                        fetch_details()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='batch' ? '#57309C':'',cursor:'pointer',borderBottom:type==='batch' ? '2px solid #57309C':'none'}}>Add Batch</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('upcoming-batch')
                        fetch_courses()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='upcoming-batch' ? '#57309C':'',cursor:'pointer',borderBottom:type==='upcoming-batch' ? '2px solid #57309C':'none'}}>Upcoming Batch</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('trainer')
                        fetch_data()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='trainer' ? '#57309C':'',cursor:'pointer',borderBottom:type==='trainer' ? '2px solid #57309C':'none'}}>Add Trainer</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('activity')
                        fetch_modules()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='activity' ? '#57309C':'',cursor:'pointer',borderBottom:type==='activity' ? '2px solid #57309C':'none'}}>Add Activity Modules</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('project')
                        fetch_categories()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='project' ? '#57309C':'',cursor:'pointer',borderBottom:type==='project' ? '2px solid #57309C':'none'}}>Add Projects</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('event')
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='event' ? '#57309C':'',cursor:'pointer',borderBottom:type==='event' ? '2px solid #57309C':'none'}}>Add Events</p>
        </div>

        <div className='p-2'>
        <p onClick={()=>{
                        settype('upcoming-course')
                        fetch_courses()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='upcoming-course' ? '#57309C':'',cursor:'pointer',borderBottom:type==='upcoming-course' ? '2px solid #57309C':'none'}}>Upcoming Course</p>
        </div>
        
        <div className='p-2'>
        <p onClick={()=>{
                        settype('student')
                        fetch_students()
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='student' ? '#57309C':'',cursor:'pointer',borderBottom:type==='student' ? '2px solid #57309C':'none'}}>Assign Students</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('interview')
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='interview' ? '#57309C':'',cursor:'pointer',borderBottom:type==='interview' ? '2px solid #57309C':'none'}}>Add Interview Details</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('syllabus')
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='syllabus' ? '#57309C':'',cursor:'pointer',borderBottom:type==='syllabus' ? '2px solid #57309C':'none'}}>Add Syllabus</p>
        </div>
        <div className='p-2'>
        <p onClick={()=>{
                        settype('class')
                    }} className='py-2 px-4 justify-content-center d-flex align-items-center pb-3' style={{color:type==='class' ? '#57309C':'',cursor:'pointer',borderBottom:type==='class' ? '2px solid #57309C':'none'}}>Add Classroom</p>
        </div>
      {/* </div> */}
        
      </Slider>
    </div>
                </div>

{/* Add New Course */}

<div className={`${type==='course' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Course</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-lg-6'>
                <label htmlFor="">Course Name</label>
                <input type="text" value={course} onChange={(e)=>{
                    setcourse(e.target.value)
                }} className='mt-2 form-control shadow-none border-secondary-subtle py-2 bg-secondary-subtle' />
                </div>

{/* Installments */}
                <div className='mb-3 border-bottom border-top pb-3 pt-3'>
                    <p className='m-0'>Installments</p>
                    <div className='row m-0 mb-2 p-0' style={{ marginBottom: '20px',borderRadius:'10px'}}>
              {codingquestions.map((q, qIndex) => (
                
                  <div className='col-lg-3 px-2' key={qIndex}>
                    <input value={q.text} className='border-secondary-subtle add-option form-control py-2 shadow-none bg-secondary-subtle my-2'
                      type="number"
                      placeholder='Enter amount here...'
                      onChange={(e) => handlecodingQuestionChange(qIndex, e)}
                      required
                    />
                  </div>
                
              ))}
              </div>
              <div className='d-flex justify-content-end mt-3'>
              <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip3}
    >
      <button type="button" className='rounded-circle btn btn-sm bg-secondary-subtle text-white d-flex align-items-center' onClick={handleAddcodingQuestion}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                  </svg> 
                </button>
    </OverlayTrigger>
               </div>
            </div>

{/* Sub courses */}
<div className='mb-3 border-bottom pb-3'>
                    <p className='m-0'>Sub Courses</p>
                    <div className='row m-0 mb-2 p-0' style={{ marginBottom: '20px',borderRadius:'10px'}}>
              {subcourses.map((q, qIndex) => (
                
                  <div className='col-lg-6 px-2' key={qIndex}>
                    <input className='add-option form-control py-2 shadow-none border-secondary-subtle bg-secondary-subtle my-2'
                      type="text"
                      placeholder='Type Sub course name here...'
                      onChange={(e) => handlesubcoursesChange(qIndex, e)}
                      required
                    />
                  </div>
                
              ))}
              </div>
              <div className='d-flex justify-content-end mt-4'>
              <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4}
    >
      <button type="button" className='rounded-circle btn btn-sm bg-secondary-subtle text-white d-flex align-items-center' onClick={handleAddsubcourses}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                  </svg> 
                </button>
    </OverlayTrigger>
               </div>
            </div>

<div className='mb-3 col-lg-6 border-bottom pb-3'>
<label htmlFor="">Installment Days</label>
<input type="number" value={installment_days} onChange={(e)=>{
                    setinstallment_days(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of days' />
</div>

<div className='mb-3 col-lg-6 border-bottom pb-3'>
<label htmlFor="">Single Shot Days</label>
<input type="number" value={single_shot_days} onChange={(e)=>{
                    setsingle_shot_days(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of days' />
</div>

<div className='mb-3 col-lg-6 border-bottom pb-3'>
<label htmlFor="">Single Shot Fees</label>
<input type="number" value={single_shot_fees} onChange={(e)=>{
                    setsingle_shot_fees(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter the amount' />
</div>

<div className='mb-3 col-lg-6 border-bottom pb-3'>
<label htmlFor="">Course Duration</label>
<input type="number" value={course_duration} onChange={(e)=>{
                    setcourse_duration(e.target.value)
                }} className='form-control bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of hours' />
</div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={add_course}>Submit</button>

</div>
            </div>
        </div>
    </div>

</div>


{/* Add New Batch */}

<div className={`${type==='batch' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Batch</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Batch Type</label>
                <select type="date" value={batch_type} onChange={(e)=>{
                    setbatch_type(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                    <option value="">Select Batch Type</option>
                    {batchtypes.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.batch_type_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Course Name</label>
                <select type="" value={main_course} onChange={(e)=>{
                    setmain_course(e.target.value)
                    fetch_subcourses(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Course</option>
                    {available_courses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Sub Course Name</label>
                <select type="date" value={sub_course} onChange={(e)=>{
                    setsub_course(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Sub Course</option>
                    {filtered_sub_courses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.sub_course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Class Room</label>
<select type="date" value={classroom} onChange={(e)=>{
                    setclassroom(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                     <option value="">Select Class Room</option>
                    {classrooms.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.name}</option>
                            </>
                        )
                    })}
                    </select>
</div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Trainer Name</label>
<select type="date" value={trainer} onChange={(e)=>{
                    settrainer(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
<option value="">Select the Trainer</option>
                    {trainers.map((x)=>{
                        return(
                            <>
                            <option value={x.trainer_name.id}>{x.trainer_name && x.trainer_name.first_name && x.trainer_name.first_name}</option>
                            </>
                        )
                    })}
                    </select>
                    </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch Start Date</label>
<input type="date" value={start_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setstart_date(e.target.value)
    }
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter the amount' />
</div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch Start Time</label>
<input type="time" value={start_time} onChange={(e)=>{
                    setstart_time(e.target.value)
                }} className='form-control bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of hours' />
</div>
<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch End Date</label>
<input type="date" value={end_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setend_date(e.target.value)
    }
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter the amount' />
</div>
<div className='mb-3 col-lg-4 col-md-6'>
<label htmlFor="">Batch End Time</label>
<input type="time" value={end_time} onChange={(e)=>{
                    setend_time(e.target.value)
                }} className='form-control bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of hours' />
</div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={add_batch}>Submit</button>

</div>
            </div>
        </div>
    </div>

</div>

{/* Add Trainer */}

<div className={`${type==='trainer' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add Trainer</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="">Trainer</label>
                <select type="date" value={name} onChange={(e)=>{
                    setname(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                    <option value="">Select the Employee</option>
                    {employees.map((x)=>{
                        return(
                            <>
                            <option value={x.emp_id}>{x.firstname}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="">Specialization</label>
                <select type="date" value={specialization} onChange={(e)=>{
                    setspecialization(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Specialization</option>
                    {specializations.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={add_trainer}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>

</div>
            </div>
        </div>
    </div>

</div>

{/* Add Activity Modules */}

<div className={`${type==='activity' ? '':'d-none'}`}>
    <h6 className='fs-5 text-dark m-0'>Activity Modules</h6>
    <div className='row m-0'>
        <div className="col-md-8 px-0 px-md-5 py-4 mx-auto">
            <div className='d-flex justify-content-end'>
            <button onClick={()=>{
                setactivitystate(true)
            }} className='m-0 d-flex btn btn-sm text-white align-items-center mb-3 px-3' style={{backgroundColor:'#4C2987'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg> <span className='ms-1'>Add Activity Module</span></button>
            </div>
            <div className="table-responsive p-2">
            <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-secondary text-center m-0 fw-medium'>SI No</p></th>
      <th scope="col"><p className='text-secondary text-center m-0 fw-medium'>Activity Module Name</p></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {modules.map((x,index)=>{
        return(
            <tr>
      <td scope="row" className='py-3'><p className='text-center m-0'>{index+1}</p></td>
      <td className='py-3'><p className='text-center m-0'>{x.module_name}</p></td>
      <td className='py-3'><div className='text-center'>
      <svg style={{cursor:'pointer'}} onClick={()=>{
        setactivity_name(x.module_name)
        setactivity_id(x.id)
        setshow(true)
      }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4C2987" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
        </div></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>

        </div>
    </div>

{/* Edit activity module */}
<Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Edit Activity Module Name
        </Modal.Title>
        <svg style={{cursor:'pointer'}} onClick={()=>{
            setshow(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='row m-0 justify-content-center'>
        <p className='text-center m-0 fw-medium mb-2 fs-5'>Update the Name</p>
        <div className='col-12'>
            <input type="text" className='form-control shadow-none border-secondary-subtle py-3' value={activity_name} onChange={(e)=>{
                setactivity_name(e.target.value)
            }}/>
        </div>
        <div className='text-end mt-3'>
            <button className='btn btn-primary px-4' onClick={()=>{
            update_modules()
            }}>Update</button>
        </div>
       </div>
      </Modal.Body>
     
    </Modal>

</div>

{/* Add Projects */}

<div className={`${type==='project' ? '':'d-none'}`}>
    <div className='row m-0'>
    <div className="col-md-12 px-0 px-md-5 py-2">
        <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='fs-5 text-dark m-0'>Add New Project</h6>
        <button className='btn btn-sm btn-primary px-3' onClick={()=>{
            setcategorystate(true)
        }}>Add Category</button>
        </div>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="">Project Name</label>
                <input type="text" value={project_name} onChange={(e)=>{
                    setproject_name(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="">Category</label>
                <select type="date" value={category} onChange={(e)=>{
                    setcategory(e.target.value)
                    fetch_languages(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Category Name</option>
                    {categories.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.category_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className={`col-12 mb-4 mt-3 d-flex align-items-center ${languages.length>0 ? '':'d-none'}`}>
                <h6 className='me-3 text-primary'>Available Languages : </h6>
                    {languages.map((x)=>{
                        return(
                            <>
                            <span style={{cursor:'pointer'}} onClick={()=>{
                                setselectedlanguages([...selectedlanguages,Number(x.id)])
                                setselectedlanguages_name([...selectedlanguages_name,{id:Number(x.id), name:x.subcategoryname}])
                            }} className='me-3 border rounded px-3 py-1'>{x.subcategoryname}</span>
                            </>
                        )
                    })}
                </div>

                <div className={`col-12 mb-4 mt-3 d-flex align-items-center ${selectedlanguages_name.length>0 ? '':'d-none'}`}>
                    <h6 className='me-3 text-primary'>Selected Languages : </h6>
                    {selectedlanguages_name.map((x)=>{
                        return(
                            <>
                            <span className='border py-1  px-2 rounded me-3 d-flex justify-content-between align-items-center'>
                            <span onClick={()=>{
                                setselectedlanguages([...selectedlanguages,Number(x.id)])
                            }} className='me-2'>{x.name}</span>
                            <svg style={{cursor:'pointer'}} onClick={() => {
                    setselectedlanguages_name(prev => prev.filter(item => item.name !== x.name))
                    setselectedlanguages(prev => prev.filter(item => item !== x.id))
                }} xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                            </span>
                            </>
                        )
                    })}
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Difficulty Level</label>
                <select value={difficulty} onChange={(e)=>{
                    setdifficulty(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Difficulty Level</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advances</option>
                    </select>
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Description</label>
                <input type="text" value={project_description} onChange={(e)=>{
                    setproject_description(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Project Type</label>
                <select value={project_type} onChange={(e)=>{
                    setproject_type(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Project Type...</option>
                    <option value="premium">Premium</option>
                    <option value="free">Free</option>
                    </select>
                </div>

                <div className={`mb-3 col-md-6 mt-1 ${project_type==='premium' ? '':'d-none'}`}>
                <label htmlFor="">Cost</label>
                <input type="number" value={project_cost} onChange={(e)=>{
                    setproject_cost(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 mt-3 col-md-6'>
                <label htmlFor="upload" style={{cursor:'pointer'}} className='bg-primary text-white rounded px-4 py-2'>Upload Document</label>
                <input type="file" onChange={(e)=>{
                    setfile(e.target.files[0])
                }} id='upload' className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                <p className='text-success mt-2' style={{fontSize:'13px'}}>{file && file.name}</p>
                </div>

                

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={()=>{
       add_project()
    }}>
        <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
    <span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
    <span className={`${load ? 'd-none':''}`}>Submit</span></button>

</div>
            </div>
        </div>
    </div>

</div>

{/* Add Events */}
<div className={`${type==='event' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Event</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Name</label>
                <input type="text" value={eventdescription} onChange={(e)=>{
                    seteventdescription(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Date</label>
                <input type="date" value={event_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setevent_date(e.target.value)
    }
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Time</label>
                <input type="time" value={event_time} onChange={(e)=>{
                    setevent_time(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Link</label>
                <input type="text" value={event_link} onChange={(e)=>{
                    setevent_link(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Type</label>
                <select name="" value={event_type} onChange={(e)=>{
                    setevent_type(e.target.value)
                }} id="" className='form-select shadow-none border-secondary-subtle mt-2 py-2'>
                    <option value="">Choose...</option>
                    <option value="cost">Paid Event</option>
                    <option value="free">Free Event</option>
                </select>
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>Amount</label>
                <input type="number" value={amount} onChange={(e)=>{
                    setamount(e.target.value)
                    setgst_total(e.target.value*0.18)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>GST Total</label>
                <input type="number" value={gst_total} onChange={(e)=>{
                    setgst_total(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="event-file" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload Image</span></label>
                <input type="file" onChange={(e)=>{
                    setevent_img(e.target.files[0])
                }} id='event-file' className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                <p className='text-success m-0 ms-2'>{event_img && event_img.name}</p>
                </div>

<div className='text-end mt-3'>
    <button disabled={event_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_event}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>

{/* Add Upcoming batches */}
<div className={`${type==='upcoming-batch' ? '':'d-none'}`}>
<Add_upcoming_batch/>
</div>

{/* Add upcoming course */}
<div className={`${type==='upcoming-course' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add Upcoming Course</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Select the Course</label>
                <select type="text" value={course_name} onChange={(e)=>{
                    setcourse_name(Number(e.target.value))
                }} className='form-select bg-light shadow-none border-secondary-subtle py-2 mt-2'>
                    <option value="">Choose...</option>
                    {allcourses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Course Description</label>
                <input type="text" value={coursedescription} onChange={(e)=>{
                    setcoursedescription(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Date and Time</label>
                <input type="datetime-local" value={course_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setcourse_date(e.target.value)
    }
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="course-file" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload Image</span></label>
                <input type="file" onChange={(e)=>{
                    setcourse_img(e.target.files[0])
                }} id='course-file' className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                <p className='text-success m-0 ms-2'>{course_img && course_img.name}</p>
                </div>

<div className='text-end mt-3'>
    <button disabled={course_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_upcoming_course}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>

</div>

{/* Assign Students */}

<div className={`${type==='student' ? '':'d-none'}`}>
<div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Assign Students to Batch</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6' style={{height:'200px',overflowY:'scroll'}}>
                {students.map((x)=>{
                    return(
                        <div className='d-flex align-items-center mb-3'>
                            <input type="checkbox" onChange={(e)=>{
                                handleCheckboxChange(e,Number(x.id))
                            }}/>
                            <label htmlFor="" className='ms-2 fw-medium'>{x.customer_name}</label>
                        </div>
                    )
                })}
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="">Batch</label>
                <select name="" id="" value={selceted_batch} onChange={(e)=>{
                    setseleced_batch(Number(e.target.value))
                }} className='form-select shadow-none border-secondary-subtle mt-2'>
                    <option value="">Select the Batch</option>
                    {incomplete_batches.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.batch_id}</option>
                            </>
                        )
                    })}
                </select>
                </div>
<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={assign_students}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Assigning...</span>
<span className={`${load ? 'd-none':''}`}>Assign</span></button>
</div>
            </div>
        </div>
    </div>

</div>

{/* Add Interview */}

<div className={`${type==='interview' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Interview</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Company Name <span style={{color:'red'}}>*</span></label>
                <input type="text" value={company_name} onChange={(e)=>{
                    setcompany_name(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />
                </div>

                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Position <span style={{color:'red'}}>*</span></label>
                <input type="text" value={position} onChange={(e)=>{
                    setposition(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

                </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Interview Date <span style={{color:'red'}}>*</span></label>
<input type="date" value={interview_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setinterview_date(e.target.value)
    }
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

</div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Interview Time <span style={{color:'red'}}>*</span></label>
<input type="time" value={interview_time} onChange={(e)=>{
                    setinterview_time(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

</div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Location <span style={{color:'red'}}>*</span></label>
<input type="text" value={location} onChange={(e)=>{
                    setlocation(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

                    </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Interview Type <span style={{color:'red'}}>*</span></label>
<input type="text" value={interview_type} onChange={(e)=>{
                    setinterview_type(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

</div>

<div className='mb-3 border-bottom pb-3'>
<label htmlFor="">Job Description <span style={{color:'red'}}>*</span></label>
<textarea type="text" value={job_description} onChange={(e)=>{
                    setjob_description(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 mt-2 bg-light' />

</div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' disabled={company_name.length>0 && position.length>0 && interview_type.length>0 && location.length>0 && interview_date!=='' && interview_time!=='' ? false:true} 
    onClick={add_interview}>
    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
    </button>

</div>
            </div>
        </div>
    </div>

</div>

{/* Add Syllabus */}

<div className={`${type==='syllabus' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add Syllabus</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Sub Course Name</label>
                <select type="date" value={sub_course_id} onChange={(e)=>{
                    setsubcourse_id(Number(e.target.value))
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Sub Course</option>
                    {subcourses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.sub_course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 border-bottom border-top pb-3 pt-3'>
                    <p className='m-0'>Syllabus</p>
                    <div className='row m-0 mb-2 p-0' style={{ marginBottom: '20px',borderRadius:'10px'}}>
              {syllabuses.map((q, qIndex) => (
                
                  <div className='col-12 px-2' key={qIndex}>
                    <input value={q.text} className='border-secondary-subtle add-option form-control py-2 shadow-none bg-secondary-subtle my-2'
                      type="text"
                      placeholder='Type syllabus here...'
                      onChange={(e) => handlesyllabusesChange(qIndex, e)}
                      required
                    />
                  </div>
                
              ))}
              </div>
              <div className='d-flex justify-content-end mt-3'>

      <button type="button" className='rounded-circle btn btn-sm bg-secondary-subtle text-white d-flex align-items-center' onClick={handleAddsyllabuses}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                  </svg> 
                </button>
               </div>
            </div>

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' 
    onClick={add_syylabus}>
    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
    </button>

</div>
            </div>
        </div>
    </div>

</div>

{/* Add  classroom*/}

<div className={`${type==='class' ? '':'d-none'}`}>
    <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add Classroom</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
            <div className='mb-3 col-lg-4 col-md-6 pb-3'>
                <label htmlFor="">Classroom Name</label>
               <input type="text" value={class_room} onChange={(e)=>{
                setclass_room(e.target.value)
               }} className='form-control shadow-none py-2 border-secondary-subtle mt-2 bg-light' name="" id="" />
                </div>

                <div className='mb-3 col-lg-4 col-md-6 pb-3'>
                <label htmlFor="">Capacity</label>
               <input type="number" value={capacity} onChange={(e)=>{
                setcapacity(e.target.value)
               }} className='form-control shadow-none py-2 border-secondary-subtle mt-2 bg-light' name="" id="" />
                </div>
                
                <div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' 
    onClick={add_classroom}>
    <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
    </button>

</div>
              </div>
            </div>

            </div>
        </div>


            </div>
        </div>

{/* Add activity modal */}
<div className={`${activitystate ? '':'d-none'}`} style={{backgroundColor:'rgba(0,0,0,0.5)',width:'100%',position:'fixed',height:'100vh',zIndex:6}}>
    <div className="m-0 row justify-content-center align-items-center h-100">
    <div className='bg-white rounded row m-0 p-4 px-4 col-lg-5 animate__animated animate__fadeIn'>

<div className='mb-3 col-md-12'>
    <div className='d-flex justify-content-between align-items-center mb-4'>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add Module Name</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setactivitystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>
<input type="text" value={activity_module} onChange={(e)=>{
    setactivity_module(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
</div>

<div className='text-end mt-2'>
<button className='btn btn-primary px-4 py-2' disabled={activity_module.length>0 ? false:true} onClick={add_activity_module}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
</div>
    </div>
</div>

{/* Add category modal*/}
<div className={`${categorystate ? '':'d-none'}`} style={{backgroundColor:'rgba(0,0,0,0.5)',width:'100%',position:'fixed',height:'100vh',zIndex:6}}>
    <div className="m-0 row justify-content-center align-items-center h-100">
    <div className='bg-white rounded row m-0 p-4 px-4 col-lg-8 animate__animated animate__fadeIn'>

<div className='mb-3 col-md-12'>
    <div className={`d-flex justify-content-between align-items-center mb-4 ${categoryform1state ? 'd-none':''}`}>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add New Category</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setcategorystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>

    <div className={`d-flex justify-content-between align-items-center mb-4 ${categoryform1state ? '':'d-none'}`}>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add Sub Languages for the Category</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setcategorystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>

<div className={`row m-0 pb-4 ${categoryform1state ? 'd-none':''}`}>
    <div className="col-lg-6 ps-0 mb-4">
        <label htmlFor="">Category Name</label>
    <input type="text" value={category_name} onChange={(e)=>{
    setcategory_name(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 pe-0 mb-4">
    <label htmlFor="">Title</label>
    <input type="text" value={cat_title} onChange={(e)=>{
    setcat_title(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 ps-0 mb-4">
    <label htmlFor="">Description</label>
    <input type="text" value={cat_description} onChange={(e)=>{
    setcat_description(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 pe-0 mb-4 d-flex align-items-end">
    <label htmlFor="cat_file" style={{cursor:'pointer'}} className='bg-primary rounded px-4 text-white py-1'>Upload Image</label>
    <input type="file" onChange={(e)=>{
    setcategory_img(e.target.files[0])
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="cat_file" />
<p className='m-0 text-success ms-2 fw-medium'>{category_img && category_img.name}</p>
    </div>

</div>

<div className={`${sublanguages.length>1 ? 'py-4':''} ${categoryform1state ? '':'d-none'}`} style={{height:'60vh',overflowY:sublanguages.length>3 ? 'scroll':'none'}}>
    {sublanguages.map((x, index) => (
                
                    <div className="row m-0" key={index}>
                        <div className="col-lg-6 ps-0 mb-4">
                            <label htmlFor="">Language Name</label>
                            <input
                                type="text"
                                onChange={(e) => handleLanguageChange(index, 'sub_category_name', e.target.value)}
                                className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2'
                                value={x.name}
                            />
                        </div>
                        <div className="col-lg-6 pe-0 mb-4 d-flex align-items-end">
                            <label
                                htmlFor={`cat_file_${index}`}
                                style={{ cursor: 'pointer' }}
                                className='bg-primary rounded px-4 text-white py-1'
                            >
                                Upload Logo
                            </label>
                            <input
                                type="file"
                                onChange={(e) => {
                                    handleLanguageChange(index, 'logo', e.target.files[0]);
                                }}
                                className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2'
                                id={`cat_file_${index}`}
                                style={{ display: 'none' }}
                            />
                            <p className='m-0 text-success ms-2 fw-medium'>
                                {x.logo && x.logo.name}
                            </p>
                        </div>
                    </div>
            ))}
  <div className='text-end'>
            <button onClick={handleAddLanguage} className='btn btn-primary btn-sm'>Add Language</button>
            </div>
            </div>
</div>

<div className={`text-end ${categoryform1state ? 'd-none':''}`}>
<button className='btn btn-primary px-4 py-2' disabled={category_name.length>0 && cat_title.length>0 && cat_description.length>0 && category_img!=null ? false:true} onClick={add_category}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
<div className={`text-end ${categoryform1state ? '':'d-none'}`}>
<button className='btn btn-primary px-4 py-2' disabled={sublanguages.length>1 ? false:true} onClick={add_sublanguages}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
</div>
    </div>
</div>
    </div>
  )
}

export default Add_details