import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'

const Batches = () => {

  const renderTooltip = (value) => (
    <Tooltip id="button-tooltip">
      {value}
    </Tooltip>
  );

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[batches,setbatches]=useState([])
    const[count,setcount]=useState(0)

    useEffect(()=>{
      axios.get(`${ipaddress2}/TrainerView/${user.id}/`)
      .then((r)=>{
        console.log("Batch details",r.data)
        setbatches(r.data)
      })
    },[count])

        // To get the time in hours and minutes format
        const formatTime = (timeString) => {
          const [hours, minutes] = timeString.split('T')[1].split(':');
          return `${hours}:${minutes}`;
        };

    const[batch_id,setbatch_id]=useState()

    const[group_name,setgroup_name]=useState("")

    const create_group=()=>{
      axios.post(`${ipaddress2}/create-group/`,{
        group_name:group_name,
        trainer_id:user.emp_id,
        batch_id:batch_id                   
      })
      .then((r)=>{
        console.log("Group created",r.data)
        setgroup_name("")
        toast.success('Group created successfully',{
          autoClose:2000
        })
      })
    }

    // Make Initial letter Capital
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const complete_request=(id)=>{
      axios.post(`${ipaddress2}/BatchCloseView/${id}/${user.emp_id}/`)
      .then((r)=>{
        setcount(count+1)
        toast.success('Request sent to admin!',{
          theme:'colored',
          autoClose:1000
        })
      })
    }

return (
    <div className='d-flex'>
        <Sidebar activevalue={"batches"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{minHeight:'100vh'}} className='bg-light p-3 main-container'>
              <h5 className='pb-2'>Batch Details</h5>
                <div>
                  <h6 className={`text-secondary text-center py-4 ${batches.length>0 ? 'd-none':''}`}>No batches available...🧐</h6>

<div className={`row m-0 ${batches.length>0 ? '':'d-none'}`}>
     {batches.map((x)=>{
      return(
        <div className="col-md-3 pe-0 mb-4">
        <div class="card p-3" style={{borderRadius:'15px'}}>
    <img style={{borderRadius:'15px'}} src={require('../images/Photo.png')} class="card-img-top" alt="..."/>
    <div class="card-body py-0 px-1">
      <div className='pt-3'>
      <h5 class="card-title m-0 text-primary" style={{color:'#1B2559',cursor:'pointer'}} onClick={()=>{
        navigate(`/students/${x.id}`)
    }}>{x.name.sub_course_name}</h5>
      <p class="card-text" style={{color:'#A3AED0',fontSize:'13px'}}>By {capitalizeFirstLetter(user.first_name)}</p>
      </div>
      <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{x.start_time.slice(0,10)}</span></p>
  
  <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{x.end_time.slice(0,10)}</span></p>
     </div>
     <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{formatTime(x.start_time)}</span></p>
  <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}><span style={{fontSize:'12px'}} className='fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
  </svg></span> <span style={{fontSize:'12px'}} className='fw-medium ms-1'>{formatTime(x.end_time)}</span></p>
     </div>
  
     <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 d-flex align-items-center' style={{color:'#57309C'}}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltip("View Queries")}
      >
        <span onClick={()=>{
        navigate(`/reply_for_queries/${x.id}`)
      }} style={{fontSize:'12px',backgroundColor:'#57309C',height:'30px',cursor:'pointer'}} className='fw-medium d-flex align-items-center rounded text-white btn btn-sm'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
  </svg></span> 
  </OverlayTrigger>
  
  <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltip("Create Group")}
      >
  <span data-bs-toggle="modal" data-bs-target="#groupModal" onClick={()=>{
    setbatch_id(x.id)
  }} style={{fontSize:'12px',backgroundColor:'#57309C',height:'30px',cursor:'pointer'}} className='fw-medium d-flex align-items-center rounded text-white btn btn-sm ms-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
  </svg></span>
  </OverlayTrigger>
  </p>
     <button onClick={()=>{
        navigate(`/logsheet/${x.name.id}/${x.id}`)
      }} className='btn text-white btn-sm rounded w-100 ms-2 px-3 py-1' style={{backgroundColor:'#57309c',height:'30px'}}>Attendance</button>
     </div>

     <div className='text-center border-top mt-3 '>
     <button className={`btn btn-sm px-5 text-white mt-2 w-100 ${x.batch_completed_status==false && x.completed_approval==false ? '':'d-none'}`} onClick={()=>{
      complete_request(x.id)
     }} style={{backgroundColor:'#57309c',height:'30px'}}>Make Complete</button>

     <p className={`${x.batch_completed_status && x.completed_approval==false ? 'fw-medium text-center pt-2 text-warning m-0':'d-none'}`}>Approval Pending</p>
     <p className={`${x.batch_completed_status && x.completed_approval ? 'fw-medium text-center pt-2 text-success m-0':'d-none'}`}>Completed</p>

     </div>
    </div>
  </div>
        </div>
      )
     })}
    </div>
                </div>
            </div>
        </div>

{/* Group Modal */}
<div class="modal fade" id="groupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h1 class="modal-title fs-6 text-primary" id="exampleModalLabel">Create the Group</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div>
          <label htmlFor="">Batch Id</label>
          <input type="text" className='form-control py-2 bg-light mt-1' value={batch_id} disabled='true' />
        </div>
        <div className='mt-3'>
          <label htmlFor="">Trainer Name</label>
          <input type="text" className='form-control py-2 bg-light mt-1' value={user.first_name}/>
        </div>
        <div className='mt-3'>
          <label htmlFor="">Group Name</label>
          <input type="text" className='shadow-none border-secondary-subtle form-control py-2 bg-light mt-1' value={group_name} onChange={(e)=>{
            setgroup_name(e.target.value)
          }}/>
        </div>

        <div className='mt-4 text-end'>
          <button className='btn btn-sm btn-primary px-3' data-bs-dismiss="modal" onClick={create_group}>Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Batches