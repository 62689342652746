import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'

const Questions = () => {
    const[state,setstate]=useState('mcq')

    const navigate=useNavigate()

    const[mcq_questions,setmcq_questions]=useState([])
    const[coding_questions,setcoding_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    useEffect(()=>{
        axios.get(`${ipaddress2}/Question_Details/${user.emp_id}/${state}/`)
        .then((r)=>{
            console.log("All question paapers",r.data)
            setmcq_questions(r.data)
        })
    },[])

    const fetchcoding_questions=(type)=>{
      axios.get(`${ipaddress2}/Question_Details/${user.emp_id}/${type}/`)
      .then((r)=>{
          console.log("All coding question paapers",r.data,type)
          setcoding_questions(r.data)
      })
    }


  return (
    <div className='d-flex'>
        <Sidebar activevalue={"questions"}/>
        <div className="w-100">
            <Navbar/>
            <div className="bg-light main-container p-3 px-4" style={{minHeight:'100vh'}}>
              <h6 className='fs-5 pb-4'>Created Question Papers</h6>
            <div className='d-flex gap-2 gap-md-5 pb-2'>
              <h5 onClick={()=>{
                setstate('mcq')
              }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:state==='mcq' ? '#57309C':'',cursor:'pointer',borderBottom:state==='mcq' ? '2px solid #57309C':'none'}}>
  <span className=''>MCQ Questions</span></h5>
<h5 onClick={()=>{
  setstate('coding')
  fetchcoding_questions('coding')
}} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:state==='coding' ? '#57309C':'',cursor:'pointer',borderBottom:state==='coding' ? '2px solid #57309C':'none'}}>
  <span className=''>Coding Questions</span></h5>
            </div>

{/* MCQ Questions */}
<div className={`${state==='mcq' ? '':'d-none'}`}>
    <h6 className={`text-center py-4 ${mcq_questions.length>0 ? 'd-none':''}`} style={{cursor:'pointer'}} onClick={()=>{
        navigate('/assign_test')
    }}>Create Question Paper...✍</h6>

<div className={`${mcq_questions.length>0 ? '':''} row m-0`}>
  {mcq_questions.map((x)=>{
    return(
      <div className="col-md-4 px-2 mt-4">
  <div className='bg-white rounded shadow-sm p-3'>
    <p><span className='text-secondary'>Course : </span> <span className='text-dark fw-medium'>{x.title}</span></p>
    <p><span className='text-secondary' style={{fontSize:'12px'}}>Type : </span><span className='text-dark fw-medium' style={{fontSize:'12px'}}>MCQ Question</span></p>
    <p><span className='text-secondary' style={{fontSize:'12px'}}>Created on : </span><span className='text-dark fw-medium' style={{fontSize:'12px'}}>{x.created_at && x.created_at.slice(0,10)}</span></p>
    <button className='btn text-white px-5 w-100 py-2' onClick={()=>{
            navigate(`/mcq_question/${x.id}`)
        }} style={{backgroundColor:'#57309C'}}>View Question Paper</button>
  </div>
</div>
    )
  })}

</div>

{/* 
<div className={`table-responsive ${mcq_questions.length>0 ? '':'d-none'}`}>
                <table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'><p className='text-center m-0'>SI No</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Title</p></th>
      <th scope="col" className='py-3'><p className='text-center m-0'>Created On</p></th>
      <th scope="col" className='py-3'></th>
    </tr>
  </thead>
  <tbody>
    {mcq_questions.map((x,index)=>{
        return(
            <tr>
      <td scope="row" className=''><p className='text-center m-0'>{index+1}</p></td>
      <td className=''><p className='text-center m-0'>{x.title}</p></td>
      <td className=''><p className='text-center m-0'>{x.created_at && x.created_at.slice(0,10)}</p></td>
      <td className=''><div className='d-flex justify-content-center'>
        <button className='btn btn-sm btn-primary' onClick={()=>{
            navigate(`/mcq_question/${x.id}`)
        }}>View Question</button>
        </div></td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div> */}
</div>



{/* Coding Questions */}
<div className={`${state==='coding' ? '':'d-none'}`}>
    <h6 className={`text-center py-4 ${coding_questions.length>0 ? 'd-none':''}`} style={{cursor:'pointer'}} onClick={()=>{
        navigate('/assign_test')
    }}>Create Question Paper...✍</h6>

<div className={`${coding_questions.length>0 ? '':'d-none'} row m-0`}>
  {coding_questions.map((x,index)=>{
    return(
      <div className="col-md-4 px-2 mt-4">
  <div className='bg-white rounded shadow-sm p-3'>
    <p><span className='text-secondary'>Title : </span> <span className='text-dark fw-medium'>{x.title}</span></p>
    <p><span className='text-secondary' style={{fontSize:'12px'}}>Type : </span><span className='text-dark fw-medium' style={{fontSize:'12px'}}>Coding Questions</span></p>
    <p><span className='text-secondary' style={{fontSize:'12px'}}>Created On : </span><span className='text-dark fw-medium' style={{fontSize:'12px'}}>{x.created_at && x.created_at.slice(0,10)}</span></p>
    <button className='btn text-white px-5 w-100 py-2' style={{backgroundColor:'#57309C'}} onClick={()=>{
            navigate(`/coding_question/${x.id}`)
        }}>View Question Paper</button>
  </div>
</div>
    )
  })}
</div>
</div>
            </div>
        </div>
    </div>
  )
}

export default Questions